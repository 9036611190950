<template>

    <div>

        <div class="pb-4 caption text-center" v-if="priceBasis">
            <span class="amber--text text--darken-1">
                &#9733;
            </span>
            <span v-if="fixedPriceBasis">
                = Tellende for valg av kapasitetstrinn. Snitt:
            </span>
            <span v-else-if="powerPriceBasis ">
                = Tellende for valg av effekttrinn. Maks:
            </span>
            <b>
                {{ this.formatNumber(priceBasisValue, 3) }} kWt
            </b>
        </div>

        <v-data-table :items="topTenHours"
                      :headers="headers"
                      fixed-header
                      disable-sort
                      hide-default-footer
                      disable-pagination
                      mobile-breakpoint="0"
                      dense>

            <template v-slot:item.index="{ item, index }">
                <div>
                    <span v-if="priceBasis === 'MONTHLY_MAX_3' && topThreeDailyMaxes.find(each => each[0] === item.time) || priceBasis === 'MONTHLY_MAX' && monthlyMax && monthlyMax[0] === item.time"
                          class="pr-2 caption amber--text text--darken-1">
                        &#9733;
                    </span>
                    <span class="caption" v-text="index + 1"/>
                </div>
            </template>

            <template v-slot:item.day="{ item }">
                <span class="caption" v-text="_formatDay(item.time)"/>
            </template>

            <template v-slot:item.hour="{ item }">
                <span class="caption" v-html="formatShortHourInterval(item.time)"/>
            </template>

            <template v-slot:item.value="{ item }">
                <span class="caption" v-text="formatNumber(item.value, 3)"/>
            </template>

        </v-data-table>

    </div>
</template>

<script>

import Common from "../mixins/Common"
import _ from "lodash";
import moment from "moment-timezone";

export default {
    name: 'MaxHoursForMonth',
    mixins: [Common],
    components: {},
    props: ['hourValues'],

    data() {
        return {
            headers: [
                {
                    text: '',
                    value: 'index',
                    align: 'end'
                },
                {
                    text: 'Dag',
                    value: 'day'
                },
                {
                    text: 'Time',
                    value: 'hour'
                },
                {
                    text: 'kWt',
                    value: 'value',
                    align: 'end'
                }
            ]
        }
    },

    computed: {

        priceBasis() {
            return this.fixedPriceBasis || this.powerPriceBasis;
        },

        priceBasisValue() {
            switch (this.priceBasis) {
                case 'MONTHLY_MAX':
                    return this.monthlyMax && this.monthlyMax[1];
                case 'MONTHLY_MAX_3':
                    return _.meanBy(this.topThreeDailyMaxes, each => each[1]);
                default:
                    return null;
            }
        },

        topTenHours() {
            if (!this.hourValues) {
                return [];
            }

            let intervalValues = this.showConsumption ? this.hourValues.consumptionInterval : this.hourValues.productionInterval;
            let orderedIntervalValues = _.orderBy(intervalValues, each => each[1], 'desc');

            return _.take(orderedIntervalValues, 10)
                .map(each => (
                    {
                        time: each[0],
                        value: each[1]
                    }));
        },

        topThreeDailyMaxes() {
            if (!this.hourValues) {
                return [];
            }

            let intervalValues = this.showConsumption ? this.hourValues.consumptionInterval : this.hourValues.productionInterval;
            let dailyMaxesByDay = _.mapValues(_.groupBy(intervalValues, each => this.formatDay(each[0])), v => _.maxBy(v, v => v[1]));
            return _.take(_.orderBy(_.toArray(dailyMaxesByDay), each => each[1], 'desc'), 3);
        },

        monthlyMax() {
            return _.maxBy(this.topThreeDailyMaxes, each => each[1]);
        },
    },

    methods: {
        _formatDay(date) {
            return moment(date).tz("Europe/Oslo").format("dddd D. MMM");
        }
    },

    watch: {}
}
</script>
