<template>

    <div style="padding-bottom:60px">
        <v-layout align-center class="py-1" style="height: 45px">
            <div class="text-button pl-1" v-text="formatLongMonth(month)"/>
            <v-spacer/>

            <v-switch dense v-model="showDetails" class="mr-4">
                <template v-slot:label>
                    <div class="body-2">Vis detaljer</div>
                </template>
            </v-switch>

            <v-btn icon class="mr-1" color="primary" @click="showSettings = true">
                <v-icon>mdi-cog</v-icon>
            </v-btn>
            <meter-values-settings v-model="showSettings"/>
        </v-layout>

        <value-type-selector/>

        <highcharts ref="chart" constructor-type="stockChart" :options="chartOptions"
                    :style="{height: $vuetify.breakpoint.xs ? '260px' : '500px'}"
                    style="width:100%"
                    v-observe-visibility="visibilityChanged"/>

        <div v-if="showEnergy && showConsumption">
            <v-layout class="pt-3">
                <div class="pb-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Forbruk {{ formatShortMonth(month) }} {{ year }}</div>
                    <div class="text-center">
                        <span class="title mr-1" v-text="formatNumber(sumConsumption, 0)"/>
                        <span class="caption">kWt</span></div>
                </div>

                <v-divider vertical/>

                <div class="py-1" style="width:50%">
                    <div class="text-center text-uppercase caption">
                        {{ sumConsumptionThisMonthThisYear >= sumConsumptionSameMonthPreviousYear ? 'Økning' : 'Reduksjon' }} fra
                        {{ year - 1 }}
                    </div>
                    <div class="text-center">
                        <span v-if="sumConsumptionSameMonthPreviousYear" class="title">
                            <v-icon v-show="sumConsumptionThisMonthThisYear >= sumConsumptionSameMonthPreviousYear" size="28"
                                    color="red darken-2" style="margin-top: -6px">mdi-arrow-up-bold</v-icon>
                            <v-icon v-show="sumConsumptionThisMonthThisYear < sumConsumptionSameMonthPreviousYear" size="28"
                                    color="green darken-2" style="margin-top: -6px">mdi-arrow-down-bold</v-icon>
                            {{ formatNumber(Math.abs(sumConsumptionThisMonthThisYear - sumConsumptionSameMonthPreviousYear), 0) }}
                        </span>
                        <span v-else class="title mr-1">&mdash;</span>
                        <span class="caption">kWt</span></div>
                </div>
            </v-layout>

            <v-divider/>

            <v-layout>
                <div class="py-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Høyeste forbruk</div>
                    <div class="text-center">
                        <span class="title mr-1" v-html="formatNumber(maxConsumption, 1) || '&mdash;'"/>
                        <span class="caption">kWt</span>
                    </div>
                    <div style="line-height: 1" class="text-center caption" v-html="formatLongHourInterval(timeOfMaxConsumption)"/>
                </div>

                <v-divider vertical/>

                <div class="py-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Snitt forbruk</div>
                    <div class="text-center">
                        <span class="title mr-1" v-html="formatNumber(averageConsumption, 1) || '&mdash;'"/>
                        <span class="caption">kWt</span></div>
                </div>
            </v-layout>
        </div>

        <div v-if="showEnergy && showProduction">
            <v-layout class="pt-3">
                <div class="pb-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Produksjon {{ formatShortMonth(month) }} {{ year }}</div>
                    <div class="text-center">
                        <span class="title mr-1" v-text="formatNumber(sumProduction, 0)"/>
                        <span class="caption">kWt</span></div>
                </div>

                <v-divider vertical/>

                <div class="py-1" style="width:50%">
                    <div class="text-center text-uppercase caption">
                        {{ sumProductionThisMonthThisYear >= sumProductionSameMonthPreviousYear ? 'Økning' : 'Reduksjon' }} fra
                        {{ year - 1 }}
                    </div>
                    <div class="text-center">
                        <span v-if="sumProductionSameMonthPreviousYear" class="title">
                            <v-icon v-show="sumProductionThisMonthThisYear >= sumProductionSameMonthPreviousYear" size="28"
                                    color="green darken-2" style="margin-top: -6px">mdi-arrow-up-bold</v-icon>
                            <v-icon v-show="sumProductionThisMonthThisYear < sumProductionSameMonthPreviousYear" size="28"
                                    color="red darken-2" style="margin-top: -6px">mdi-arrow-down-bold</v-icon>
                            {{ formatNumber(Math.abs(sumProductionThisMonthThisYear - sumProductionSameMonthPreviousYear), 0) }}
                        </span>
                        <span v-else class="title mr-1">&mdash;</span>
                        <span class="caption">kWt</span></div>
                </div>
            </v-layout>

            <v-divider/>

            <v-layout>
                <div class="py-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Høyeste produksjon</div>
                    <div class="text-center">
                        <span class="title mr-1" v-html="formatNumber(maxProduction, 1) || '&mdash;'"/>
                        <span class="caption">kWt</span>
                    </div>
                    <div style="line-height: 1" class="text-center caption" v-html="formatLongHourInterval(timeOfMaxProduction)"/>
                </div>

                <v-divider vertical/>

                <div class="py-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Snitt produksjon</div>
                    <div class="text-center">
                        <span class="title mr-1" v-html="formatNumber(averageProduction, 1) || '&mdash;'"/>
                        <span class="caption">kWt</span></div>
                </div>
            </v-layout>
        </div>

        <div v-if="showCost">
            <v-layout class="pt-3">
                <div class="pb-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Nettleie {{ formatShortMonth(month) }} {{ year }}</div>
                    <div class="text-center">
                        <span class="title mr-1" v-text="formatNumber(sumGridCost, 0)"/>
                        <span class="caption">kr</span></div>
                </div>

                <v-divider vertical/>

                <div class="py-1" style="width:50%">
                    <div class="text-center text-uppercase caption">
                        {{ sumGridCostThisMonthThisYear >= sumGridCostSameMonthPreviousYear ? 'Økning' : 'Reduksjon' }} fra
                        {{ year - 1 }}
                    </div>
                    <div class="text-center">
                        <span v-if="sumGridCostSameMonthPreviousYear" class="title">
                            <v-icon v-show="sumGridCostThisMonthThisYear >= sumGridCostSameMonthPreviousYear" size="28"
                                    color="red darken-2" style="margin-top: -6px">mdi-arrow-up-bold</v-icon>
                            <v-icon v-show="sumGridCostThisMonthThisYear < sumGridCostSameMonthPreviousYear" size="28"
                                    color="green darken-2" style="margin-top: -6px">mdi-arrow-down-bold</v-icon>
                            {{ formatNumber(Math.abs(sumGridCostThisMonthThisYear - sumGridCostSameMonthPreviousYear), 0) }}
                        </span>
                        <span v-else class="title mr-1">&mdash;</span>
                        <span class="caption">kr</span></div>
                </div>
            </v-layout>

            <v-divider/>

            <v-layout>
                <div class="py-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Dyreste nettleie</div>
                    <div class="text-center">
                        <span class="title mr-1" v-html="formatNumber(maxGridCost, 2) || '&mdash;'"/>
                        <span class="caption">kr</span>
                    </div>
                    <div style="line-height: 1" class="text-center caption" v-html="formatLongHourInterval(timeOfMaxGridCost)"/>
                </div>

                <v-divider vertical/>

                <div class="py-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Snitt nettleie</div>
                    <div class="text-center">
                        <span class="title mr-1" v-html="formatNumber(averageGridCost, 2) || '&mdash;'"/>
                        <span class="caption">kr/t</span></div>
                </div>
            </v-layout>

            <itemized-grid-cost :values="itemizedGridCost" class="pt-6"/>

            <div v-if="meterPoint && !meterPoint.vatIncluded"
                 class="caption text-center pt-6">
                (Beløpene er uten mva)
            </div>

        </div>

        <div v-if="showDetails">
            <div class="text-button pt-5 text-center">
                {{ showConsumption ? 'Forbruk' : 'Produksjon'}}
                per dag og time
            </div>

            <heat-map-for-month :hour-values="hourValues"/>

            <div v-if="showConsumption" class="text-button pt-2 text-center">
                Timer med høyest forbruk
            </div>

            <max-hours-for-month v-if="showConsumption" :hour-values="hourValues"/>
        </div>

        <div id="details-anchor"/>

        <div class="d-flex justify-center mt-4" style="position:fixed; bottom:65px; left:12px; right:12px">
            <v-btn-toggle ref="scroller"
                          mandatory
                          rounded
                          dense
                          color="primary"
                          v-model="selectedButton"
                          style="scroll-behavior:smooth"
                          class="overflow-x-auto overflow-y-hidden">

                <v-btn height="48" v-if="lastMonthPreviousYear" :value="lastMonthPreviousYear">
                    <template v-slot:default>
                        <div>
                            <v-icon small style="margin-top:6px">mdi-arrow-left</v-icon>
                            <div class="overline" style="margin-top:-6px">{{ parseInt(year) - 1 }}</div>
                        </div>
                    </template>
                </v-btn>

                <v-btn v-for="month in months" :key="month" height="48" :value="month" :ref="month">
                    <template v-slot:default>
                        <div>
                            <div style="margin-top:6px">{{ formatShortMonth(month) }}</div>
                            <div class="overline" style="margin-top:-6px">{{ year }}</div>
                        </div>
                    </template>
                </v-btn>

                <v-btn height="48" v-if="firstMonthNextYear" :value="firstMonthNextYear">
                    <template v-slot:default>
                        <div>
                            <v-icon small style="margin-top:6px">mdi-arrow-right</v-icon>
                            <div class="overline" style="margin-top:-6px">{{ parseInt(year) + 1 }}</div>
                        </div>
                    </template>
                </v-btn>
            </v-btn-toggle>
        </div>
    </div>
</template>

<script>

import MeterValuesSettings from "./MeterValuesSettings"
import ValueTypeSelector from "./ValueTypeSelector"
import ItemizedGridCost from "./ItemizedGridCost"
import HeatMapForMonth from "./HeatMapForMonth"
import MaxHoursForMonth from "./MaxHoursForMonth"
import Common from "../mixins/Common"
import HttpErrorHandler from "../mixins/HttpErrorHandler"
import {EventBus} from '@/eventbus'
import moment from "moment-timezone";
import _ from "lodash";

export default {
    name: 'MeterValuesByMonth',
    mixins: [Common, HttpErrorHandler],
    components: {MeterValuesSettings, ValueTypeSelector, ItemizedGridCost, HeatMapForMonth, MaxHoursForMonth},

    data() {
        return {
            selectedButton: null,
            showSettings: false,
            showDetails: true,
            hourValues: null,
            visible: true,

            chartOptions: {
                chart: {
                    backgroundColor: "transparent",
                    panning: false,
                    spacingBottom: 0,
                    style: {
                        fontFamily: "Roboto"
                    }
                },
                navigator: {
                    enabled: false
                },
                rangeSelector: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                tooltip: {
                    split: false,
                    shared: true,
                },
                xAxis: {
                    min: 1,
                    max: 31,
                    ordinal: false,
                    type: 'linear',
                    tickPositions: [1, 5, 10, 15, 20, 25, 30, 32]
                },
                yAxis: [
                    {
                        title: {
                            align: 'high',
                            offset: 5,
                            text: 'kWt',
                            rotation: 0,
                            y: 15
                        },
                        labels: {
                            x: -5
                        },
                        opposite: false
                    },
                    {
                        title: {
                            align: 'high',
                            offset: 5,
                            text: 'kr',
                            rotation: 0,
                            y: 15
                        },
                        labels: {
                            x: -5
                        },
                        opposite: false,
                        reversedStacks: false
                    },
                    {
                        visible: false,
                        title: {
                            align: 'high',
                            offset: 5,
                            text: '°C',
                            rotation: 0,
                            y: 15
                        },
                        labels: {
                            x: 5
                        },
                        opposite: true,
                        allowDecimals: false
                    },
                    {
                        visible: false,
                        title: {
                            align: 'high',
                            offset: 5,
                            text: 'øre',
                            rotation: 0,
                            y: 15
                        },
                        labels: {
                            x: 5
                        },
                        min: 0,
                        opposite: true,
                        allowDecimals: false
                    }
                ],
                title: {
                    text: null
                },
                legend: {
                    enabled: true,
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom',
                },
                plotOptions: {
                    column: {
                        dataGrouping: {
                            enabled: false,
                        },
                        pointPadding: 0.1,
                        groupPadding: 0.1,
                    },
                    series: {
                        events: {
                            legendItemClick: function(e) {
                                e.preventDefault();
                            }
                        }
                    }
                },
                exporting: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                loading: {
                    labelStyle: {
                        fontWeight: "normal"
                    }
                },
                series: [],
            }
        }
    },

    methods: {

        loadHourValues() {
            if (!this.month || !this.showDetails) {
                return;
            }

            let from = moment.tz(this.month, "Europe/Oslo").startOf("month").utc();
            let to = moment.tz(this.month, "Europe/Oslo").startOf("month").add(1, "month").utc();

            let url = `${this.apiUrl}customer/netowner/${this.netownerId}/metervalues/${this.meterPointId}`;
            let params = {
                from: from.format(),
                to: to.format(),
                temperatureSensorId: this.meterPoint.temperatureSensor?.id
            };

            this.$http.get(url, {params: params}).then(response => {
                this.hourValues = response.body;
            }, response => {
                this.setErrorResponse(response);
            });
        },

        updateChart() {
            if (!this.meterValues) {
                return;
            }

            let sameMonthPreviousYear = moment(this.month).subtract(1, 'year').format("YYYY-MM");

            let consumptionAbsolute = new Map();
            this.meterValues.consumption.days.forEach(each => {
                consumptionAbsolute.set(each.day, this.formatNumber(each.absolute, 0));
            });

            let productionAbsolute = new Map();
            this.meterValues.production.days.forEach(each => {
                productionAbsolute.set(each.day, this.formatNumber(each.absolute, 0));
            });

            let consumption = this.meterValues.consumption.days.filter(each => each.day.startsWith(this.month))
                .map(each => ({
                    x: moment(each.day).date(),
                    y: each.sum,
                    abs: consumptionAbsolute.get(each.day) || '-'
                }));

            let production = this.meterValues.production.days.filter(each => each.day.startsWith(this.month))
                .map(each => ({
                    x: moment(each.day).date(),
                    y: each.sum,
                    abs: productionAbsolute.get(each.day) || '-'
                }));

            let gridCost = this.meterValues.cost.days.filter(each => each.day.startsWith(this.month))
                .map(each => ({
                    x: moment(each.day).date(),
                    y: each.sumGridCost
                }));

            let electricityCost = this.meterValues.cost.days.filter(each => each.day.startsWith(this.month))
                .map(each => ({
                    x: moment(each.day).date(),
                    y: each.sumElectricityCost
                }));

            let consumptionPreviousYear = this.meterValues.consumption.days.filter(each => each.day.startsWith(sameMonthPreviousYear))
                .map(each => ({
                    x: moment(each.day).date(),
                    y: each.sum
                }));

            let productionPreviousYear = this.meterValues.production.days.filter(each => each.day.startsWith(sameMonthPreviousYear))
                .map(each => ({
                    x: moment(each.day).date(),
                    y: each.sum
                }));

            let gridCostPreviousYear = this.meterValues.cost.days.filter(each => each.day.startsWith(sameMonthPreviousYear))
                .map(each => ({
                    x: moment(each.day).date(),
                    y: each.sumGridCost
                }));

            let electricityCostPreviousYear = this.meterValues.cost.days.filter(each => each.day.startsWith(sameMonthPreviousYear))
                .map(each => ({
                    x: moment(each.day).date(),
                    y: each.sumElectricityCost
                }));

            let temperature = this.meterValues.temperature.days.filter(each => each.day.startsWith(this.month))
                .map(each => ({
                    x: moment(each.day).date(),
                    y: each.average
                }));

            let prices = this.meterValues.price.days.filter(each => each.day.startsWith(this.month))
                .map(each => ({
                    x: moment(each.day).date(),
                    y: each.average * 100
                }));

            this.chartOptions.series = [
                {
                    id: "0",
                    data: consumption,
                    visible: false,
                    showInLegend: false,
                    name: "Forbruk",
                    type: "column",
                    color: "#2196F3",
                    index: 1,
                    yAxis: 0,
                    zIndex: 0,
                    tooltip: {
                        headerFormat: '{point.key}. ' + this.formatLongMonth(this.month) + '<br>',
                        pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br>● Målerstand: <b>{point.abs} kWt</b><br>',
                        valueSuffix: ' kWt',
                        valueDecimals: 1,
                    },
                    events: {
                        click: (e) => {
                            let day = moment(this.month).date(e.point.x).format("YYYY-MM-DD")
                            this.$store.commit('setDay', day);
                            EventBus.$emit('day-selected');
                        }
                    }
                },
                {
                    id: "1",
                    data: consumptionPreviousYear,
                    visible: false,
                    showInLegend: false,
                    name: (this.year - 1),
                    type: "column",
                    color: "#d7d7d7",
                    index: 0,
                    yAxis: 0,
                    zIndex: 0,
                    tooltip: {
                        valueSuffix: ' kWt',
                        valueDecimals: 1,
                    },
                },
                {
                    id: "2",
                    data: production,
                    visible: false,
                    showInLegend: false,
                    name: "Produksjon",
                    type: "column",
                    color: "#66BB6A",
                    index: 1,
                    yAxis: 0,
                    zIndex: 0,
                    tooltip: {
                        headerFormat: '{point.key}. ' + this.formatLongMonth(this.month) + '<br>',
                        pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br>● Målerstand: <b>{point.abs} kWt</b><br>',
                        valueSuffix: ' kWt',
                        valueDecimals: 1,
                    },
                    events: {
                        click: (e) => {
                            let day = moment(this.month).date(e.point.x).format("YYYY-MM-DD")
                            this.$store.commit('setDay', day);
                            EventBus.$emit('day-selected');
                        }
                    }
                },
                {
                    id: "3",
                    data: productionPreviousYear,
                    visible: false,
                    showInLegend: false,
                    name: (this.year - 1),
                    type: "column",
                    color: "#b8b8b8",
                    index: 0,
                    yAxis: 0,
                    zIndex: 0,
                    tooltip: {
                        valueSuffix: ' kWt',
                        valueDecimals: 1,
                    },
                },
                {
                    id: "4",
                    data: gridCost,
                    visible: false,
                    showInLegend: false,
                    name: "Nettleie",
                    type: "column",
                    stack: 'cost',
                    stacking: 'normal',
                    color: "#2196F3",
                    index: 1,
                    yAxis: 1,
                    zIndex: 0,
                    tooltip: {
                        valueSuffix: ' kr',
                        valueDecimals: 0,
                    },
                    events: {
                        click: (e) => {
                            let day = moment(this.month).date(e.point.x).format("YYYY-MM-DD")
                            this.$store.commit('setDay', day);
                            EventBus.$emit('day-selected');
                        }
                    }
                },
                {
                    id: "5",
                    data: electricityCost,
                    visible: false,
                    showInLegend: false,
                    name: "Kraft (estimert)",
                    type: "column",
                    stack: 'cost',
                    stacking: 'normal',
                    color: "#66BB6A",
                    index: 1,
                    yAxis: 1,
                    zIndex: 0,
                    tooltip: {
                        valueSuffix: ' kr',
                        valueDecimals: 0,
                    },
                    events: {
                        click: (e) => {
                            let day = moment(this.month).date(e.point.x).format("YYYY-MM-DD")
                            this.$store.commit('setDay', day);
                            EventBus.$emit('day-selected');
                        }
                    }
                },
                {
                    id: "6",
                    data: gridCostPreviousYear,
                    visible: false,
                    showInLegend: false,
                    name: 'Nettleie ' + (this.year - 1),
                    type: "column",
                    stack: 'costPreviousYear',
                    stacking: 'normal',
                    color: "#969696",
                    index: 0,
                    yAxis: 1,
                    zIndex: 0,
                    tooltip: {
                        valueSuffix: ' kr',
                        valueDecimals: 0,
                    },
                },
                {
                    id: "7",
                    data: electricityCostPreviousYear,
                    visible: false,
                    showInLegend: false,
                    name: 'Strøm ' + (this.year - 1),
                    type: "column",
                    stack: 'costPreviousYear',
                    stacking: 'normal',
                    color: "#b8b8b8",
                    index: 0,
                    yAxis: 1,
                    zIndex: 0,
                    tooltip: {
                        valueSuffix: ' kr',
                        valueDecimals: 0,
                    },
                },
                {
                    id: "8",
                    data: temperature,
                    visible: false,
                    showInLegend: false,
                    name: "Temperatur",
                    type: "spline",
                    color: "#F44336",
                    lineWidth: 2,
                    dashStyle: "ShortDot",
                    index: 2,
                    yAxis: 2,
                    zIndex: 1,
                    tooltip: {
                        valueSuffix: ' °C',
                        valueDecimals: 1,
                    },
                },
                {
                    id: "9",
                    data: prices,
                    visible: false,
                    showInLegend: false,
                    name: "Spotpris",
                    type: "spline",
                    color: "#F44336",
                    lineWidth: 2,
                    dashStyle: "ShortDot",
                    index: 2,
                    yAxis: 3,
                    zIndex: 1,
                    tooltip: {
                        valueSuffix: ' øre/kWt',
                        valueDecimals: 1,
                    },
                }
            ];
            this.updateChartVisibility();
            this.$refs.chart.chart.reflow();
        },

        updateChartVisibility() {
            if (this.chartOptions.series && this.chartOptions.series.length > 0) {
                this.chartOptions.series[0].visible = this.showEnergy && this.showConsumption;
                this.chartOptions.series[1].visible = this.showEnergy && this.showConsumption && this.showPreviousYear;
                this.chartOptions.series[2].visible = this.showEnergy && this.showProduction;
                this.chartOptions.series[3].visible = this.showEnergy && this.showProduction && this.showPreviousYear;
                this.chartOptions.series[4].visible = this.showCost;
                this.chartOptions.series[5].visible = this.showCost && this.showElectricityCost;
                this.chartOptions.series[6].visible = this.showCost && this.showPreviousYear;
                this.chartOptions.series[7].visible = this.showCost && this.showElectricityCost && this.showPreviousYear;
                this.chartOptions.series[8].visible = this.showTemperature;
                this.chartOptions.series[9].visible = this.showPrices;

                this.chartOptions.series[0].showInLegend = this.chartOptions.series[0].visible;
                this.chartOptions.series[2].showInLegend = this.chartOptions.series[2].visible;
                this.chartOptions.series[4].showInLegend = this.chartOptions.series[4].visible;
                this.chartOptions.series[5].showInLegend = this.chartOptions.series[5].visible;
                this.chartOptions.series[8].showInLegend = this.chartOptions.series[8].visible;
                this.chartOptions.series[9].showInLegend = this.chartOptions.series[9].visible;
            }

            this.chartOptions.yAxis[0].visible = this.showEnergy;
            this.chartOptions.yAxis[1].visible = this.showCost;
            this.chartOptions.yAxis[2].visible = this.showTemperature;
            this.chartOptions.yAxis[3].visible = this.showPrices;
        },

        monthsInYear(year) {
            return year && this.meterValues ? this.meterValues.consumption.months.map(each => each.month).filter(each => each.startsWith(year)) : [];
        },

        visibilityChanged(visible) {
            this.visible = visible;
            if (visible) {
                // Necessary in case browser window was resized
                this.$refs.chart.chart.reflow();
            }
        },

        scrollToSelectedMonth() {
            let buttonLeft = this.$refs[this.month]?.[0]?.$el?.offsetLeft;
            let buttonWidth = this.$refs[this.month]?.[0]?.$el?.offsetWidth;
            let scrollerWidth = this.$refs.scroller.$el?.offsetWidth;

            if (buttonLeft === undefined || buttonWidth === undefined || scrollerWidth === undefined) {
                return;
            }

            this.$refs.scroller.$el.scrollLeft = buttonLeft - (scrollerWidth - buttonWidth) / 2;
        }
    },

    computed: {
        months() {
            return this.monthsInYear(this.year);
        },

        lastMonthPreviousYear() {
            if (!this.year) {
                return null;
            }
            let months = this.monthsInYear(parseInt(this.year) - 1);
            return months.length > 0 ? months[months.length - 1] : null;
        },

        firstMonthNextYear() {
            if (!this.year) {
                return null;
            }
            let months = this.monthsInYear(parseInt(this.year) + 1);
            return months.length > 0 ? months[0] : null;
        },

        sumConsumptionSameMonthPreviousYear() {
            let todayLastYear = moment().subtract(1, 'year').format("YYYY-MM-DD");
            let sameMonthPreviousYear = moment(this.month).subtract(1, 'year').format("YYYY-MM");
            let consumption = this.meterValues?.consumption.days.filter(each => each.day.startsWith(sameMonthPreviousYear) &&
                                                                                each.day.localeCompare(todayLastYear) < 0)
            return consumption ? _.sumBy(consumption, 'sum') : 0;
        },

        sumProductionSameMonthPreviousYear() {
            let todayLastYear = moment().subtract(1, 'year').format("YYYY-MM-DD");
            let sameMonthPreviousYear = moment(this.month).subtract(1, 'year').format("YYYY-MM");
            let production = this.meterValues?.production.days.filter(each => each.day.startsWith(sameMonthPreviousYear) &&
                                                                              each.day.localeCompare(todayLastYear) < 0)
            return production ? _.sumBy(production, 'sum') : 0;
        },

        sumGridCostSameMonthPreviousYear() {
            let todayLastYear = moment().subtract(1, 'year').format("YYYY-MM-DD");
            let sameMonthPreviousYear = moment(this.month).subtract(1, 'year').format("YYYY-MM");
            let cost = this.meterValues?.cost.days.filter(each => each.day.startsWith(sameMonthPreviousYear) &&
                                                                  each.day.localeCompare(todayLastYear) < 0)
            return cost ? _.sumBy(cost, 'sumGridCost') : 0;
        },

        sumConsumptionThisMonthThisYear() {
            let today = moment().format("YYYY-MM-DD");
            let thisMonthThisYear = moment(this.month).format("YYYY-MM");
            let consumption = this.meterValues?.consumption.days.filter(each => each.day.startsWith(thisMonthThisYear) &&
                                                                                each.day.localeCompare(today) < 0)
            return consumption ? _.sumBy(consumption, 'sum') : 0;
        },

        sumProductionThisMonthThisYear() {
            let today = moment().format("YYYY-MM-DD");
            let thisMonthThisYear = moment(this.month).format("YYYY-MM");
            let production = this.meterValues?.production.days.filter(each => each.day.startsWith(thisMonthThisYear) &&
                                                                              each.day.localeCompare(today) < 0)
            return production ? _.sumBy(production, 'sum') : 0;
        },

        sumGridCostThisMonthThisYear() {
            let today = moment().format("YYYY-MM-DD");
            let thisMonthThisYear = moment(this.month).format("YYYY-MM");
            let cost = this.meterValues?.cost.days.filter(each => each.day.startsWith(thisMonthThisYear) &&
                                                                  each.day.localeCompare(today) < 0)
            return cost ? _.sumBy(cost, 'sumGridCost') : 0;
        },

        monthConsumption() {
            return this.meterValues?.consumption.months.find(each => each.month === this.month);
        },

        monthProduction() {
            return this.meterValues?.production.months.find(each => each.month === this.month);
        },

        monthCost() {
            return this.meterValues?.cost.months.find(each => each.month === this.month);
        },

        sumConsumption() {
            return this.monthConsumption?.sum;
        },

        sumProduction() {
            return this.monthProduction?.sum;
        },

        sumGridCost() {
            return this.monthCost?.sumGridCost;
        },

        itemizedGridCost() {
            return this.monthCost?.itemizedGridCost;
        },

        maxConsumption() {
            return this.monthConsumption?.max;
        },

        maxProduction() {
            return this.monthProduction?.max;
        },

        maxGridCost() {
            return this.monthCost?.maxGridCost;
        },

        averageConsumption() {
            return this.monthConsumption?.average;
        },

        averageProduction() {
            return this.monthProduction?.average;
        },

        averageGridCost() {
            return this.monthCost?.averageGridCost;
        },

        timeOfMaxConsumption() {
            return this.monthConsumption?.timeOfMax;
        },

        timeOfMaxProduction() {
            return this.monthProduction?.timeOfMax;
        },

        timeOfMaxGridCost() {
            return this.monthCost?.timeOfMaxGridCost;
        }
    },

    watch: {
        meterValues() {
            this.updateChart();
            this.loadHourValues();
        },

        showDetails() {
            if (this.showDetails) {
                this.$vuetify.goTo('#details-anchor');
                this.loadHourValues();
            }
        },

        selectedButton() {
            if (this.selectedButton) {
                if (this.selectedButton.indexOf('-') !== -1) {
                    this.$store.commit("setMonth", this.selectedButton);
                } else {
                    this.$store.commit("setYear", this.selectedButton);
                }
            }
        },

        month() {
            this.selectedButton = this.month;
            this.updateChart();
            this.loadHourValues();

            this.$nextTick(() => {
                this.scrollToSelectedMonth();
            });
        },

        valueType() {
            this.updateChartVisibility();
        },

        chartType() {
            this.updateChartVisibility();
        },

        showPreviousYear() {
            this.updateChartVisibility();
        },

        showElectricityCost() {
            this.updateChartVisibility();
        },

        showTemperature() {
            this.updateChartVisibility();
        },

        showPrices() {
            this.updateChartVisibility();
        }
    },

    mounted() {
        this.selectedButton = this.month;
        this.updateChartVisibility();
        this.updateChart();
        this.loadHourValues();
        this.scrollToSelectedMonth();
    }
}
</script>
