<template>

    <v-layout v-if="hasProduction || hasTariff" class="pb-2">

        <v-spacer v-if="center"/>

        <v-btn-toggle v-if="hasProduction"
                      mandatory
                      rounded
                      dense
                      color="primary"
                      v-model="valueType">
            <v-btn small :disabled="showCost" value="CONSUMPTION">Forbruk</v-btn>
            <v-btn small :disabled="showCost" value="PRODUCTION">Produksjon</v-btn>
        </v-btn-toggle>

        <v-spacer v-if="!center"/>

        <v-btn-toggle v-if="hasTariff"
                      mandatory
                      rounded
                      dense
                      color="primary"
                      v-model="chartType">
            <v-btn small value="ENERGY">kWt</v-btn>
            <v-btn small value="COST">kr</v-btn>
        </v-btn-toggle>

        <v-spacer v-if="center"/>

    </v-layout>

</template>

<script>

import Common from "@/mixins/Common"

export default {
    name: 'ValueTypeSelector',
    mixins: [Common],

    computed: {
        center() {
            return this.hasProduction && !this.hasTariff ||
                   !this.hasProduction && this.hasTariff;
        }
    }
}
</script>
