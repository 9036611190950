import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n'
import App from './App'
import Welcome from './components/Welcome'
import Authenticate from './components/Authenticate'
import Status from './components/Status'
import Twitter from './components/Twitter'
import Facebook from './components/Facebook'
import OutageMap from './components/OutageMap'
import MeterValues from './components/MeterValues'
import VueResource from 'vue-resource'
import VueObserveVisibility from 'vue-observe-visibility'
import VueGeolocation from 'vue-browser-geolocation';
import Highcharts from 'highcharts'
import stockInit from 'highcharts/modules/stock'
import heatmapInit from 'highcharts/modules/heatmap'
import HighchartsVue from 'highcharts-vue'
import Vuetify from 'vuetify/lib'
import { register } from 'register-service-worker'
import {messages} from './messages'
import 'aws-sdk';
import moment from "moment-timezone";
import '@mdi/font/css/materialdesignicons.css'
import { initializeApp } from "firebase/app";
import {getMessaging, onMessage} from "firebase/messaging";
import InsightMessages from "@/components/InsightMessages.vue";
import {EventBus} from "@/eventbus";


stockInit(Highcharts);
heatmapInit(Highcharts);

Vue.use(Vuetify);
Vue.use(VueResource);
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(HighchartsVue);
Vue.use(VueObserveVisibility);
Vue.use(VueGeolocation);

Vue.config.productionTip = false;

//
// Create Vuex store.  This is the shared data model of the app.
//

const store = new Vuex.Store({
                                 state: {

                                     // Whether there is an updated version of the app is available.
                                     updateAvailable: false,

                                     // The number of pending REST requests.
                                     pendingRequests: 0,

                                     // Cognito JWT tokens
                                     cognito: {
                                         refreshToken: localStorage.getItem("refreshToken"),
                                         idToken: null
                                     },

                                     meterPointsLoaded: false,

                                     // Available meterpoints for user.
                                     meterPoints: [],

                                     // The currently selected meterpoint.
                                     meterPoint: null,

                                     // Metervalues for the currently selected meterpoint.
                                     meterValues: null,

                                     // The currently selected year, e.g., "2019".
                                     year: null,

                                     // The currently selected month, e.g., "2019-05".
                                     month: null,

                                     // The currently selected day, e.g., "2019-05-17".
                                     day: null,

                                     // The selected value type (consumption or production).
                                     valueType: 'CONSUMPTION',

                                     // The selected chart type (kWt or kr).
                                     chartType: 'ENERGY',

                                     // Whether to show values from previous year in charts.
                                     showPreviousYear: false,

                                     // Whether to show estimated electricity cost in charts.
                                     showElectricityCost: true,

                                     // Whether to show temperatures in charts.
                                     showTemperature: true,

                                     // Whether to show prices in charts.
                                     showPrices: false,

                                     // The currently selected metervalues tab.
                                     meterValuesTab: 0,

                                     // Used for PWA install
                                     beforeInstallPrompt: null,

                                     messages : [],

                                     // Application properties. Loaded from http server on startup.
                                     properties: null,

                                     // Used to display an error dialog.
                                     error: false,
                                     errorMessage: null
                                 },

                                 mutations: {
                                     setUpdateAvailable(state, updateAvailable) {
                                         state.updateAvailable = updateAvailable;
                                     },
                                     setLoading(state, loading) {
                                         if (loading) {
                                             state.pendingRequests++;
                                         } else {
                                             state.pendingRequests--;
                                         }
                                     },
                                     setCognitoRefreshToken(state, refreshToken) {
                                         state.cognito.idToken = null;
                                         state.cognito.refreshToken = refreshToken;
                                         if (refreshToken) {
                                             localStorage.setItem("refreshToken", refreshToken);
                                         } else {
                                             localStorage.removeItem("refreshToken");
                                         }
                                     },
                                     setCognitoIdToken(state, idToken) {
                                         state.cognito.idToken = idToken;
                                     },
                                     setCognitoAuth(state, cognitoAuth) {
                                         state.cognito.auth = cognitoAuth;
                                     },
                                     setBeforeInstallPrompt(state, beforeInstallPrompt) {
                                         state.beforeInstallPrompt = beforeInstallPrompt;
                                     },
                                     setProperties(state, properties) {
                                         state.properties = properties;
                                     },
                                     setMeterPoints(state, meterPoints) {
                                         state.meterPoints = meterPoints;
                                     },
                                     setMeterPointsLoaded(state, meterPointsLoaded) {
                                         state.meterPointsLoaded = meterPointsLoaded;
                                     },
                                     setMeterPoint(state, meterPoint) {
                                         state.meterPoint = meterPoint;
                                     },
                                     setMeterValues(state, meterValues) {
                                         state.meterValues = meterValues;
                                     },
                                     setYear(state, year) {
                                         if (state.year === year) {
                                             return;
                                         }
                                         state.year = year;
                                         console.log("year " + state.year);
                                         if (year && state.meterValues) {
                                             state.month = state.meterValues ? state.meterValues.consumption.months.find(e => e.month.startsWith(year))?.month : null;
                                             console.log("month " + state.month);
                                             state.day = state.meterValues ? state.meterValues.consumption.days.find(e => e.day.startsWith(year))?.day : null;
                                             console.log("day " + state.day);
                                         }
                                     },
                                     setMonth(state, month) {
                                         if (state.month === month) {
                                             return;
                                         }
                                         state.month = month;
                                         console.log("month " + month);
                                         if (month) {
                                             state.year = month.split('-')[0];
                                             console.log("year " + state.year);
                                             if (state.meterValues) {
                                                 state.day = state.meterValues ? state.meterValues.consumption.days.find(e => e.day.startsWith(month))?.day : null;
                                                 console.log("day " + state.day);
                                             }
                                         }
                                     },
                                     setDay(state, day) {
                                         if (state.day === day) {
                                             return;
                                         }
                                         state.day = day;
                                         console.log("day " + state.day);
                                         if (day) {
                                             let parts = day.split('-');
                                             state.year = parts[0];
                                             console.log("year " + state.year);
                                             state.month = parts[0] + '-' + parts[1];
                                             console.log("month " + state.month);
                                         }
                                     },
                                     setValueType(state, valueType) {
                                         state.valueType = valueType;
                                     },
                                     setChartType(state, chartType) {
                                         state.chartType = chartType;
                                     },
                                     setShowPreviousYear(state, showPreviousYear) {
                                         state.showPreviousYear = showPreviousYear;
                                         localStorage.setItem("customer.showPreviousYear", JSON.stringify(showPreviousYear));
                                     },
                                     setShowElectricityCost(state, showElectricityCost) {
                                         state.showElectricityCost = showElectricityCost;
                                         localStorage.setItem("customer.showElectricityCost", JSON.stringify(showElectricityCost));
                                     },
                                     setShowTemperature(state, showTemperature) {
                                         if (showTemperature) {
                                             state.showPrices = false;
                                             localStorage.setItem("customer.showPrices", JSON.stringify(false));
                                         }
                                         state.showTemperature = showTemperature;
                                         localStorage.setItem("customer.showTemperature", JSON.stringify(showTemperature));
                                     },
                                     setShowPrices(state, showPrices) {
                                         if (showPrices) {
                                             state.showTemperature = false;
                                             localStorage.setItem("customer.showTemperature", JSON.stringify(false));
                                         }
                                         state.showPrices = showPrices;
                                         localStorage.setItem("customer.showPrices", JSON.stringify(showPrices));
                                     },
                                     setMeterValuesTab(state, meterValuesTab) {
                                         state.meterValuesTab = meterValuesTab;
                                     },
                                     setMessages(state, messages) {
                                         state.messages = messages;
                                     },
                                     setError(state, error) {
                                         state.error = error;
                                     },
                                     setErrorMessage(state, errorMessage) {
                                         state.errorMessage = errorMessage;
                                     }
                                 }
                             });

let loadFromLocalStorage = (key, setter) => {
    let value = JSON.parse(localStorage.getItem("customer." + key));
    if (value !== null) {
        store.commit(setter, value);
    }
};

loadFromLocalStorage("showPreviousYear", "setShowPreviousYear");
loadFromLocalStorage("showElectricityCost", "setShowElectricityCost");
loadFromLocalStorage("showTemperature", "setShowTemperature");
loadFromLocalStorage("showPrices", "setShowPrices");

//
// Register service worker and notify user if a new version is available.
//
// Due to firebase serviceworker, service worker registration is performed even for development
let ServiceWorkerUrl =  `${process.env.BASE_URL}service-worker.js`

if (process.env.NODE_ENV !== 'production') {
   ServiceWorkerUrl =  `${process.env.BASE_URL}custom-service-worker.js`
}
    console.log("Registering service worker url: " + ServiceWorkerUrl);
    register(ServiceWorkerUrl, {
        registered(registration) {
            Vue.prototype.$swRegistration = registration;
            console.log(registration);
            navigator.serviceWorker.addEventListener('message', event => {
                // Receive events from service worker
                // This only works when application is in background mode and not when the application is closed,
                // however when the app is loaded it fetches the last messges from server and compare max timestamp from
                // received messages with max timestamp from previous messages stored in local storage.
                // If newer messages then set new messages icon on messages tab.
                console.log('Push notification event from service worker or main thread', event);
                if (event?.data?.data === "pushnotificationclicked") {
                    console.log('Emit event: get-latest-messages');
                    EventBus.$emit('get-latest-messages');
                } else {
                    console.log('Emit event: push-notification');
                    EventBus.$emit('push-notification');
                }
            });

        },
        ready() {
            console.log('App is being served from cache by a service worker.\n For more details, visit https://goo.gl/AFskqB');
        },
        cached() {
            console.log('Content has been cached for offline use.');
        },
        updated() {
            console.log('New content is available; please refresh.');
            store.commit("setUpdateAvailable", true);
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.');
        },
        error(error) {
            console.error('Error during service worker registration:', error);
        }
    });

//
// Add interceptor for outgoing REST requests.
//

const uuidv4 = require('uuid/v4');
Vue.http.interceptors.push(function (request) {

    store.commit("setLoading", true);

    // Add JWT token
    if (store.state.cognito.idToken && request.url.indexOf("X-Amz-Security-Token") === -1) {
        request.headers.set('Authorization', store.state.cognito.idToken);
    }

    // Add breadcrumb
    request.headers.set("breadcrumbId", uuidv4());

    // return response callback
    return function(response) {
        store.commit("setLoading", false);
    };
});


//
// Create and configure Vuetify
//

import nb from 'vuetify/es5/locale/no'

const vuetify = new Vuetify(
        {
            icons: {
                iconfont: 'mdi',
            },
            theme: {dark: false},
            lang: {
                locales: { nb },
                current: 'nb',
            },

        });

//
// Create VueI18n instance with Norwegian locale.
//

const i18n = new VueI18n({locale: 'nb', messages});


//
// Set Norwegian locale for moment.js
//

moment.locale("nb");


//
// Set Norwegian texts for Highcharts
//

Highcharts.setOptions({
                          lang: {
                              months: ["januar", "februar", "mars", "april", "mai", "juni", "juli", "august", "september", "oktober", "november", "desember"],
                              shortMonths: ["jan", "feb", "mar", "apr", "mai", "jun", "jul", "aug", "sep", "okt", "nov", "des"],
                              weekdays: ["søndag", "mandag", "tirsdag", "onsdag", "torsdag", "fredag", "lørdag"],
                              shortWeekdays: ["søn", "man", "tir", "ons", "tor", "fre", "lør"],
                              loading: "Laster inn...",
                              noData: "Ingen verdier",
                              resetZoom: "Tilbakestill zoom",
                              resetZoomTitle: "Tilbakestill zoom",
                              decimalPoint: ","
                          }
                      });


//
// Load properties.json over http
//

let propertiesUrl = (process.env.NODE_ENV === "production")
                    ? "properties.json?" + Date.now()  // Force reload
                    : "properties-local.json";

Vue.http.get(propertiesUrl).then(response => {

    store.commit("setProperties", response.body);

    // Set title and favicon
    document.title = "Min strøm - " + response.body.NetOwnerName;

    let favIcon = document.createElement("link");
    favIcon.setAttribute("rel", "shortcut icon");
    favIcon.setAttribute("href", "/img/icons/" + response.body.NetOwnerCode + "/favicon.png");

    let touchIcon = document.createElement("link");
    touchIcon.setAttribute("rel", "apple-touch-icon");
    touchIcon.setAttribute("href", "/img/icons/" + response.body.NetOwnerCode + "/apple-touch-icon.png");

    let head = document.querySelector("head");
    head.appendChild(favIcon);
    head.appendChild(touchIcon);

    // Listen for beforeinstallprompt
    window.addEventListener('beforeinstallprompt', e => {
        console.log("Got beforeinstallprompt: " + e);
        store.commit("setBeforeInstallPrompt", e);
    });

    //Lock orientation on Safari version 16.4 and above.
    const myUserAgent = navigator.userAgent;
    if (myUserAgent.includes("Safari")) {
        const currentVersion = myUserAgent.match(/Version\/(\d+\.\d+)/);
        if (currentVersion) {
            const versionNumbers = currentVersion[1].split('.').map(Number);
            const majorVersion = versionNumbers[0];
            const minorVersion = versionNumbers[1];

            if (majorVersion < 16 || (majorVersion === 16 && minorVersion < 4)) {
                if (screen && screen.orientation) {
                    screen.orientation.lock('portrait').catch(reason => {
                        console.log("Orientation lock not available");
                    });
                }
            }
        }
    }

    //
    // Setup routing
    //

    const router = new VueRouter({
                                     routes: [
                                         { path: '/welcome', component: Welcome },
                                         { path: '/authenticate', component: Authenticate },
                                         { path: '/authenticate/:phoneNumber/:codeId', component: Authenticate },
                                         { path: '/status', component: Status },
                                         { path: '/metervalues', component: MeterValues },
                                         { path: '/twitter', component: Twitter },
                                         { path: '/facebook', component: Facebook },
                                         { path: '/outage', component: OutageMap },
                                         { path: '/insightmessages', component: InsightMessages }
                                     ]
                                 });

    //
    // Create Vue app
    //

    new Vue({
                render: h => h(App),
                store,
                router,
                i18n,
                vuetify,

                methods: {
                    authenticate() {
                        if (this.properties && this.refreshToken) {
                            let idp = new AWS.CognitoIdentityServiceProvider({
                                                                                 region: "eu-west-1",
                                                                                 accessKeyId: "void",
                                                                                 secretAccessKey: "void"
                                                                             });
                            idp.initiateAuth({
                                                 AuthFlow: "REFRESH_TOKEN_AUTH",
                                                 ClientId: this.$store.state.properties.ClientId,
                                                 AuthParameters: {
                                                     "REFRESH_TOKEN": this.refreshToken
                                                 }
                                             },
                                             (err, data) => {
                                                 if (err) {
                                                     console.log("Failed to refresh ID token. Retrying in 10 seconds.");
                                                     console.log(err, err.stack);
                                                     setTimeout(() => {
                                                         this.authenticate();
                                                     }, 10 * 1000);
                                                 }
                                                 else {
                                                     this.$store.commit("setCognitoIdToken", data.AuthenticationResult.IdToken);
                                                     console.log("Successfully refreshed ID token");

                                                     // Re-authenticate in 10 minutes
                                                     setTimeout(() => {
                                                         this.authenticate();
                                                     }, 10 * 60 * 1000);

                                                 }
                                             });
                        }
                    },
                    configureFireBase() {

                        if (!this.$store.state.properties ||
                            !this.$store.state.properties.FireBaseConfigApiKey ||
                            !this.$store.state.properties.FireBaseConfigAuthDomain ||
                            !this.$store.state.properties.FireBaseConfigProjectId ||
                            !this.$store.state.properties.FireBaseConfigStorageBucket ||
                            !this.$store.state.properties.FireBaseConfigMessagingSenderId ||
                            !this.$store.state.properties.FireBaseConfigAppId) {
                            console.error("Push notification. NO CONFIG in properties");
                            return;
                        }

                        let fireBaseConfig = {
                            "apiKey": this.$store.state.properties.FireBaseConfigApiKey,
                            "authDomain": this.$store.state.properties.FireBaseConfigAuthDomain,
                            "projectId": this.$store.state.properties.FireBaseConfigProjectId,
                            "storageBucket": this.$store.state.properties.FireBaseConfigStorageBucket,
                            "messagingSenderId": this.$store.state.properties.FireBaseConfigMessagingSenderId,
                            "appId": this.$store.state.properties.FireBaseConfigAppId
                        };

                        //console.log("Push notification Vue. FireBease Config: " + JSON.stringify(fireBaseConfig));

                        let firebaseApp = initializeApp(fireBaseConfig);

                        let firebasemessaging = getMessaging(firebaseApp);
                        Vue.prototype.$messaging = firebasemessaging;
                    }
                },

                computed: {
                    properties() {
                        return this.$store.state.properties;
                    },
                    refreshToken() {
                        return this.$store.state.cognito.refreshToken;
                    }
                },

                watch: {
                    properties() {
                        console.log("Properties changed. Authenticating.");
                        this.authenticate();
                        this.configureFireBase();
                    },
                    refreshToken() {
                        console.log("Refresh token changed. Authenticating.");
                        this.authenticate();
                    }
                },
                mounted() {
                    this.authenticate();
                    this.configureFireBase();
                }
            }
    ).$mount('#app');

}, response => {
    console.log(response);
});