<template>
    <div>

        <div class="text-center text-button">Fordeling av nettleie</div>

        <highcharts ref="chart" constructor-type="stockChart" :options="chartOptions"
                    style="width:100%; height: 130px"
                    v-observe-visibility="visibilityChanged"/>

    </div>
</template>

<script>

import Common from "../mixins/Common"
import _ from "lodash";

export default {
    name: 'ItemizedGridCost',
    mixins: [Common],
    components: {},
    props: ['values'],

    data() {
        return {
            chartOptions: {
                chart: {
                    type: 'bar',
                    backgroundColor: "transparent",
                    panning: false,
                    spacingBottom: 0,
                    style: {
                        fontFamily: "Roboto"
                    }
                },
                navigator: {
                    enabled: false
                },
                rangeSelector: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                tooltip: {
                    enabled: false
                },
                xAxis: {
                    type: 'category',
                },
                yAxis: {
                    visible: false
                },
                title: {
                    text: null
                },
                legend: {
                    enabled: false,
                },
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true,
                        }
                    }
                },
                exporting: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                loading: {
                    labelStyle: {
                        fontWeight: "normal"
                    }
                },
                series: [],
            }
        }
    },

    methods: {

        updateChart() {
            if (!this.values) {
                return;
            }

            let data = [];
            let x = 0;

            if (this.values.fixed) {
                data.push({
                              x: x++,
                              y: this.values.fixed,
                              name: this.fixedPriceBasis ? 'Kapasitetsledd' : 'Fastledd',
                              color: "#90CAF9"
                          });
            }

            _.forEach(this.values.power, (value, key) => {
                data.push({
                              x: x++,
                              y: value,
                              name: this.formatPowerLevelName(key),
                              color: "#90CAF9"
                          });
            });

            _.forEach(this.values.energy, (value, key) => {
                data.push({
                              x: x++,
                              y: value,
                              name: 'Energi: ' + key,
                              color: "#2196F3"
                          });
            });

            data.push({
                          x: x++,
                          y: this.values.fee,
                          name: 'Avgifter',
                          color: "#1565C0"
                      });

            this.chartOptions.series = [{data: data}];

            let self = this;
            this.chartOptions.plotOptions.series.dataLabels.formatter = function () {
                return self.formatNumber(this.y, 0) + ' kr';
            };

        },

        formatPowerLevelName(key) {
            switch (key) {
                case 'POWER':
                    return 'Effekt';
                case 'FLAT':
                    return 'Effekt: Fastpris';
                default:
                    return 'Effekt: ' + key
            }
        },

        visibilityChanged(visible) {
            if (visible) {
                // Necessary in case browser window was resized
                this.$refs.chart.chart.reflow();
            }
        },
    },

    computed: {},

    watch: {
        values() {
            this.updateChart();
        },
    },

    mounted() {
        this.updateChart();
    }
}
</script>
