<template>

    <v-card color="grey lighten-4"
            class="mx-auto mb-3">
        <v-layout>
            <div class="body-1 mx-auto pt-4">
                Snittproduksjon / ukedag
            </div>
        </v-layout>

        <highcharts class="py-2" ref="chart" constructor-type="stockChart" :options="chartOptions"
                    style="height:200px; width:100%"/>
    </v-card>
</template>

<script>

    import Common from '../../mixins/Common'
    import moment from 'moment-timezone';
    import _ from 'lodash';

    export default {
        name: 'ProductionByDayOfWeek',
        mixins: [Common],

        data() {
            return {
                chartOptions: {
                    chart: {
                        backgroundColor: "transparent",
                        panning: false,
                        style: {
                            fontFamily: "Roboto"
                        }
                    },
                    navigator: {
                        enabled: false
                    },
                    rangeSelector: {
                        enabled: false
                    },
                    scrollbar: {
                        enabled: false
                    },
                    xAxis: {
                        ordinal: true,
                        type: 'linear',
                        min: 0,
                        max: 6,
                        categories: ['man', 'tir', 'ons', 'tor', 'fre', 'lør', 'søn'],
                    },
                    yAxis: {
                        title: {
                            text: 'Døgnproduksjon (kWt)',
                        },
                        opposite: false,
                        gridLineWidth: 0,
                        plotLines: [
                            {
                                dashStyle: 'Dash',
                                width: 1,
                                value: 0.0,
                                zIndex: 3,
                                // label: {
                                //     text: 'Snitt',
                                //     style: {
                                //         color: "rgba(255,19,23,0.67)",
                                //     }
                                // },
                                color: "rgba(255,19,23,0.67)",
                            }

                        ],
                    },
                    title: {
                        text: '',
                    },
                    time: {
                        getTimezoneOffset: timestamp => -moment.tz(timestamp, 'Europe/Oslo').utcOffset()
                    },
                    tooltip: {
                        split: false,
                        shared: true,
                        useHTML: true,
                    },
                    legend: {
                        enabled: false,
                    },
                    plotOptions: {
                        column: {
                            dataGrouping: {
                                enabled: false,
                            }
                        },
                    },
                    exporting: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                    loading: {
                        labelStyle: {
                            fontWeight: "normal"
                        }
                    },
                    series: [{id: '0', data: []}],
                }
            }
        },

        methods: {
            updateChart() {
                if (!this.meterValues || !this.meterValues.production) {
                    return;
                }

                let values = this.meterValues.production.averageByDayOfWeek.map(each => [each[0], 24 * each[1]]);
                this.chartOptions.series = [
                    {
                        id: '0',
                        data: values,
                        name: 'Døgnproduksjon',
                        type: "column",
                        color: "#66BB6A",
                        index: 0,
                        yAxis: 0,
                        zIndex: 0,
                        tooltip: {
                            valueSuffix: ' kWt',
                            valueDecimals: 2
                        },
                    }
                ];

                this.chartOptions.yAxis.plotLines[0].value = _.mean(values.map(each => each[1]));
            }
        },

        computed: {
        },

        watch: {
            meterValues() {
                this.updateChart();
            },
        },

        mounted() {
            this.updateChart();
        }
    }
</script>
