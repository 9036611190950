<template>

    <v-dialog v-model="visible" max-width="350">
        <v-card>

            <v-card-title>
            </v-card-title>

            <v-card-text>

                <highcharts v-show="this.chartOptions.series.length > 0"
                            ref="chart" constructor-type="stockChart" :options="chartOptions"
                            style="width:100%; height:250px"
                            class="pb-5"/>

                <div class="pb-5">
                    Spotpris er prisen strøm omsettes for på kraftbørsen Nord Pool.
                </div>

                <div class="pb-5">
                    <span v-if="meterPoint && meterPoint.vatIncluded">
                        Beløpet inkluderer mva men ikke påslag, og varierer fra time til time.
                    </span>
                    <span v-else>
                        Beløpet er uten mva og påslag, og varierer fra time til time.
                    </span>
                </div>
                <div>
                    Merk at dette ikke er relatert til nettleien som du betaler til {{ netownerName }}.
                </div>
            </v-card-text>

            <v-card-actions>
                <v-spacer/>
                <v-btn text color="primary" href="https://www.nve.no/reguleringsmyndigheten/kunde/strom/alt-om-strom/" target="_blank" class="mr-2">Les mer</v-btn>
                <v-btn text color="primary" @click="visible = false">Lukk</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>

import Common from "@/mixins/Common"
import moment from "moment-timezone";

export default {
    name: 'SpotPriceInfo',
    mixins: [Common],
    components: {},
    props: ['value'],

    data() {
        return {
            chartOptions: {
                chart: {
                    backgroundColor: "transparent",
                    panning: false,
                    spacingBottom: 0,
                    style: {
                        fontFamily: "Roboto"
                    }
                },
                navigator: {
                    enabled: false
                },
                rangeSelector: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                tooltip: {
                    split: false,
                    shared: true,
                },
                xAxis: {
                    ordinal: false,
                    type: 'datetime',
                    tickInterval: 6 * 3600 * 1000,
                    plotLines: [
                        {
                            dashStyle: 'Dash',
                            width: 1,
                            value: 0,
                            label: {
                                text: 'Nå',
                                rotation: 0,
                                style: {
                                    fontSize: '11px',
                                }
                            },
                        }
                    ]
                },
                yAxis: [
                    {
                        visible: true,
                        title: {
                            align: 'high',
                            offset: 5,
                            text: 'øre',
                            rotation: 0,
                            y: 15
                        },
                        labels: {
                            x: -5
                        },
                        min: 0,
                        opposite: false,
                        allowDecimals: false
                    }
                ],
                title: {
                    text: null
                },
                time: {
                    getTimezoneOffset: timestamp => -moment.tz(timestamp, 'Europe/Oslo').utcOffset()
                },
                legend: {
                    enabled: false,
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom',
                },
                plotOptions: {
                    column: {
                        dataGrouping: {
                            enabled: false,
                        }
                    },
                },
                exporting: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                loading: {
                    labelStyle: {
                        fontWeight: "normal"
                    }
                },
                series: [],
            }
        }
    },

    computed: {
        visible: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },

    watch: {
        visible() {
            if (!this.visible) {
                return;
            }

            if (!this.meterValues?.price?.latestPublished) {
                this.chartOptions.series = [];
                return;
            }

            let prices = this.meterValues.price.latestPublished.map(each => ({
                x: moment(each.time).valueOf(),
                y: each.value * 100,
                color: each.time === this.meterValues.price.current.time ? 'red' : '#2196F3'
            }));

            this.chartOptions.series = [
                {
                    id: '0',
                    data: prices,
                    name: 'Spotpris',
                    type: 'column',
                    index: 0,
                    tooltip: {
                        valueSuffix: ' øre/kWt',
                        valueDecimals: 1,
                        dateTimeLabelFormats: {
                            hour: '%A %e. %b  %H:%M'
                        }
                    }}
            ];

            this.chartOptions.xAxis.plotLines[0].value = moment(this.meterValues.price.current.time).valueOf();
            this.chartOptions.xAxis.plotLines[0].label.text = 'Nå: ' + Math.round(100 * this.meterValues.price.current.value) + ' øre';
        }
    }
}
</script>
