//
// Internationalized strings.
//

export const messages = {
    nb: {
        messages: {
            messages: 'Meldinger',
            updates: 'for oppdateringer.',
            see: 'se',
            active: 'Aktiv',
            types: {
                maintenance: 'Vedlikehold',
                correction: 'Feilretting',
                troubleshooting: 'Feilsøk',
                upgrade: 'Oppgradering',
                rationing: 'Rasjonering',
                ongoingError: 'Pågående driftforstyrrelse',
            },
            map: {
                layer_map_dark: 'Kart (mørk)',
                layer_map: 'Kart',
                layer_satellite_dark: 'Satelitt (mørk)',
                layer_satellite: 'Satelitt',
            },
            help: {
                meaning: 'Symbolforklaringer',
                outage: 'Strømbrudd',
                outageExplained: 'Flere strømmålere har sendt automatisk strømbruddsalarm',
                plannedOutage: 'Planlagt strømstans',
                plannedOutageExplained: 'Nettselskapet varsler en framtidig strømstans',
                activePlannedOutage: 'Pågående strømstans',
                activePlannedOutageExplained: 'En driftsmelding er aktiv'
            },
            social: {
                dialogTitle: 'Hjelp',
                dialogContent: 'For å se tidslinjer på X, må du være logget på X i nettleseren din. I tillegg til å tillate tredjeparts informasjonskapsler i nettleseren din. Dette er på grunn av oppdateringer fra X.',
                dialogInstruction1: 'Trinn 1:',
                dialogInstructionLoginX: 'Logg på X i standard nettleser din.',
                dialogInstruction2: 'Trinn 2:',
                dialogInstruction2Clarify: 'Dette trinnet må bare gjøres i den valgte standardnettleseren.',
                safariInstruction1: 'Åpne Innstillinger på iPhone.',
                safariInstruction2: 'Velg Safari.',
                safariInstruction3: 'Under Personvern og sikkerhet slår du av "Blokker alle informasjonskapsler".',
                chromeInstruction1: 'Åpne Chrome.',
                chromeInstruction2: 'Gå inn i nettleserens innstillinger.',
                chromeInstruction3: 'Velg Personvern og sikkerhet.',
                chromeInstruction4: 'Velg tredjeparts informasjonskapsler.',
                chromeInstruction5: 'Velg Tillat tredjeparts informasjonskapsler.',
                firefoxInstruction1: 'Åpne Firefox.',
                firefoxInstruction2: 'Gå inn i nettleserens innstillinger.',
                firefoxInstruction3: 'Velg Personvern og sikkerhet.',
                firefoxInstruction4: 'Under Nettleserpersonvern velger du "Tilpasset".',
                firefoxInstruction5: 'Kryss informasjonskapsler og velg "Infokapsler fra ubesøkte nettsteder".',
                disclaimer: 'Å tillate tredjeparts informasjonskapsler betyr at noen nettsteder kan spore aktiviteten din på andre nettsteder ved hjelp av informasjonskapsler. Dette kan resultere i tilpassede annonser og innhold på forskjellige sider. For økt personvern anbefales det å holde blokkering av tredjeparts informasjonskapsler aktivert.',
                toShowTweets: 'For å se innlegg her, må du være logget på X (tidligere Twitter), og tillate tredjeparts informasjonskapsler i nettleseren din.',
                readMore: 'Hjelp'
            },
            insightMessages: {
                date: 'Dato',
                SMS: 'Sms',
                PUSH: 'Push varsel',
                PREFERE_PUSH: "Foretrekk push varsel"
            },
            authenticate: {
                youHaveAuthenticatedYourPhone: 'Takk! Du har nå verifisert telefonnummeret ditt.'
            },
            troubleshootMessage: 'Vi har mottatt signal om strømbrudd, og undersøkelser pågår.',
            plannedOutage: 'Planlagt strømstans for',
            plannedOutageError: 'Nettselskapet jobber med',
            outage: 'Strømbrudd',
            noOutage: 'Ingen strømstans',
            extensiveOutage: 'Ved omfattende strømbrudd kan det ta noe tid fra strømmen kommer tilbake og til området friskmeldes.',
            close: 'Lukk',
            pushNotification: {
                description: 'Gir applikasjonen mulighet til å motta push varsel ved strømbrudd og andre hendelser',
                pushNotification: 'Push varsel',
                refreshToken: 'Oppdater token',
                FCMKey: 'FCM nøkkel',
                keyDate: 'Nøkkel dato'
            }
        },
    },


    sv: {
        messages: {
            messages: 'Meddelanden',
            updates: 'för uppdateringar.',
            see: 'se',
            active: 'Aktiv',
            types: {
                maintenance: 'Underhåll',
                correction: 'Korrektion',
                troubleshooting: 'Felsökning',
                upgrade: 'Uppgradering',
                rationing: 'Rationering',
                ongoingError: 'Pågående driftstörningar'
            },
            map: {
                layer_map_dark: 'Karta (mörk)',
                layer_map: 'Karta',
                layer_satellite_dark: 'Satellit (mörk) ',
                layer_satellite: 'Satellit',
            },
            help: {
                meaning: 'Symbolförklaringar',
                outage: 'Strömavbrott',
                outageExplained: 'Området upplever strömavbrott',
                plannedOutage: 'Planerat strömavbrott',
                plannedOutageExplained: 'Det är schemalagt underhåll av området',
                activePlannedOutage: 'Aktivt schemalagt strömavbrott',
                activePlannedOutageExplained: 'Schemalagt underhåll har påbörjat, men det är inte registrerat strömavbrott'
            },
            social: {
                dialogTitle: 'Hjälp',
                dialogContent: 'För att visa tidslinjer på X måste du vara inloggad på X i din webbläsare. Samt tillåta tredjepartskakor i din webbläsare. Detta på grund av uppdateringar från X. ',
                dialogInstruction1: 'Steg 1:',
                dialogInstructionLoginX: 'Logga in på X i din standardwebbläsare.',
                dialogInstruction2: 'Steg 2:',
                dialogInstruction2Clarify: 'Detta steg behöver endast göras i din valda standardwebbläsare.',
                safariInstruction1: 'Öppna Inställningar i din iPhone.',
                safariInstruction2: 'Välj Safari.',
                safariInstruction3: 'Under Sekretess och säkerhet, slå av "Blockera Alla Kakor".',
                chromeInstruction1: 'Öppna Chrome.',
                chromeInstruction2: 'Gå in i webbläsarens inställningar.',
                chromeInstruction3: 'Välj Integritet och säkerhet.',
                chromeInstruction4: 'Välj Tredjepartcookies.',
                chromeInstruction5: 'Välj Tillåt cookies från tredje part.',
                firefoxInstruction1: 'Öppna Firefox.',
                firefoxInstruction2: 'Gå in i webbläsarens inställningar.',
                firefoxInstruction3: 'Välj Sekretess & säkerhet.',
                firefoxInstruction4: 'Under Webbläsarintegritet, välj "Anpassad".',
                firefoxInstruction5: 'Kryssa i Kakor och välj "Kakor från obesökta webbplatser".',
                disclaimer: 'Att tillåta tredjepartskakor innebär att vissa webbplatser kan följa din aktivitet på andra webbplatser med hjälp av kakor. Det kan resultera i anpassade annonser och innehåll på olika sidor. För ökad integritet rekommenderas att behålla blockering av tredjepartscookies aktiverad.',
                toShowTweets: 'För att visa inlägg här måste du vara inloggad på X (tidigare Twitter), samt tillåta tredjepartskakor i din webbläsare.',
                readMore: 'Hjälp'
            },
            insightMessages: {
                date: 'Datum',
                SMS: 'Sms',
                PUSH: 'Pushnotis',
                PREFERE_PUSH: "Föredra pushnotis"
            },
            authenticate: {
              youHaveAuthenticatedYourPhone: 'Tack! Du har nu verifierat ditt telefonnummer.'
            },
            troubleshootMessage: 'Vi arbetar med feilsökning och rättning.',
            plannedOutage: 'Planerad strömavbrott för',
            plannedOutageExplained: 'Nätbolaget varnar för ett framtida strömavbrott',
            outage: 'Strömavbrott',
            noOutage: 'Ingen strömavbrott',
            extensiveOutage: 'Vid omfattande strömavbrott kan det ta lite tid från det att strömmen återkommer tills området räknas som felfritt.',
            close: 'Stäng',
            pushNotification: {
                description: 'Tillåter att appen tar emot pushnotiser i händelse av strömavbrott och andra händelser',
                pushNotification: 'Pushnotis',
                refreshToken: 'uppdatera token',
                FCMKey: 'FCM Nyckel',
                keyDate: 'Nyckel datum'
            }
        },
    },
};

