<template>
    <v-container>
        <v-row>
            <v-switch :disabled="!togglePushNotificationEnabled" :label="$t('messages.pushNotification.pushNotification')" v-model="pushActive"></v-switch>
        </v-row>
        <v-row>
            <v-dialog v-model="visible" max-width="350">
                <v-card>
                    <v-card-title class="headline">{{ $t('messages.pushNotification.pushNotification') }}</v-card-title>
                    <v-card-text>
                        <v-text-field :label="$t('messages.pushNotification.FCMKey')" readonly v-model="deviceNotificationKey"></v-text-field>
                        <v-text-field :label="$t('messages.pushNotification.keyDate')" readonly v-model="formattedDeviceNotificationKeyTimeStamp"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="red" text @click="getNewPushNotificationToken()">{{ $t('messages.pushNotification.refreshToken') }}</v-btn>
                            <v-spacer></v-spacer>
                        <v-btn color="red" text @click="visible = false">{{ $t('messages.close') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>

import moment from "moment-timezone";
import Common from "../mixins/Common"
import HttpErrorHandler from "../mixins/HttpErrorHandler"
import { getToken, deleteToken } from "firebase/messaging";
import {EventBus} from "@/eventbus";
export default {
    name: 'PushNotification',
    mixins: [Common, HttpErrorHandler],
    components: {
    },
    data() {
        return {
            pushActive: false,
            pushIcon: true,
            deviceNotificationKey: null,
            deviceNotificationKeyTimeStamp: null,
            visible: false,
            togglePushNotificationEnabled: true,
        }
    },
    methods: {
        async clearToken() {

            //Somtimes one need to refresh before clear of token
            let token = await this.getNewPushNotificationToken();

            console.log("clearToken");
            await deleteToken(this.$messaging).then((result) => {
                console.log("clearToken: Token removed", result);
            }).catch((err) => {
                console.log('An error occurred while removing token. ', err);
                this.setErrorMessage("clearToken" + err);
            });

            await this.uploadPushNotificationToken(null); // clear token in insight database

            this.deviceNotificationKey = null;
            this.deviceNotificationKeyTimeStamp = null;

            localStorage.removeItem("pushNotificationToken");
            localStorage.removeItem("pushNotificationTokenTimestamp");

            console.log("Token removed");
        },
        async getVapId() {
            let retVal = null;

            let url = `${this.apiUrl}customer/netowner/${this.netownerId}/pushnotification/vapid`;

            await this.$http.get(url).then(response => {
                retVal = response.body;
            }, response => {
                this.setErrorResponse(response);
                console.log(response);
            });

            return retVal;
        },
        async getNewPushNotificationToken() {

            let token = await this.getPushNotificationToken();
            if (token) {
                await this.uploadPushNotificationToken(token);
                let timestamp = moment.utc();
                localStorage.setItem("pushNotificationToken", token);
                localStorage.setItem("pushNotificationTokenTimestamp", timestamp.format("YYYY-MM-DD HH:mm:ss"));

                this.deviceNotificationKey = token;
                this.deviceNotificationKeyTimeStamp = timestamp.format("YYYY-MM-DD HH:mm:ss");
            } else {
                this.deviceNotificationKey = null;
                this.deviceNotificationKeyTimeStamp = null;
                localStorage.removeItem("pushNotificationToken");
                localStorage.removeItem("pushNotificationTokenTimestamp");
            }

            return token;
        },
        async getPushNotificationToken() {

            let retVal = null;

            let vapid = await this.getVapId();

            if (!vapid) {
                return;
            }

            await getToken(this.$messaging,{vapidKey: vapid, serviceWorkerRegistration: this.$swRegistration}).then((currentToken) => {
                if (currentToken) {
                    console.log("getToken succeeded");
                    retVal = currentToken;
                } else {
                    console.error('No registration token available. Request permission to generate one.');
                }
            }).catch((err) => {
                console.error('An error occurred while retrieving token. ', err);
                this.setErrorMessage(err);
            });

            return retVal;
        },
        async uploadPushNotificationToken(token) {

            let url = `${this.apiUrl}customer/netowner/${this.netownerId}/pushnotification/pushnotificationtoken/?${token ? "pushNotificationToken=" + token : ""}`;

            await this.$http.put(url).then(response => {
                console.log("uploadPushNotificationToken uploaded");
            }, response => {
                this.setErrorResponse(response);
                console.error(response);
            });
        }
    },

    computed: {
        formattedDeviceNotificationKeyTimeStamp() {

            if(this.deviceNotificationKeyTimeStamp) {
               console.log("timesamp :" + this.deviceNotificationKeyTimeStamp);
               return this.deviceNotificationKeyTimeStamp;
            }
           return null;
        }
    },

    watch: {
        async pushActive(newVal, oldVal) {
            try {
                this.togglePushNotificationEnabled = false;
                if (!newVal && this.idToken) {
                   await this.clearToken();
                    return;
                }

                if (newVal && this.idToken) {
                    await this.getNewPushNotificationToken();
                    return;
                }
            } finally {
                this.togglePushNotificationEnabled = true;
            }
        },
    },

    created() {
        this.deviceNotificationKey = localStorage.getItem("pushNotificationToken");
        this.deviceNotificationKeyTimeStamp = localStorage.getItem("pushNotificationTokenTimestamp");

        if (this.deviceNotificationKey) {
            this.pushActive = true;
        } else {
            this.pushActive = false;
        }

        EventBus.$on('activate-push-notification', () => {
            this.pushActive = true;
        });
    }
}
</script>