<template>

    <v-card>
      <v-card-title class="mb-2">{{ $t('messages.social.dialogTitle') }}</v-card-title>

      <v-card-subtitle>
        {{ $t('messages.social.dialogContent') }}
      </v-card-subtitle>

      <v-card-text class="text-h6 mb-0 pb-0">
        {{ $t('messages.social.dialogInstruction1') }}
      </v-card-text>

      <v-card-text>
        {{ $t('messages.social.dialogInstructionLoginX') }}
      </v-card-text>

      <v-card-text class="text-h6 mb-0 pb-0">
        {{ $t('messages.social.dialogInstruction2') }}
      </v-card-text>

      <v-card-text>
        {{ $t('messages.social.dialogInstruction2Clarify') }}
      </v-card-text>

      <v-card-text class="text-subtitle-1 font-weight-bold mb-0 pb-0">
        iPhone:
      </v-card-text>
      <v-card-text class="text-subtitle-2 font-weight-bold mb-0 pb-0">
        Safari
      </v-card-text>

      <v-card-text>
        <ol>
          <li>{{ $t('messages.social.safariInstruction1') }}</li>
          <li>{{ $t('messages.social.safariInstruction2') }}</li>
          <li>{{ $t('messages.social.safariInstruction3') }}</li>
        </ol>
      </v-card-text>

      <v-card-text class="text-subtitle-1 font-weight-bold mb-0 pb-0">
        Android / iPhone:
      </v-card-text>

      <v-card-text class="text-subtitle-2 font-weight-bold mb-0 pb-0">
        Chrome
      </v-card-text>

      <v-card-text>
        <ol>
          <li>{{ $t('messages.social.chromeInstruction1') }}</li>
          <li>{{ $t('messages.social.chromeInstruction2') }}</li>
          <li>{{ $t('messages.social.chromeInstruction3') }}</li>
          <li>{{ $t('messages.social.chromeInstruction4') }}</li>
          <li>{{ $t('messages.social.chromeInstruction5') }}</li>
        </ol>
      </v-card-text>

      <v-card-text class="text-subtitle-2 font-weight-bold mb-0 pb-0">
        Firefox
      </v-card-text>

      <v-card-text>
        <ol>
          <li>{{ $t('messages.social.firefoxInstruction1') }}</li>
          <li>{{ $t('messages.social.firefoxInstruction2') }}</li>
          <li>{{ $t('messages.social.firefoxInstruction3') }}</li>
          <li>{{ $t('messages.social.firefoxInstruction4') }}</li>
          <li>{{ $t('messages.social.firefoxInstruction5') }}</li>
        </ol>
      </v-card-text>

      <v-card-text>
        {{ $t('messages.social.disclaimer') }}
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" @click="$emit('closeDialog')">{{ $t('messages.close') }}</v-btn>
      </v-card-actions>
    </v-card>


</template>

<script>

export default {
  name: 'TwitterHelpDialog'
}

</script>
