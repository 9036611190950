<script>
import Common from "./Common"

export default {

    mixins: [Common],

    computed: {
        beforeInstallPrompt() {
            return this.$store.state.beforeInstallPrompt;
        },

        showInstallBanner() {
            return this.beforeInstallPrompt != null;
        },

        showInstallBannerIos() {
            let isInstalled = navigator.standalone;
            return !this.showInstallBanner && this.isIos && !isInstalled;
        },

        // Dirty hack from https://thewebdev.info/2022/02/11/how-to-detect-ipad-pro-as-ipad-with-javascript/
        isIos() {
            if (/iPad|iPhone|iPod/.test(navigator.platform)) {
                return true;
            }
            return navigator.maxTouchPoints != null &&
                   navigator.maxTouchPoints > 2 &&
                   /MacIntel/.test(navigator.platform);
        },
    },

    methods: {
        install() {

            // Show the prompt
            this.beforeInstallPrompt.prompt();

            // Wait for the user to respond to the prompt
            this.beforeInstallPrompt.userChoice
                .then((choiceResult) => {
                    if (choiceResult.outcome === 'accepted') {
                        console.log('User accepted the A2HS prompt');
                    } else {
                        console.log('User dismissed the A2HS prompt');
                    }
                    this.$store.commit("setBeforeInstallPrompt", null);
                });
        }
    }
}

</script>
