<script>
    export default {

        methods: {

            setErrorResponse(errorResponse, errorMessage) {
                this.$store.commit("setError", true);
                this.$store.commit("setErrorMessage", errorMessage ? errorMessage : this.formatErrorMessage(errorResponse));
            },

            setErrorMessage(errorMessage) {
                this.$store.commit("setError", true);
                this.$store.commit("setErrorMessage", errorMessage);
            },

            clearError() {
                this.$store.commit("setError", false);
                this.$store.commit("setErrorMessage", null);
            },

            formatErrorMessage(response) {
                console.log(JSON.stringify(response));

                if (response.status === 401) {
                    return ["Brukeren er ikke autentisert"];
                }

                let result = [];

                if (response.status !== 0) {
                    result.push("Feilkode" + ": " + response.status);
                }
                if (response.body && response.body.message) {
                    result.push("Feilmelding" + ": " + response.body.message);
                }

                let breadcrumbId = response.headers.get("breadcrumbId");
                if (breadcrumbId) {
                    result.push("Referanse" + ": " + breadcrumbId);
                }

                if (result.length === 0) {
                    result.push("Vennligst prøv igjen.")
                }

                return result;
            }
        },

        computed: {
            error() {
                return this.$store.state.error;
            },

            errorMessage() {
                return this.$store.state.errorMessage;
            }
        }
    }
</script>
