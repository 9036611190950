<template>

    <v-dialog :fullscreen="$vuetify.breakpoint.mobile" max-width="500" v-model="visible">
        <v-card tile>

            <v-stepper v-model="step" class="elevation-0">
                <v-stepper-header class="elevation-0">
                    <v-stepper-step :complete="step > 1" step="1"/>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="step > 2" step="2"/>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="step > 3" step="3"/>
                    <v-divider></v-divider>
                    <v-stepper-step step="4"/>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <div class="px-2 pb-2">
                            <p class="title">
                                Meld fra om farlige forhold
                            </p>
                            <p>Hjelp oss å holde strømnettet trygt!</p>
                            <p>Her kan du melde fra om du ser noe som er eller kan bli en fare.</p>

                            <v-row wrap justify="center">
                                <v-card v-for="observationType in observationTypes"
                                        :key="observationType.id"
                                        @click="selectedObservationType = observationType"
                                        width="85"
                                        height="85"
                                        :color="observationType === selectedObservationType ? 'red' : 'grey lighten-3'"
                                        class="pa-2 ma-2">
                                    <v-icon size="30"
                                            :color="observationType === selectedObservationType ? 'white' : 'primary'"
                                            class="mx-auto">
                                        {{observationType.icon}}
                                    </v-icon>
                                    <div class="caption pt-2"
                                         style="line-height: 1.3"
                                         :class="{'white--text': observationType === selectedObservationType}">
                                        {{observationType.title}}
                                    </div>
                                </v-card>
                            </v-row>

                            <v-card outlined height="80" class="mt-5 mx-3" color="green lighten-5">
                                <v-card-text>
                                <span v-if="selectedObservationType" class="">
                                    {{selectedObservationType.description}}
                                </span>
                                <span v-else>
                                    Velg en kategori.
                                </span>
                                </v-card-text>
                            </v-card>

                            <v-layout class="mt-4">
                                <v-spacer/>
                                <v-btn text color="primary" class="mr-2" @click="visible = false">Avbryt</v-btn>
                                <v-btn color="primary" class="ml-2" @click="step = 2" :disabled="!selectedObservationType">Fortsett</v-btn>
                                <v-spacer/>
                            </v-layout>
                        </div>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <div class="pa-2">
                            <v-textarea v-model="message"
                                        outlined
                                        dense
                                        hide-details
                                        height="120"
                                        label="Melding"/>

                            <v-file-input v-model="images"
                                          outlined
                                          dense
                                          show-size
                                          counter
                                          prepend-icon="mdi-camera"
                                          accept="image/*"
                                          label="Legg ved bilde"
                                          :multiple="true"
                                          class="mt-6"/>

                            <v-row wrap justify="center">

                                <v-img v-for="image in wrappedImages"
                                       :key="image.name"
                                       :src="image.url"
                                       width="100"
                                       height="100"
                                       max-width="100"
                                       max-height="100"
                                       class="ma-1"
                                       style="border:1px solid black"/>
                            </v-row>

                            <v-layout class="mt-12">
                                <v-spacer/>
                                <v-btn text color="primary" class="mr-2" @click="visible = false">Avbryt</v-btn>
                                <v-btn color="primary" class="ml-2" @click="step = 3">Fortsett</v-btn>
                                <v-spacer/>
                            </v-layout>
                        </div>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <div class="px-0 pb-2">

                            <v-alert type="info"
                                     border="left"
                                     width="100%">
                                Angi posisjonen i kartet.
                            </v-alert>

                            <div style="position: relative">
                                <v-btn small
                                       dark
                                       fab
                                       color="rgba(0,0,0,0.62)"
                                       :loading="locationStatus === 'IN_PROGRESS'"
                                       @click="locate"
                                       class="locate">
                                    <v-icon>mdi-crosshairs-gps</v-icon>
                                </v-btn>

                                <l-map ref="map"
                                       :bounds.sync="bounds"
                                       style="width: 100%; height: min(500px, calc(100vh - 300px)); background-color: #000; z-index:0"
                                       :minZoom="4"
                                       :maxZoom="18"
                                       :zoom.sync="zoom"
                                       @click="selectLocation"
                                       :options="{zoomControl: false, attributionControl: $vuetify.breakpoint.mdAndUp}">

                                    <l-tile-layer :url="tileLayer.url"
                                                  :opacity="tileLayer.opacity"
                                                  :attribution="tileLayer.attribution"/>

                                    <l-control-zoom  v-if="$vuetify.breakpoint.mdAndUp" position="bottomleft"/>
                                    <l-control-scale v-if="$vuetify.breakpoint.mdAndUp" position="bottomright" :imperial="false"/>

                                    <l-geo-json v-if="gridArea" :geojson="gridArea" :options="gridAreaOptions"/>

                                    <l-marker v-if="location"
                                              :lat-lng="location"
                                              :zIndexOffset="11000"
                                              :icon="locationIcon"/>
                                </l-map>
                            </div>

                            <v-layout class="mt-4">
                                <v-spacer/>
                                <v-btn text color="primary" @click="visible = false" class="px-2">Avbryt</v-btn>
                                <v-btn text color="primary" @click="skipLocation" class="px-2 mr-2">Hopp over</v-btn>
                                <v-btn color="primary" @click="step = 4" :disabled="!location">Fortsett</v-btn>
                                <v-spacer/>
                            </v-layout>
                        </div>
                    </v-stepper-content>

                    <v-stepper-content step="4">
                        <div class="px-2 pb-2">
                            <v-switch hide-details dense v-model="allowContact" :label="'Tillat ' + netownerName + ' å kontakte meg'"/>

                            <v-layout class="mt-12">
                                <v-spacer/>
                                <v-btn text color="primary" class="mr-2" @click="visible = false">Avbryt</v-btn>
                                <v-btn color="primary" class="ml-2" @click="step = 5">Send inn</v-btn>
                                <v-spacer/>
                            </v-layout>
                        </div>
                    </v-stepper-content>

                    <v-stepper-content step="5">
                        <div class="px-2 pb-2">

                            <v-list>
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-progress-circular v-if="observationStatus === 'IN_PROGRESS'" color="primary" indeterminate style="margin-left:3px" size="28"/>
                                        <v-icon v-if="observationStatus === 'NOT_STARTED'" size="33" color="primary">mdi-clock-outline</v-icon>
                                        <v-icon v-if="observationStatus === 'OK'" size="33" color="green">mdi-check</v-icon>
                                        <v-icon v-if="observationStatus === 'ERROR'" size="33" color="red">mdi-close</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Laster opp rapport
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item v-if="images.length > 0">
                                    <v-list-item-icon>
                                        <v-progress-circular v-if="imageStatus === 'IN_PROGRESS'" color="primary" indeterminate style="margin-left:3px" size="28"/>
                                        <v-icon v-if="imageStatus === 'NOT_STARTED'" size="33" color="primary">mdi-clock-outline</v-icon>
                                        <v-icon v-if="imageStatus === 'OK'" size="33" color="green">mdi-check</v-icon>
                                        <v-icon v-if="imageStatus === 'ERROR'" size="33" color="red">mdi-close</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>
                                        Laster opp bilder
                                    </v-list-item-title>
                                </v-list-item>

                            </v-list>

                            <v-alert v-if="observationStatus === 'OK' && (imageStatus === 'OK' || imageStatus === 'ERROR')"
                                     class="mt-8"
                                     type="info"
                                     border="left"
                                     prominent
                                     width="100%">
                                Rapporten din er sendt.
                                Takk for at du bidrar til å holde strømnettet trygt!
                            </v-alert>

                            <v-alert v-if="observationStatus === 'ERROR'"
                                     class="mt-8"
                                     type="error"
                                     border="left"
                                     prominent
                                     width="100%">
                                Noe gikk galt.  Vennligst prøv igjen senere.
                            </v-alert>

                            <v-layout class="mt-12">
                                <v-spacer/>
                                <v-btn text color="primary" class="mr-2" @click="visible = false">Avbryt</v-btn>
                                <v-btn color="primary" class="ml-2" @click="visible = false" :disabled="observationStatus === 'NOT_STARTED' || observationStatus === 'IN_PROGRESS'">Lukk</v-btn>
                                <v-spacer/>
                            </v-layout>
                        </div>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>

        </v-card>
    </v-dialog>
</template>

<script>

    import Common from "../mixins/Common"
    import HttpErrorHandler from "../mixins/HttpErrorHandler"
    import L from 'leaflet';
    import {LMap, LTileLayer, LControlScale, LControlZoom, LGeoJson, LMarker} from 'vue2-leaflet';

    export default {
        name: 'CreateObservation',
        mixins: [Common, HttpErrorHandler],
        props: ['value'],
        components: {LMap, LTileLayer, LControlScale, LControlZoom, LGeoJson, LMarker},

        data() {
            return {
                step: 1,
                images: [],
                wrappedImages: [],
                message: null,
                location: null,
                userLocation: null,
                allowContact: true,
                locationStatus: 'NOT_STARTED',
                imageStatus: 'NOT_STARTED',
                observationStatus: 'NOT_STARTED',

                observationTypes: [
                    {
                        id: 'SPARK',
                        category: 'ACTUAL_RISK',
                        title: 'Lysglimt',
                        icon: 'mdi-flare',
                        description: 'Lysglimt, gnister eller røyk fra elektrisk utstyr.'
                    },
                    {
                        id: 'TREE_OVER_LINE',
                        category: 'ACTUAL_RISK',
                        title: 'Trefall på linje',
                        icon: 'mdi-tree',
                        description: 'Tre som har falt over strømførende ledning.'
                    },
                    {
                        id: 'TREE_NEAR_LINE',
                        category: 'POTENTIAL_RISK',
                        title: 'Tre nær linje',
                        icon: 'mdi-tree',
                        description: 'Tre som står i fare for å falle over strømførende ledning.'
                    },
                    {
                        id: 'DISTRIBUTION_CABINET',
                        category: 'ACTUAL_RISK',
                        title: 'Skade på kabelskap',
                        icon: 'mdi-hammer-wrench',
                        description: 'Skade på kabelskap, koblingsbokser og lignende.'
                    },
                    {
                        id: 'DOOR',
                        category: 'POTENTIAL_RISK',
                        title: 'Åpen dør',
                        icon: 'mdi-door',
                        description: 'Åpen dør i nettstasjon.'
                    },
                    {
                        id: 'OTHER',
                        category: 'ACTUAL_RISK',
                        title: 'Annen fare',
                        icon: 'mdi-alert',
                        description: 'Andre forhold som er eller kan utvikle seg til å bli en fare.'
                    },
                ],
                selectedObservationType: null,
                netownerBounds: null,
                bounds: L.latLngBounds(L.latLng(61.93, 4.958), L.latLng(67.917, 24.398)),
                zoom: 8,
                // locationIcon: L.icon({iconUrl: 'pegman.png', iconSize: [19, 40]}),
                locationIcon: L.icon({iconUrl: 'risk.svg', iconSize: [26, 26]}),
                gridArea: null,
                gridAreaOptions: {
                    color: "rgb(255, 255, 255)",
                    weight: 0,
                    fillOpacity: 0.1,
                    attribution: '<a href="https://nve.no/" target="_blank">NVE</a>'
                },
                tileLayer: {
                    url: 'https://d3awtdd4drqiy5.cloudfront.net/ArcGIS/rest/services/Geocache_WMAS_WGS84/GeocacheBasis/MapServer/tile/{z}/{y}/{x}',
                    opacity: 0.55,
                    attribution: '&copy; Kartverket, Geovekst og Kommuner - Geodata AS',
                }
            }
        },

        methods: {

            async createObservation() {
                this.observationStatus = 'IN_PROGRESS';

                try {
                    let url = `${this.apiUrl}customer/netowner/${this.netownerId}/observation`;
                    let observation = {
                        category: this.selectedObservationType.category,
                        type: this.selectedObservationType.id,
                        message: this.message,
                        latitude: this.location?.lat,
                        longitude: this.location?.lng,
                        allowContact: this.allowContact,
                        meterPointId: this.meterPoint ? this.meterPoint.meterPointId : null,
                    };

                    let response = await this.$http.post(url, observation);
                    this.observationStatus = 'OK';

                    await this.uploadImages(response.body.id);

                } catch (error) {
                    console.log(error);
                    this.observationStatus = 'ERROR';
                }
            },

            locate() {
                if (!this.userLocation) {
                    this.locationStatus = 'IN_PROGRESS';
                    this.$getLocation({enableHighAccuracy: true, timeout: 30000})
                        .then(this.onLocationFound)
                        .catch(() => this.locationStatus = 'ERROR');
                } else {
                    this.location = this.userLocation;
                    this.flyTo(this.userLocation);
                }
            },

            onLocationFound(location) {
                let shouldFly = !this.userLocation;
                this.location = this.userLocation = location;
                this.locationStatus = 'OK';

                if (shouldFly) {
                    this.flyTo(this.userLocation);
                }
            },

            flyTo(location) {
                if (location) {
                    this.map().flyTo(location, 16, {animate: true});
                }
            },

            selectLocation(e) {
                this.location = e.latlng;
            },

            skipLocation() {
                this.location = null;
                this.step = 4;
            },

            async uploadImages(observationId) {
                this.imageStatus = 'IN_PROGRESS';

                try {
                    for (let i = 0; i < this.wrappedImages.length; i++) {
                        let image = this.wrappedImages[i];

                        // Create attachment
                        let attachment = {
                            contentType: image.file.type,
                            name: image.file.name,
                            size: image.file.size

                        };
                        let response = await this.$http.post(`${this.apiUrl}customer/netowner/${this.netownerId}/observation/attachment`, attachment);
                        let attachmentId = response.body.id;
                        let uploadUrl = response.body.uploadUrl;

                        // Upload to S3.
                        let config = {
                            headers: {
                                'Content-Type': image.file.type
                            },
                        };
                        await this.$http.put(uploadUrl, image.file, config);

                        // Connect attachment to observation
                        await this.$http.put(`${this.apiUrl}customer/netowner/${this.netownerId}/observation/${observationId}/attachment/${attachmentId}`);
                    }

                    this.imageStatus = 'OK';

                } catch (error) {
                    this.imageStatus = 'ERROR';
                }
            },

            reset() {
                this.step = 1;
                this.selectedObservationType = null;
                this.message = null;
                this.images = [];
                this.location = null;
                this.allowContact = true;
                this.locationStatus = 'NOT_STARTED';
                this.imageStatus = 'NOT_STARTED';
                this.observationStatus = 'NOT_STARTED';
            },

            wrapFile(file) {
                return {
                    file: file,
                    url: URL.createObjectURL(file),
                    progress: -1
                };
            },

            map() {
                return this.$refs.map?.mapObject;
            },

            loadNetowner() {
                if (!this.netownerId || !this.idToken) {
                    return;
                }
                let url = this.properties.ApiUrl + "customer/netowner/" + this.netownerId + "/netowner";

                this.$http.get(url).then(response => {
                                             if (response.body.netownerBounds) {
                                                 this.netownerBounds = L.latLngBounds(L.latLng(response.body.netownerBounds.south, response.body.netownerBounds.west),
                                                                                      L.latLng(response.body.netownerBounds.north, response.body.netownerBounds.east));
                                                 if (response.body.netownerPolygonUrl) {
                                                     this.$http.get(response.body.netownerPolygonUrl).then(response => {
                                                         this.gridArea = response.body;
                                                     });
                                                 }
                                             }
                                         },
                                         response => {
                                             this.setErrorResponse(response);
                                         });
            },
        },

        computed: {
            visible: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            },
        },

        watch: {

            netownerId() {
                this.loadNetowner();
            },

            idToken(current, previous) {
                if (!previous && current) {
                    this.loadNetowner();
                }
            },

            visible() {
                this.reset();
            },

            step() {
                if (this.step === 3) {
                    this.$nextTick(() => {
                        this.map().invalidateSize(false);
                        setTimeout(() => this.map().flyToBounds(this.netownerBounds, {animate: true}), 1000);
                    })
                }

                if (this.step === 5) {
                    if (this.images.length === 0) {
                        this.imageStatus = 'OK';
                    }
                    this.createObservation();
                }
            },

            images() {
                this.wrappedImages = this.images.map(this.wrapFile);
            }
        },

        created() {
            this.loadNetowner();
        },
    }

</script>

<style lang="scss" scoped>

@mixin box-on-map {
    background-color: rgba(#000, .62);
    color: white;
    position: absolute;
    z-index: 1;
    display: block;
}

.locate {
    @include box-on-map;
    right: 15px;
    top: 15px;
}
</style>
