<template>
    <div>
        <div class="body-1 text-center pt-4 black--text">
            Kapasitetstrinn
        </div>
        <div class="caption text-center pt-1">
            Her ser du hvordan forbruket ditt plasserer seg på kapasitetstrinnene i nettleien.
        </div>

        <highcharts class="py-2" ref="chart" constructor-type="stockChart" :options="chartOptions"
                    style="height:250px; width:100%"/>

    </div>
</template>

<script>

    import Common from '../mixins/Common'
    import moment from 'moment-timezone';
    import _ from 'lodash';

    export default {
        name: 'FixedPriceLevelByMonth',
        mixins: [Common],

        data() {
            return {
                chartOptions: {
                    chart: {
                        backgroundColor: "transparent",
                        panning: false,
                        spacingBottom: 0,
                        spacingLeft:0,
                        style: {
                            fontFamily: "Roboto"
                        }
                    },
                    navigator: {
                        enabled: false
                    },
                    rangeSelector: {
                        enabled: false
                    },
                    scrollbar: {
                        enabled: false
                    },
                    tooltip: {
                        split: false,
                        shared: true,
                    },
                    xAxis: {
                        min: 0,
                        max: 11,
                        type: 'category',
                        categories: ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des']
                    },
                    yAxis:
                        {
                            min: 0,
                            title: {
                                text: 'Kapasitet (kW)',
                            },
                            labels: {
                                y: 5,
                            },
                            endOnTick: true,
                            showLastLabel: true,
                            opposite: false,
                            gridLineWidth: 0,
                        },
                    title: {
                        text: null
                    },
                    legend: {
                        enabled: true,
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom',
                    },
                    plotOptions: {
                        line: {
                            dataGrouping: {
                                enabled: false,
                            },
                            marker: {
                                enabled: true,
                                radius: 4,
                                symbol: 'circle'
                            }
                        },
                    },
                    exporting: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                    loading: {
                        labelStyle: {
                            fontWeight: "normal"
                        }
                    },
                    series: [],
                }
            }
        },

        methods: {
            updateChart() {
                if (!this.meterValues?.cost) {
                    return;
                }

                let useMonthlyMax = this.fixedPriceBasis === 'MONTHLY_MAX';
                let useMonthlyMax3 = this.fixedPriceBasis === 'MONTHLY_MAX_3';

                if (!useMonthlyMax && !useMonthlyMax3) {
                    return;
                }

                let maxPriceBasis = this.meterValues.cost.months.filter(each => each.month.startsWith(this.thisYear))
                    .map(each => ({
                        x: moment(each.month).month(),
                        y: useMonthlyMax ? each.monthlyMax : each.monthlyMax3,
                    }));

                let maxPriceBasisLastYear = this.meterValues.cost.months.filter(each => each.month.startsWith(this.lastYear))
                    .map(each => ({
                        x: moment(each.month).month(),
                        y: useMonthlyMax ? each.monthlyMax : each.monthlyMax3,
                    }));

                this.chartOptions.series = [
                    {
                        id: '0',
                        data: maxPriceBasis,
                        name: this.thisYear,
                        type: 'line',
                        color: '#2196F3',
                        zIndex: 2,
                        tooltip: {
                            valueSuffix: ' kW',
                            valueDecimals: 2
                        },
                    },
                    {
                        id: '1',
                        data: maxPriceBasisLastYear,
                        name: this.lastYear,
                        type: 'line',
                        color: '#b8b8b8',
                        zIndex: 1,
                        tooltip: {
                            valueSuffix: ' kW',
                            valueDecimals: 2
                        },
                    }
                ];

                let maxOfMaxes = Math.max(_.max(maxPriceBasis.map(each => each.y)),
                                          _.max(maxPriceBasisLastYear.map(each => each.y)));

                let levels = this.meterPoint?.tariff?.fixedPrice?.levels || [];

                let relevantLevels = levels.filter(level => !level.min || level.min <= maxOfMaxes);
                if (relevantLevels.length < levels.length) {
                    // Add next level too
                    relevantLevels.push(levels[relevantLevels.length]);
                }

                let tickPositions = [0].concat(relevantLevels.filter(each => each.max).map(each => each.max));

                if (relevantLevels.length > 0 && relevantLevels[relevantLevels.length - 1].max == null) {
                    tickPositions.push(Math.ceil(maxOfMaxes));
                }

                let colors = ['#E1F5FE', '#B3E5FC', '#81D4FA', '#4FC3F7', '#29B6F6', '#03A9F4', '#039BE5', '#0288D1', '#0277BD', '#01579B'];

                this.chartOptions.yAxis.tickPositions = tickPositions;
                this.chartOptions.yAxis.plotBands =
                    relevantLevels.map(
                        (level, index) =>
                            ({
                                from: level.min || 0,
                                to: level.max || maxOfMaxes,
                                zIndex: 0,
                                color: colors[index % colors.length] + '80',
                                label: {
                                    align: 'right',
                                    text: Math.round(level.price) + ' kr/mnd',
                                    y: 4,
                                    x: -5,
                                    style: {
                                        fontSize: '10px',
                                    }
                                }
                            }));
            }
        },

        watch: {
            meterValues() {
                this.updateChart();
            },
        },

        mounted() {
            this.updateChart();
        }
    }
</script>
