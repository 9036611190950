<template>

    <v-card color="grey lighten-4"
            class="mx-auto mb-3">
        <v-layout>
            <div class="body-1 mx-auto pt-4">
                Høyeste timeproduksjon / måned
            </div>
        </v-layout>

        <highcharts class="py-2" ref="chart" constructor-type="stockChart" :options="chartOptions"
                    style="height:230px; width:100%"/>
    </v-card>
</template>

<script>

    import Common from '../../mixins/Common'
    import moment from 'moment-timezone';
    import _ from 'lodash';

    export default {
        name: 'ProductionByMonth',
        mixins: [Common],

        data() {
            return {
                chartOptions: {
                    chart: {
                        backgroundColor: "transparent",
                        panning: false,
                        spacingBottom: 0,
                        style: {
                            fontFamily: "Roboto"
                        }
                    },
                    navigator: {
                        enabled: false
                    },
                    rangeSelector: {
                        enabled: false
                    },
                    scrollbar: {
                        enabled: false
                    },
                    tooltip: {
                        split: false,
                        shared: true,
                    },
                    xAxis: {
                        min: 0,
                        max: 11,
                        type: 'category',
                        categories: ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des']
                    },
                    yAxis: [
                        {
                            min: 0,
                            title: {
                                align: 'high',
                                offset: 5,
                                text: 'kWt',
                                rotation: 0,
                                y: 15
                            },
                            labels: {
                                // x: -5
                            },
                            opposite: false,
                        }
                    ],
                    title: {
                        text: null
                    },
                    legend: {
                        enabled: true,
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom',
                    },
                    plotOptions: {
                        line: {
                            dataGrouping: {
                                enabled: false,
                            },
                            marker: {
                                enabled: true,
                                radius: 4,
                                symbol: 'circle'
                            }
                        },
                    },
                    exporting: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                    loading: {
                        labelStyle: {
                            fontWeight: "normal"
                        }
                    },
                    series: [],
                }
            }
        },

        methods: {
            updateChart() {
                if (!this.meterValues || !this.meterValues.production) {
                    return;
                }

                let maxProduction = this.meterValues.production.months.filter(each => each.month.startsWith(this.thisYear))
                    .map(each => ({
                        x: moment(each.month).month(),
                        y: each.max
                    }));

                let maxProductionLastYear = this.meterValues.production.months.filter(each => each.month.startsWith(this.lastYear))
                    .map(each => ({
                        x: moment(each.month).month(),
                        y: each.max
                    }));

                this.chartOptions.series = [
                    {
                        id: '0',
                        data: maxProduction,
                        name: this.thisYear,
                        type: 'line',
                        color: '#66BB6A',
                        zIndex: 1,
                        tooltip: {
                            valueSuffix: ' kWt',
                            valueDecimals: 2
                        },
                    },
                    {
                        id: '1',
                        data: maxProductionLastYear,
                        name: this.lastYear,
                        type: 'line',
                        color: '#b8b8b8',
                        zIndex: 0,
                        tooltip: {
                            valueSuffix: ' kWt',
                            valueDecimals: 2
                        },
                    }
                ];
            }
        },

        computed: {
        },

        watch: {
            meterValues() {
                this.updateChart();
            },
        },

        mounted() {
            this.updateChart();
        }
    }
</script>
