var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mobile,"max-width":"420"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_c('v-icon',{attrs:{"left":"","large":"","color":"error"}},[_vm._v("mdi-alert-circle-outline")]),_vm._v(" Jordfeil "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.visible = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('p',[_vm._v(" Strømmåleren din har registrert en mulig jordfeil i anlegget ditt. ")]),_c('p',[_vm._v(" Jordfeil kan føre til livsfarlige støt eller brann. ")]),(_vm.intervals.length > 0)?_c('p',[_vm._v(" Tabellen viser tidsrom der det er registrert jordfeil. Det kan hjelpe deg å finne ut hvilket elektrisk utstyr som har feil. ")]):_vm._e(),_c('p',[_vm._v(" Ta gjerne kontakt med oss hvis du har spørsmål. ")]),(_vm.intervals.length > 0)?_c('v-data-table',{attrs:{"items":_vm.intervals,"headers":[{text: 'Fra', value: 'from'}, {text: 'Til', value: 'to'}],"height":40 + Math.min(_vm.intervals.length * 30, 200),"fixed-header":"","disable-sort":"","hide-default-footer":"","disable-pagination":"","mobile-breakpoint":0,"dense":""},scopedSlots:_vm._u([{key:"item.from",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.formatDateTime(item.from)))])]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.formatDateTime(item.to)))])]}}],null,false,3423327337)}):_vm._e()],1),_c('v-card-actions',[(_vm.earthfaultUrl)?_c('v-btn',{staticClass:"mx-1",attrs:{"text":"","color":"primary","href":_vm.earthfaultUrl,"target":"_blank"}},[_vm._v(" Les mer ")]):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Ta kontakt "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('contact',{attrs:{"phone":_vm.earthfaultPhone,"email":_vm.earthfaultEmail,"email-subject":'Jordfeil, målepunkt ' + _vm.meterPointId}})],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.visible = false}}},[_vm._v(" Lukk ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }