<template>

    <v-layout align-center justify-space-around column>

        <div class="pa-3 title">Kom i gang</div>

        <v-stepper v-model="step" vertical flat>
            <!--            TODO: Terms of use-->

            <v-stepper-step :complete="step > 1" step="1">
                Angi telefonnummer
                <small v-if="step > 1">{{phoneNumber}}</small>
            </v-stepper-step>

            <v-stepper-content step="1">

                <div class="mb-2">Skriv inn telefonnummeret ditt og få tilsendt bekreftelseskode på SMS.</div>
                <v-text-field outlined type="tel" label="Telefonnummer" v-model="phoneNumber" :disabled="isLoading" @keydown.enter="sendCode" style="max-width: 250px"/>
                <v-alert text :value="sendCodeError != null" type="error"><div v-html="sendCodeError"></div></v-alert>
                <v-btn color="primary" @click="sendCode" :disabled="isLoading || !phoneNumber">Send kode</v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="step > 2" step="2">Angi bekreftelseskode</v-stepper-step>

            <v-stepper-content step="2">
                <div class="mb-2">Skriv inn bekreftelseskoden du fikk på SMS.</div>

                <v-otp-input v-model="code" type="number" length="6" style="width:280px" @finish="validateCode"/>
                <v-alert text :value="validateCodeError != null" type="error">{{validateCodeError}}</v-alert>
                <v-btn color="error" @click="restart" :disabled="isLoading">Avbryt</v-btn>
            </v-stepper-content>

            <v-stepper-step step="3">Ferdig</v-stepper-step>

            <v-stepper-content step="3">
                <div class="mb-2">{{ $t('messages.authenticate.youHaveAuthenticatedYourPhone') }}</div>
                <v-checkbox class="mb-2" v-model="allowPushNotification" label="Tillat push varsel"></v-checkbox>
                <v-btn color="primary" :disabled="!idToken" @click="finish">Fortsett</v-btn>
            </v-stepper-content>
        </v-stepper>

    </v-layout>

</template>

<script>

    import Common from "../mixins/Common"
    import HttpErrorHandler from "../mixins/HttpErrorHandler"
    import {EventBus} from "@/eventbus";

    export default {
        name: 'Authenticate',
        mixins: [Common, HttpErrorHandler],

        data() {
            return {
                step: 1,
                phoneNumber: null,
                code: null,
                codeId: null,
                refreshToken: null,
                sendCodeError: null,
                validateCodeError: null,
                allowPushNotification: true,
            }
        },

        methods: {
            sendCode() {
                if (!this.phoneNumber) {
                    return;
                }

                let url = this.apiUrl + "customerauth/netowner/" +
                          this.netownerId + "/auth/send";
                let params = {phoneNumber: this.phoneNumber};

                this.sendCodeError = null;

                this.$http.get(url, {params: params}).then(response => {
                    this.codeId = response.body.codeId;
                    this.step = 2;
                }, response => {
                    if (response.body.errorCode === "TOO_MANY_ATTEMPTS") {
                        this.sendCodeError = "For mange forsøk. Prøv igjen senere."
                    } else if (response.body.errorCode === "SMS_FAILED") {
                        this.sendCodeError = "Kunne ikke sende SMS akkurat nå. Prøv igjen senere."
                    } else if (response.body.errorCode === "NOT_INVITED_YET") {
                        this.sendCodeError = "Du kan ikke ta i bruk <strong>Min strøm</strong> nå. Vi sender snart en invitasjon til deg."
                    } else if (response.body.errorCode === "NO_METERPOINTS") {
                        this.sendCodeError = "<p>Dette telefonnummeret er ikke knyttet til noen anlegg hos nettselskapet.</p>" +
                                             "<ul>" +
                                             "<li>Er anlegget registrert på noen andre i husstanden?</li>" +
                                             "<li>Ta <a href='" + this.contactUrl + "' target='_blank'>kontakt</a> med oss hvis du trenger hjelp.</li><" +
                                             "/ul>";
                    } else {
                        this.setErrorResponse(response);
                    }
                });
            },

            validateCode() {
                if (!this.code) {
                    return;
                }

                let url = this.apiUrl + "customerauth/netowner/" + this.netownerId + "/auth/validate";
                let params = {phoneNumber: this.phoneNumber, codeId: this.codeId, code: this.code};

                this.validateCodeError = null;

                this.$http.get(url, {params: params}).then(response => {
                    this.refreshToken = response.body.refreshToken;
                    this.$store.commit("setCognitoRefreshToken", this.refreshToken);
                    this.step = 3;
                }, response => {
                    if (response.body.errorCode === "TOO_MANY_ATTEMPTS") {
                        this.validateCodeError = "For mange forsøk. Prøv igjen senere."
                    } else if (response.body.errorCode === "WRONG_CODE") {
                        this.validateCodeError = "Feil kode. Vennligst prøv igjen."
                    } else {
                        this.setErrorResponse(response);
                    }
                });
            },

            restart() {
                this.code = null;
                this.codeId = null;
                this.sendCodeError = null;
                this.validateCodeError = null;
                this.step = 1;
            },

            finish() {
                if(this.allowPushNotification) {
                    EventBus.$emit('activate-push-notification');
                }
                this.$router.push('/status');
            }
        },

        created() {
            if (this.prefilledPhoneNumber && this.prefilledCodeId) {
                this.$store.commit("setCognitoRefreshToken", null);
                this.phoneNumber = this.prefilledPhoneNumber;
                this.codeId = this.prefilledCodeId;
                this.step = 2;
            }
        },
    }
</script>
