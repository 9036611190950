<template>

    <v-card color="grey lighten-4"
            class="mx-auto mb-3">
        <v-layout class="pt-4 pl-4 pr-4" align-baseline>

            <div class="body-1">
                Belastning
            </div>

            <v-btn small icon elevation="2" @click="showHelp = true" class="mx-3" color="primary">
                <v-icon small>mdi-help</v-icon>
            </v-btn>

            <v-spacer/>

            <v-btn-toggle dense rounded mandatory color="primary" v-model="unit">
                <v-btn small color="transparent" value="A">A</v-btn>
                <v-btn small color="transparent" value="kW">kW</v-btn>
            </v-btn-toggle>

        </v-layout>

        <highcharts class="py-2" ref="chart" :options="chartOptions"
                    style="height:200px; width:100%"/>

        <v-bottom-sheet v-model="showHelp" >
            <v-card tile color="blue lighten-5">
                <v-card-text class="pt-12">
                    <p>
                        Grafen viser belastningen i anlegget sett i forhold til størrelsen på hovedsikringen.
                        Dette kan hjelpe deg i å vurdere om du har en passende sikringsstørrelse.
                    </p>
                    <p>
                        <strong>Gjennomsnitt</strong><br>
                        Gjennomsnittlig belastning.
                    </p>
                    <p>
                        <strong>Høyeste 1%</strong><br>
                        Belastningen var høyere enn dette 1% av tiden.
                    </p>
                    <p>
                        <strong>Høyeste</strong><br>
                        Den høyeste registrerte belastningen.
                    </p>

                    <p>
                        Velg om du vil vise belastning i Ampere (A) eller kilowatt (kW).
                    </p>

                    <p>
                        Merk at beregningene er basert på forbruksverdier som er målt èn gang i timen. Dersom forbruket har
                        variert mye innenfor timen vil den faktiske topp-belastningen være høyere enn grafen viser.
                    </p>

                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn text color="primary" @click="showHelp = false">Lukk</v-btn>
                    <v-spacer/>
                </v-card-actions>
            </v-card>
        </v-bottom-sheet>
    </v-card>
</template>

<script>

    import Common from '../../mixins/Common'
    import moment from 'moment-timezone';
    import _ from 'lodash';

    export default {
        name: 'Load',
        mixins: [Common],

        data() {
            return {
                unit: 'A',
                showHelp: false,
                chartOptions: {
                    chart: {
                        type: 'bar',
                        backgroundColor: 'transparent',
                        zoomType: 'x',
                        style: {
                            fontFamily: 'Roboto'
                        },
                    },
                    xAxis: {
                        type: 'category',
                        lineWidth:0,
                        categories: ['Gjennomsnitt', 'Høyeste 1%', 'Høyeste', 'Anleggskapasitet (hovedsikring)'],
                    },
                    yAxis: {
                        visible: false,
                    },
                    title: {
                        text: '',
                    },
                    tooltip: {
                        enabled: false,
                    },
                    legend: {
                        enabled: false,
                    },
                    plotOptions: {
                        bar: {
                            borderWidth: 0,
                            dataLabels: {
                                enabled: true,
                                style: {
                                    textOutline: 'none',
                                    fontWeight: 'normal',
                                    fontSize: '11px',
                                }
                            }
                        }
                    },
                    exporting: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                    loading: {
                        labelStyle: {
                            fontWeight: 'normal'
                        }
                    },
                    colors: ["#7cb5ec", "#7cb5ec", "#7cb5ec", "#f15c80"],
                    series: [
                        {
                            colorByPoint: true,
                            data: [0, 0, 0, 0],
                        },
                    ],
                }
            }
        },

        methods: {
            updateChart() {
                if (!this.meterValues || !this.meterValues.consumption || !this.meterPoint ||
                    !this.meterPoint.fuseSize || !this.meterPoint.voltage || !this.meterPoint.phases) {
                    this.chartOptions.series[0].data = [0, 0, 0, 0];
                    return;
                }

                let self = this;
                this.chartOptions.plotOptions.bar.dataLabels.formatter = function () {
                    return self.formatNumber(this.y, 1) + ' ' + self.unit;
                };

                if (this.unit === 'kW') {
                    let capacity = this.meterPoint.fuseSize * this.meterPoint.voltage / 1000;
                    if (this.meterPoint.phases === 3) {
                        capacity *= Math.sqrt(3);
                    }

                    this.chartOptions.series[0].data = [this.meterValues.consumption.average,
                                                        this.meterValues.consumption.p99,
                                                        this.meterValues.consumption.max,
                                                        capacity];
                } else {

                    let factor = this.meterPoint.voltage / 1000;
                    if (this.meterPoint.phases === 3) {
                        factor *= Math.sqrt(3);
                    }

                    this.chartOptions.series[0].data = [this.meterValues.consumption.average / factor,
                                                        this.meterValues.consumption.p99 / factor,
                                                        this.meterValues.consumption.max / factor,
                                                        this.meterPoint.fuseSize];
                }
            }
        },

        computed: {
        },

        watch: {
            meterValues() {
                this.updateChart();
            },

            unit() {
                this.updateChart();
            }
        },

        mounted() {
            this.updateChart();
        }
    }
</script>
