<template>

    <div class="grey lighten-3" style="height: 100%">
        <v-container style="max-width: 600px" >

            <v-row no-gutters style="padding-bottom: 50px">

                <v-col v-if="meterPointsLoaded && meterPoints.length === 0 && this.phoneNumber" cols="12">
                    <v-card color="error" class="white--text mb-4">
                        <v-card-title>
                            <v-icon large left color="white">mdi-alert-circle-outline</v-icon>
                            Fant ingen målere
                        </v-card-title>
                        <v-card-text>
                            <div class="white--text">
                                <p>
                                    Telefonnummeret <strong>{{ phoneNumber }}</strong> er ikke knyttet til noen anlegg hos {{ netownerName }}.
                                </p>
                                <p>
                                    Er anlegget registrert på noen andre i husstanden?
                                </p>
                                <p>
                                    Logg inn på nytt for å velge et annet telefon&shy;nummer.
                                </p>
                            </div>

                            <v-layout>
                                <v-spacer/>
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn text
                                               class="white--text"
                                               v-bind="attrs"
                                               v-on="on">
                                            Ta kontakt
                                        </v-btn>
                                    </template>
                                    <contact :url="contactUrl"
                                             :phone="contactPhone"
                                             :email="contactEmail"/>
                                </v-menu>
                                <v-btn text class="white--text" @click="signOut">Logg inn på nytt</v-btn>
                            </v-layout>

                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col v-if="meterPoint && !meterPoint.contractStart" cols="12">
                    <v-card color="error" class="white--text mb-4">
                        <v-card-title>
                            <v-icon large left color="white">mdi-alert-circle-outline</v-icon>
                            Begrenset historikk
                        </v-card-title>
                        <v-card-text>
                            <div class="white--text">
                                Beklager, vi mangler informasjon om når strømavtalen din ble inngått og kan dessverre
                                ikke vise forbrukshistorikk.
                            </div>

                            <v-layout>
                                <v-spacer/>
                                <v-btn text class="white--text" :href="contactUrl" target="_blank">Kontakt oss</v-btn>
                            </v-layout>

                        </v-card-text>
                    </v-card>
                </v-col>


                <v-col v-if="meterPoint && meterPoint.earthfaultIncident && earthfaultEnabled" cols="12">
                    <v-card color="error" class="white--text mb-4">
                        <v-card-title>
                            <v-icon large left color="white">mdi-alert-circle-outline</v-icon>
                            Jordfeil
                        </v-card-title>
                        <v-card-text>
                            <div class="white--text">
                                Det er registrert en mulig jordfeil i anlegget ditt.
                            </div>

                            <v-layout>
                                <v-spacer/>
                                <v-btn text class="white--text" @click="showEarthfault = true">Vis detaljer</v-btn>
                            </v-layout>

                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col v-if="isPowerOutage" cols="12">
                    <v-card color="error" class="white--text mb-4">
                        <v-card-title>
                            <v-icon large left color="white">mdi-power-plug-off</v-icon>
                            Strømstans
                        </v-card-title>
                        <v-card-text>
                            <div class="white--text">
                                Strømmåleren din registrerte at anlegget ble strømløst for
                                {{formatDuration(latestPowerOutage.from)}}.

                                <span v-if="isPhaseOutage">
                                    Gjelder bare enkelte faser.
                                </span>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col v-if="(substationOutage || manualSubstationOutage) && !isPowerOutage" cols="12">
                    <v-card color="error" class="white--text mb-4">
                        <v-card-title>
                            <v-icon large left color="white">mdi-power-plug-off</v-icon>
                            {{ $t('messages.outage') }}
                            <v-spacer/>
                        </v-card-title>
                        <v-card-text>
                            <div class="white--text text-center">
                                Det er registrert strømstans i ditt område.
                            </div>
                            <v-layout class="pt-2">
                                <v-spacer/>
                                <v-btn text class="white--text" to="/outage">Vis kart</v-btn>
                            </v-layout>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col v-if="plannedSubstationOutage && !substationOutage && !isPowerOutage" cols="12">
                    <v-card color="orange darken-1" class="white--text mb-4">
                        <v-card-title>
                            <v-icon large left color="white">mdi-power-plug-off</v-icon>
                            Planlagt utkobling
                            <v-spacer/>
                        </v-card-title>
                        <v-card-text>
                            <div class="white--text">
                                Det er planlagt en utkobling av strømmen i ditt område mellom
                                {{ formatDateTime(plannedSubstationOutage.from) }} og {{ formatDateTime(plannedSubstationOutage.to) }}.
                            </div>
                            <v-layout class="pt-1">
                                <v-spacer/>
                                <v-btn text class="white--text" to="/outage">Vis kart</v-btn>
                            </v-layout>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="6">
                    <v-card class="mr-1 mb-4" @click="showMeterValuesForThisYear('CONSUMPTION')">
                        <v-card-text>
                            <v-layout align-center>
                                <div>
                                    <div v-if="hasProduction" class="caption text-uppercase">Forbruk</div>
                                    <div class="caption text-uppercase">Hittil i år</div>
                                </div>
                                <v-spacer/>
                                <v-icon v-if="consumptionChangeFromLastYear != null && consumptionChangeFromLastYear > 0" size="18" color="error" class="pr-1">mdi-arrow-top-right-thin-circle-outline</v-icon>
                                <v-icon v-if="consumptionChangeFromLastYear != null && consumptionChangeFromLastYear <= 0" size="18" color="success" class="pr-1">mdi-arrow-bottom-right-thin-circle-outline</v-icon>
                                <div v-if="consumptionChangeFromLastYear != null && consumptionChangeFromLastYear > 0" class="error--text">{{ formatNumber(consumptionChangeFromLastYear, 0) }}%</div>
                                <div v-if="consumptionChangeFromLastYear != null && consumptionChangeFromLastYear <= 0" class="success--text">{{ formatNumber(Math.abs(consumptionChangeFromLastYear), 0) }}%</div>
                            </v-layout>
                            <v-layout align-baseline class="py-2">
                                <v-spacer/>
                                <div class="text-h5 black--text mr-2">{{ formatNumber(consumptionThisYear, 0) || '&mdash;'}}</div>
                                <div class="caption black--text">kWt</div>
                                <v-spacer/>
                            </v-layout>

                            <div v-if="consumptionChangeFromLastYear !== null">
                                <v-layout align-center>
                                    <div class="caption" style="line-height: 1.5; width:35px" v-text="thisYear"/>
                                    <div style="width: calc(100% - 35px)">
                                        <div class="blue" style="height: 8px" :style="{width: getWidthInPercent(consumptionThisYear, consumptionLastYear) + '%'}"/>
                                    </div>
                                </v-layout>

                                <v-layout align-center>
                                    <div class="caption" style="line-height: 1.0; width:35px" v-text="lastYear"/>
                                    <div style="width: calc(100% - 35px)">
                                        <div class="grey" style="height: 8px" :style="{width: getWidthInPercent(consumptionLastYear, consumptionThisYear) + '%'}"/>
                                    </div>
                                </v-layout>
                            </div>
                            <div v-else style="height:30px"/>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="6">
                    <v-card class="ml-2 mb-4" @click="showMeterValuesForThisMonth('CONSUMPTION')">
                        <v-card-text>
                            <v-layout align-center>
                                <div>
                                    <div v-if="hasProduction" class="caption text-uppercase">Forbruk</div>
                                    <div class="caption text-uppercase" v-html="formatMonth(thisMonth) || '&nbsp;'"/>
                                </div>
                                <v-spacer/>
                                <v-icon v-if="consumptionChangeFromThisMonthLastYear != null && consumptionChangeFromThisMonthLastYear > 0" size="18" color="error" class="pr-1">mdi-arrow-top-right-thin-circle-outline</v-icon>
                                <v-icon v-if="consumptionChangeFromThisMonthLastYear != null && consumptionChangeFromThisMonthLastYear <= 0" size="18" color="success" class="pr-1">mdi-arrow-bottom-right-thin-circle-outline</v-icon>
                                <div v-if="consumptionChangeFromThisMonthLastYear != null && consumptionChangeFromThisMonthLastYear > 0" class="error--text">{{ formatNumber(consumptionChangeFromThisMonthLastYear, 0) }}%</div>
                                <div v-if="consumptionChangeFromThisMonthLastYear != null && consumptionChangeFromThisMonthLastYear <= 0" class="success--text">{{ formatNumber(Math.abs(consumptionChangeFromThisMonthLastYear), 0) }}%</div>
                            </v-layout>
                            <v-layout align-baseline class="py-2">
                                <v-spacer/>
                                <div class="text-h5 black--text mr-2">{{ formatNumber(consumptionThisMonth, 0) || '&mdash;'}}</div>
                                <div class="caption black--text">kWt</div>
                                <v-spacer/>
                            </v-layout>

                            <div v-if="consumptionChangeFromThisMonthLastYear !== null">
                                <v-layout align-center>
                                    <div class="caption" style="line-height: 1.5; width:35px" v-text="thisYear"/>
                                    <div style="width: calc(100% - 35px)">
                                        <div class="blue" style="height: 8px" :style="{width: getWidthInPercent(consumptionThisMonthThisYear, consumptionThisMonthLastYear) + '%'}"/>
                                    </div>
                                </v-layout>

                                <v-layout align-center>
                                    <div class="caption" style="line-height: 1.0; width:35px" v-text="lastYear"/>
                                    <div style="width: calc(100% - 35px)">
                                        <div class="grey" style="height: 8px" :style="{width: getWidthInPercent(consumptionThisMonthLastYear, consumptionThisMonthThisYear) + '%'}"/>
                                    </div>
                                </v-layout>
                            </div>
                            <div v-else style="height:30px"/>

                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="6" v-if="hasProduction">
                    <v-card class="mr-1 mb-4" @click="showMeterValuesForThisYear('PRODUCTION')">
                        <v-card-text>
                            <v-layout align-center>
                                <div>
                                    <div class="caption text-uppercase">Produksjon</div>
                                    <div class="caption text-uppercase">Hittil i år</div>
                                </div>
                                <v-spacer/>
                                <v-icon v-if="productionChangeFromLastYear != null && productionChangeFromLastYear > 0" size="18" color="success" class="pr-1">mdi-arrow-top-right-thin-circle-outline</v-icon>
                                <v-icon v-if="productionChangeFromLastYear != null && productionChangeFromLastYear <= 0" size="18" color="error" class="pr-1">mdi-arrow-bottom-right-thin-circle-outline</v-icon>
                                <div v-if="productionChangeFromLastYear != null && productionChangeFromLastYear > 0" class="success--text">{{ formatNumber(productionChangeFromLastYear, 0) }}%</div>
                                <div v-if="productionChangeFromLastYear != null && productionChangeFromLastYear <= 0" class="error--text">{{ formatNumber(Math.abs(productionChangeFromLastYear), 0) }}%</div>
                            </v-layout>
                            <v-layout align-baseline class="py-2">
                                <v-spacer/>
                                <div class="text-h5 black--text mr-2">{{ formatNumber(productionThisYear, 0) || '&mdash;'}}</div>
                                <div class="caption black--text">kWt</div>
                                <v-spacer/>
                            </v-layout>

                            <div v-if="productionChangeFromLastYear !== null">
                                <v-layout align-center>
                                    <div class="caption" style="line-height: 1.5; width:35px" v-text="thisYear"/>
                                    <div style="width: calc(100% - 35px)">
                                        <div class="green" style="height: 8px" :style="{width: getWidthInPercent(productionThisYear, productionLastYear) + '%'}"/>
                                    </div>
                                </v-layout>

                                <v-layout align-center>
                                    <div class="caption" style="line-height: 1.0; width:35px" v-text="lastYear"/>
                                    <div style="width: calc(100% - 35px)">
                                        <div class="grey" style="height: 8px" :style="{width: getWidthInPercent(productionLastYear, productionThisYear) + '%'}"/>
                                    </div>
                                </v-layout>
                            </div>
                            <div v-else style="height:30px"/>

                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="6" v-if="hasProduction">
                    <v-card class="ml-2 mb-4" @click="showMeterValuesForThisMonth('PRODUCTION')">
                        <v-card-text>
                            <v-layout align-center>
                                <div>
                                    <div class="caption text-uppercase">Produksjon</div>
                                    <div class="caption text-uppercase" v-html="formatMonth(thisMonth) || '&nbsp;'"/>
                                </div>
                                <v-spacer/>
                                <v-icon v-if="productionChangeFromThisMonthLastYear != null && productionChangeFromThisMonthLastYear > 0" size="18" color="success" class="pr-1">mdi-arrow-top-right-thin-circle-outline</v-icon>
                                <v-icon v-if="productionChangeFromThisMonthLastYear != null && productionChangeFromThisMonthLastYear <= 0" size="18" color="error" class="pr-1">mdi-arrow-bottom-right-thin-circle-outline</v-icon>
                                <div v-if="productionChangeFromThisMonthLastYear != null && productionChangeFromThisMonthLastYear > 0" class="success--text">{{ formatNumber(productionChangeFromThisMonthLastYear, 0) }}%</div>
                                <div v-if="productionChangeFromThisMonthLastYear != null && productionChangeFromThisMonthLastYear <= 0" class="error--text">{{ formatNumber(Math.abs(productionChangeFromThisMonthLastYear), 0) }}%</div>
                            </v-layout>
                            <v-layout align-baseline class="py-2">
                                <v-spacer/>
                                <div class="text-h5 black--text mr-2">{{ formatNumber(productionThisMonth, 0) || '&mdash;'}}</div>
                                <div class="caption black--text">kWt</div>
                                <v-spacer/>
                            </v-layout>

                            <div v-if="productionChangeFromThisMonthLastYear !== null">
                                <v-layout align-center>
                                    <div class="caption" style="line-height: 1.5; width:35px" v-text="thisYear"/>
                                    <div style="width: calc(100% - 35px)">
                                        <div class="green" style="height: 8px" :style="{width: getWidthInPercent(productionThisMonthThisYear, productionThisMonthLastYear) + '%'}"/>
                                    </div>
                                </v-layout>

                                <v-layout align-center>
                                    <div class="caption" style="line-height: 1.0; width:35px" v-text="lastYear"/>
                                    <div style="width: calc(100% - 35px)">
                                        <div class="grey" style="height: 8px" :style="{width: getWidthInPercent(productionThisMonthLastYear, productionThisMonthThisYear) + '%'}"/>
                                    </div>
                                </v-layout>
                            </div>
                            <div v-else style="height:30px"/>

                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="6">
                    <v-card class="mr-2 mb-4">
                        <v-card-text>
                            <v-layout>
                                <div class="text-h5 black--text">{{ formatNumber(currentTemperature, 0) || '&mdash;' }}&deg;</div>
                                <v-spacer/>
                                <v-icon size="32" color="grey darken-3">mdi-thermometer</v-icon>
                            </v-layout>
                            <div class="caption" v-html="meterPoint && meterPoint.temperatureSensor && meterPoint.temperatureSensor.region || '&nbsp;'"/>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="6">
                    <v-card class="ml-2 mb-4" @click="showSpotPriceInfo = true">
                        <v-card-text>
                            <v-layout>
                                <div class="text-h5 black--text">{{ currentPrice && formatNumber(currentPrice * 100, 0) || '&mdash;' }}&nbsp;<span class="caption">øre/kWt</span></div>
                                <v-spacer/>
                                <v-icon size="32" color="grey darken-3">mdi-finance</v-icon>
                            </v-layout>
                            <div class="caption">
                                Spotpris {{ currentPriceTime && 'kl ' + formatHour(currentPriceTime.from) + '&ndash;' + formatHour(currentPriceTime.to) || '&nbsp;' }}
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" v-if="meterPoint && meterPoint.tariff" class="mb-4">
                    <grid-cost/>
                </v-col>

                <v-col cols="12">
                    <v-card class="mb-4">
                        <v-card-text>

                            <v-row>
                                <v-col cols="6">
                                    <div class="caption">
                                        Målernummer
                                    </div>
                                    <div class="caption font-weight-medium black--text"
                                         v-text="meterPoint && meterPoint.meterSerialNumber"/>
                                </v-col>

                                <v-col cols="6">
                                    <div class="caption">
                                        Målepunktnummer
                                    </div>
                                    <div class="caption font-weight-medium black--text"
                                         v-text="meterPoint && meterPoint.meterPointId"/>
                                </v-col>

                                <v-col cols="4">
                                    <div class="text-center">
                                        <div class="caption mb-1">
                                            Spenning
                                        </div>
                                        <v-chip color="blue white--text" small
                                                v-html="meterPoint && meterPoint.voltage ? meterPoint.voltage + '&nbsp;V' : '&mdash;'"/>
                                    </div>
                                </v-col>

                                <v-col cols="4">
                                    <div class="text-center">
                                        <div class="caption mb-1">
                                            Hovedsikring
                                        </div>
                                        <v-chip color="blue white--text" small
                                                v-html="meterPoint && meterPoint.fuseSize ? meterPoint.fuseSize + '&nbsp;A' : '&mdash;'"/>
                                    </div>
                                </v-col>

                                <v-col cols="4">
                                    <div class="text-center">
                                        <div class="caption mb-1">
                                            Faser
                                        </div>
                                        <v-chip color="blue white--text" small
                                                v-html="meterPoint && meterPoint.phases ? meterPoint.phases + '-fase' : '&mdash;'"/>
                                    </div>
                                </v-col>

                                <v-col cols="12" v-if="meterPoint && meterPoint.hanPortActivated != null">
                                    <div class="text-center">
                                        <div class="caption mb-1">
                                            HAN-port
                                        </div>
                                        <v-chip small outlined @click="showHanInfo = true">
                                            <v-icon left x-small :color="meterPoint.hanPortActivated ? 'green' : 'red'">mdi-circle</v-icon>
                                            <span v-if="meterPoint.hanPortActivated">Åpen</span>
                                            <span v-else>Stengt</span>
                                        </v-chip>
                                    </div>
                                </v-col>

                            </v-row>

                            <div v-if="meterPoint && meterPoint.latestPowerOutages" class="mt-5">
                                <div class="caption text-uppercase text-center">Seneste strømstans</div>

                                <v-data-table :items="meterPoint.latestPowerOutages"
                                              :headers="[{text: 'Fra', value: 'from'}, {text: 'Til', value: 'to'}, {text: 'Fase', value: 'type'}]"
                                              disable-sort
                                              hide-default-footer
                                              disable-pagination
                                              :mobile-breakpoint="0"
                                              dense
                                              class="dense-table"
                                              no-data-text="Ingen registrerte strømstans siste 12 måneder">

                                    <template v-slot:item.from="{ item }">
                                        <span class="caption">{{ formatDateTime(item.from )}}</span>
                                    </template>

                                    <template v-slot:item.to="{ item }">
                                        <span class="caption">{{ formatDateTime(item.to )}}</span>
                                    </template>

                                    <template v-slot:item.type="{ item }">
                                        <span class="caption">{{ formatPowerOutageType(item.type )}}</span>
                                    </template>

                                </v-data-table>
                            </div>

                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" v-if="showInstallBanner">
                    <v-card color="success darken-1" class="white--text mb-4">
                        <v-card-title>
                            <v-icon left color="yellow lighten-2">mdi-lightbulb-on</v-icon>
                            Tips!
                            <v-spacer/>
                        </v-card-title>
                        <v-card-text>
                            <div class="white--text">
                                Legg til <strong>Min strøm</strong> som en snarvei på Hjem-skjermen din, så har du den alltid lett tilgjengelig.
                            </div>
                            <v-layout>
                                <v-spacer/>
                                <v-btn text class="white--text" @click="install">Legg til</v-btn>
                            </v-layout>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" v-if="showInstallBannerIos">
                    <v-card color="success darken-1" class="white--text mb-4">
                        <v-card-title>
                            <v-spacer/>

                            <v-icon left color="yellow lighten-2">mdi-lightbulb-on</v-icon>
                            Tips!
                            <v-spacer/>
                        </v-card-title>
                        <v-card-text>
                            <div class="white--text text-center">
                                Legg til <strong>Min strøm</strong> som en snarvei, så har du den alltid lett tilgjengelig.
                            </div>
                            <v-layout align-center class="pt-3">
                                <v-spacer/>
                                <div class="white--text">
                                    Trykk
                                </div>
                                <v-icon class="px-1" color="white">mdi-export-variant</v-icon>
                                <div class="white--text">
                                    og velg "Legg til på Hjem-skjermen"
                                </div>
                                <v-spacer/>
                            </v-layout>
                        </v-card-text>
                    </v-card>
                </v-col>

            </v-row>

            <v-btn fixed
                   color="error"
                   v-if="this.meterPoint"
                   @click="showCreateObservation = true"
                   style="right:-54px; bottom: 125px; transform: rotate(-90deg)">
                <v-icon left>mdi-alert-outline</v-icon> Meld fare
            </v-btn>

            <create-observation v-model="showCreateObservation"/>

            <spot-price-info v-model="showSpotPriceInfo"/>
            <han-info v-model="showHanInfo"/>
            <earthfault v-model="showEarthfault"/>

        </v-container>
    </div>
</template>

<script>

    import Common from "../mixins/Common"
    import InstallWebapp from "../mixins/InstallWebapp"
    import HttpErrorHandler from "../mixins/HttpErrorHandler"
    import CreateObservation from "./CreateObservation"
    import SpotPriceInfo from "./SpotPriceInfo"
    import Contact from "./Contact"
    import HanInfo from "./HanInfo"
    import Earthfault from "./Earthfault";
    import GridCost from "./GridCost";
    import moment from "moment-timezone";
    import {EventBus} from "@/eventbus";
    import _ from "lodash";
    import jwt_decode from "jwt-decode";

    export default {
        name: 'Status',
        mixins: [Common, InstallWebapp, HttpErrorHandler],
        components: {CreateObservation, SpotPriceInfo, Contact, HanInfo, Earthfault, GridCost},

        data() {
            return {
                showSpotPriceInfo: false,
                showHanInfo: false,
                showEarthfault: false,
                showCreateObservation: false
            }
        },

        computed: {

            phoneNumber() {
                if (!this.idToken) {
                    return null;
                }
                return jwt_decode(this.idToken).phone_number;
            },

            thisMonth() {
                return this.meterValues?.consumption.months[this.meterValues?.consumption.months.length - 1]?.month;
            },

            thisMonthLastYear() {
                return this.month.replace(this.year, this.lastYear);
            },

            consumptionThisYear() {
                if (this.meterValues && this.meterPoint?.meterPointId === this.meterValues.meterPointId) {
                    return this.meterValues.consumption.years.find(each => each.year === this.thisYear)?.sum;
                }
                return null;
            },

            productionThisYear() {
                if (this.meterValues && this.meterPoint?.meterPointId === this.meterValues.meterPointId) {
                    return this.meterValues.production.years.find(each => each.year === this.thisYear)?.sum;
                }
                return null;
            },

            consumptionThisMonth() {
                if (this.meterValues && this.meterPoint?.meterPointId === this.meterValues.meterPointId) {
                    return this.meterValues.consumption.months.find(each => each.month === this.thisMonth)?.sum;
                }
                return null;
            },

            productionThisMonth() {
                if (this.meterValues && this.meterPoint?.meterPointId === this.meterValues.meterPointId) {
                    return this.meterValues.production.months.find(each => each.month === this.thisMonth)?.sum;
                }
                return null;
            },

            consumptionLastYear() {
                if (this.meterValues && this.meterPoint?.meterPointId === this.meterValues.meterPointId) {

                    let todayLastYear = moment().subtract(1, 'year').format('YYYY-MM-DD');
                    let consumption = this.meterValues.consumption.days.filter(each => each.day.startsWith(this.lastYear) &&
                                                                                       each.day.localeCompare(todayLastYear) < 0);

                    let completeness = this.meterValues.consumption.years.find(each => each.year === this.lastYear)?.completeness;

                    return consumption && completeness && completeness > 90 ? _.sumBy(consumption, 'sum') : null;
                }
                return null;
            },

            productionLastYear() {
                if (this.meterValues && this.meterPoint?.meterPointId === this.meterValues.meterPointId) {

                    let todayLastYear = moment().subtract(1, 'year').format('YYYY-MM-DD');
                    let production = this.meterValues.production.days.filter(each => each.day.startsWith(this.lastYear) &&
                                                                                       each.day.localeCompare(todayLastYear) < 0);

                    let completeness = this.meterValues.production.years.find(each => each.year === this.lastYear)?.completeness;

                    return production && completeness && completeness > 90 ? _.sumBy(production, 'sum') : null;
                }
                return null;
            },

            consumptionThisMonthLastYear() {
                if (this.meterValues && this.meterPoint?.meterPointId === this.meterValues.meterPointId) {

                    let todayLastYear = moment().subtract(1, 'year').format("YYYY-MM-DD");
                    let consumption = this.meterValues.consumption.days.filter(each => each.day.startsWith(this.thisMonthLastYear) &&
                                                                                       each.day.localeCompare(todayLastYear) < 0)
                    return _.sumBy(consumption, 'sum');
                }

                return null;
            },

            productionThisMonthLastYear() {
                if (this.meterValues && this.meterPoint?.meterPointId === this.meterValues.meterPointId) {

                    let todayLastYear = moment().subtract(1, 'year').format("YYYY-MM-DD");
                    let production = this.meterValues.production.days.filter(each => each.day.startsWith(this.thisMonthLastYear) &&
                                                                                       each.day.localeCompare(todayLastYear) < 0)
                    return _.sumBy(production, 'sum');
                }

                return null;
            },

            consumptionThisMonthThisYear() {
                if (this.meterValues && this.meterPoint?.meterPointId === this.meterValues.meterPointId) {

                    let today = moment().format("YYYY-MM-DD");
                    let consumption = this.meterValues.consumption.days.filter(each => each.day.startsWith(this.thisMonth) &&
                                                                                       each.day.localeCompare(today) < 0)
                    return _.sumBy(consumption, 'sum');
                }

                return null;
            },

            productionThisMonthThisYear() {
                if (this.meterValues && this.meterPoint?.meterPointId === this.meterValues.meterPointId) {

                    let today = moment().format("YYYY-MM-DD");
                    let production = this.meterValues.production.days.filter(each => each.day.startsWith(this.thisMonth) &&
                                                                                       each.day.localeCompare(today) < 0)
                    return _.sumBy(production, 'sum');
                }

                return null;
            },

            consumptionChangeFromLastYear() {
                if (this.consumptionThisYear && this.consumptionLastYear) {
                    return 100 * (this.consumptionThisYear - this.consumptionLastYear) / this.consumptionLastYear;
                }
                return null;
            },

            productionChangeFromLastYear() {
                if (this.productionThisYear && this.productionLastYear) {
                    return 100 * (this.productionThisYear - this.productionLastYear) / this.productionLastYear;
                }
                return null;
            },

            consumptionChangeFromThisMonthLastYear() {
                if (this.consumptionThisMonthThisYear && this.consumptionThisMonthLastYear) {
                    return 100 * (this.consumptionThisMonthThisYear - this.consumptionThisMonthLastYear) / this.consumptionThisMonthLastYear;
                }
                return null;
            },

            productionChangeFromThisMonthLastYear() {
                if (this.productionThisMonthThisYear && this.productionThisMonthLastYear) {
                    return 100 * (this.productionThisMonthThisYear - this.productionThisMonthLastYear) / this.productionThisMonthLastYear;
                }
                return null;
            },

            currentTemperature() {
                return this.meterValues?.temperature?.current?.value;
            },

            currentPrice() {
                return this.meterValues?.price?.current?.value;
            },

            currentPriceTime() {
                if (this.meterValues && this.meterValues.price && this.meterValues.price.current) {
                    return {
                        from: this.meterValues.price.current.time,
                        to: moment.utc(this.meterValues.price.current.time).add(1, 'hour').format()
                    }
                }
                return null;
            },

            latestPowerOutage() {
                return this.meterPoint?.latestPowerOutages?.[0];
            },

            isPowerOutage() {
                return this.latestPowerOutage && !this.latestPowerOutage.to;
            },

            isPhaseOutage() {
                return this.isPowerOutage && this.latestPowerOutage.type.indexOf('PHASE') !== -1;
            },

            substationOutage() {
                if (!this.meterPoint?.substationOutages) {
                    return null;
                }

                return this.meterPoint.substationOutages.outages.length > 0 ? this.meterPoint.substationOutages.outages[0] : null;
            },

            manualSubstationOutage() {
                if (!this.meterPoint?.substationOutages) {
                    return null;
                }
                let now = moment();
                let manualOutage = this.meterPoint.substationOutages.plannedOutages.filter(each => each.type === 'ongoingError' && moment(each.to).isAfter(now));
                return manualOutage.length > 0 ? manualOutage[0] : null;
            },

            plannedSubstationOutage() {
                if (!this.meterPoint?.substationOutages) {
                    return null;
                }

                let now = moment();
                let plannedOutages = this.meterPoint.substationOutages.plannedOutages.filter(each => moment(each.to).isAfter(now) && each.type !== 'ongoingError');

                return plannedOutages.length > 0 ? plannedOutages[0] : null;
            },
        },

        methods: {
            showMeterValuesForThisYear(valueType) {
                this.chartType = 'ENERGY';
                this.valueType = valueType;
                this.$store.commit('setYear', this.thisYear);
                EventBus.$emit('year-selected');
            },

            showMeterValuesForThisMonth(valueType) {
                this.chartType = 'ENERGY';
                this.valueType = valueType;
                this.$store.commit('setMonth', this.thisMonth);
                EventBus.$emit('month-selected');
            },

            getWidthInPercent(thisValue, otherValue) {
                if (thisValue === 0) {
                    return 0;
                }
                if (thisValue >= otherValue) {
                    return 100;
                }

                return 100 - (otherValue - thisValue) / otherValue * 100;
            },

            formatPowerOutageType(type) {
                switch (type) {
                    case "POWER_OUTAGE":
                        return "Alle";
                    case "PHASE_L1_OUTAGE":
                        return "Fase 1";
                    case "PHASE_L2_OUTAGE":
                        return "Fase 2";
                    case "PHASE_L3_OUTAGE":
                        return "Fase 3";
                    default:
                        return type;
                }
            }
        }
    }
</script>

<style lang="scss">

.dense-table th {
    font-weight: normal;
}

.dense-table th, .dense-table td  {
    padding-right: 12px !important;
    padding-left: 12px !important;
}

</style>