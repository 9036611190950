<template>

    <div style="padding-bottom:60px">

        <v-layout align-center class="py-1">
            <v-spacer/>
            <div class="text-button pl-12">
                {{ formatDay(day) }}. {{ formatMonth(month) }} {{ year }}
            </div>
            <v-spacer/>
            <v-btn icon class="mr-1" color="primary" @click="showSettings = true">
                <v-icon>mdi-cog</v-icon>
            </v-btn>
            <meter-values-settings v-model="showSettings"/>
        </v-layout>

        <value-type-selector/>

        <highcharts ref="chart" constructor-type="stockChart" :options="chartOptions"
                    :style="{height: $vuetify.breakpoint.xs ? '260px' : '500px'}"
                    style="width:100%"
                    v-observe-visibility="visibilityChanged"/>

        <div v-if="showEnergy && showConsumption">
            <div class="pt-3">
                <div class="text-center text-uppercase caption">Forbruk {{ formatDay(day) }}. {{ formatMonth(month) }} {{ year }}</div>
                <div class="text-center">
                    <span class="title mr-1" v-text="formatNumber(sumConsumption, 0)"/>
                    <span class="caption">kWt</span></div>
            </div>

            <v-divider/>

            <v-layout>
                <div class="py-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Høyeste forbruk</div>
                    <div class="text-center">
                        <span class="title mr-1" v-html="formatNumber(maxConsumption, 1) || '&mdash;'"/>
                        <span class="caption">kWt</span>
                    </div>
                    <div style="line-height: 1" class="text-center caption" v-html="formatShortHourInterval(timeOfMaxConsumption)"/>
                </div>

                <v-divider vertical/>

                <div class="py-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Snitt forbruk</div>
                    <div class="text-center">
                        <span class="title mr-1" v-html="formatNumber(averageConsumption, 1) || '&mdash;'"/>
                        <span class="caption">kWt</span></div>
                </div>
            </v-layout>
        </div>

        <div v-if="showEnergy && showProduction">
            <div class="pt-3">
                <div class="text-center text-uppercase caption">Produksjon {{ formatDay(day) }}. {{ formatMonth(month) }} {{ year }}</div>
                <div class="text-center">
                    <span class="title mr-1" v-text="formatNumber(sumProduction, 0)"/>
                    <span class="caption">kWt</span></div>
            </div>

            <v-divider/>

            <v-layout>
                <div class="py-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Høyeste produksjon</div>
                    <div class="text-center">
                        <span class="title mr-1" v-html="formatNumber(maxProduction, 1) || '&mdash;'"/>
                        <span class="caption">kWt</span>
                    </div>
                    <div style="line-height: 1" class="text-center caption" v-html="formatShortHourInterval(timeOfMaxProduction)"/>
                </div>

                <v-divider vertical/>

                <div class="py-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Snitt produksjon</div>
                    <div class="text-center">
                        <span class="title mr-1" v-html="formatNumber(averageProduction, 1) || '&mdash;'"/>
                        <span class="caption">kWt</span></div>
                </div>
            </v-layout>
        </div>

        <div v-if="showCost">
            <div class="pt-3">
                <div class="text-center text-uppercase caption">Nettleie {{ formatDay(day) }}. {{ formatMonth(month) }} {{ year }}</div>
                <div class="text-center">
                    <span class="title mr-1" v-text="formatNumber(sumGridCost, 2)"/>
                    <span class="caption">kr</span></div>
            </div>

            <v-divider/>

            <v-layout>
                <div class="py-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Dyreste nettleie</div>
                    <div class="text-center">
                        <span class="title mr-1" v-html="formatNumber(maxGridCost, 2) || '&mdash;'"/>
                        <span class="caption">kr</span>
                    </div>
                    <div style="line-height: 1" class="text-center caption" v-html="formatShortHourInterval(timeOfMaxGridCost)"/>
                </div>

                <v-divider vertical/>

                <div class="py-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Snitt nettleie</div>
                    <div class="text-center">
                        <span class="title mr-1" v-html="formatNumber(averageGridCost, 2) || '&mdash;'"/>
                        <span class="caption">kr/t</span></div>
                </div>
            </v-layout>
        </div>

        <div class="d-flex justify-center mt-4" style="position:fixed; bottom:65px; left:12px; right:12px">
            <v-btn-toggle ref="scroller"
                          mandatory
                          rounded
                          dense
                          color="primary"
                          v-model="selectedButton"
                          style="scroll-behavior:smooth"
                          class="overflow-x-auto overflow-y-hidden">

                <v-btn height="48" v-if="lastDayPreviousMonth" :value="lastDayPreviousMonth">
                    <template v-slot:default>
                        <div>
                            <v-icon small style="margin-top:6px">mdi-arrow-left</v-icon>
                            <div class="overline" style="margin-top:-6px">{{formatShortMonth(lastDayPreviousMonth)}}</div>
                        </div>
                    </template>
                </v-btn>

                <v-btn v-for="day in days" :key="day" height="48" :value="day" :ref="day">
                    <template v-slot:default>
                        <div>
                            <div style="margin-top:6px">{{ formatDay(day) }}</div>
                            <div class="overline" style="margin-top:-6px">{{ formatShortMonth(day) }}</div>
                        </div>
                    </template>
                </v-btn>

                <v-btn height="48" v-if="firstDayNextMonth" :value="firstDayNextMonth">
                    <template v-slot:default>
                        <div>
                            <v-icon small style="margin-top:6px">mdi-arrow-right</v-icon>
                            <div class="overline" style="margin-top:-6px">{{formatShortMonth(firstDayNextMonth)}}</div>
                        </div>
                    </template>
                </v-btn>

            </v-btn-toggle>
        </div>

    </div>

</template>

<script>

import MeterValuesSettings from "./MeterValuesSettings"
import ValueTypeSelector from "./ValueTypeSelector"
import Common from "../mixins/Common"
import HttpErrorHandler from "../mixins/HttpErrorHandler"
import moment from "moment-timezone";
import _ from "lodash";

export default {
    name: 'MeterValuesByMonth',
    mixins: [Common, HttpErrorHandler],
    components: {MeterValuesSettings, ValueTypeSelector},

    data() {
        return {
            selectedButton: null,
            visible: true,
            sumConsumption: null,
            sumProduction: null,
            sumGridCost: null,
            averageConsumption: null,
            averageProduction: null,
            averageGridCost: null,
            maxConsumption: null,
            maxProduction: null,
            maxGridCost: null,
            timeOfMaxConsumption: null,
            timeOfMaxProduction: null,
            timeOfMaxGridCost: null,
            showSettings: false,
            meterValuesForMonth: {
                meterValues: [],
                month: null,
                meterPointId: null
            },

            chartOptions: {
                chart: {
                    backgroundColor: "transparent",
                    panning: false,
                    spacingBottom: 0,
                    style: {
                        fontFamily: "Roboto"
                    }
                },
                navigator: {
                    enabled: false
                },
                rangeSelector: {
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                tooltip: {
                    split: false,
                    shared: true,
                },
                xAxis: {
                    ordinal: false,
                    type: 'datetime',
                },
                yAxis: [
                    {
                        title: {
                            align: 'high',
                            offset: 5,
                            text: 'kWt',
                            rotation: 0,
                            y: 15
                        },
                        labels: {
                            x: -5
                        },
                        opposite: false
                    },
                    {
                        title: {
                            align: 'high',
                            offset: 5,
                            text: 'kr',
                            rotation: 0,
                            y: 15
                        },
                        labels: {
                            x: -5
                        },
                        opposite: false,
                        reversedStacks: false
                    },
                    {
                        visible: false,
                        title: {
                            align: 'high',
                            offset: 5,
                            text: '°C',
                            rotation: 0,
                            y: 15
                        },
                        labels: {
                            x: 5
                        },
                        opposite: true,
                        allowDecimals: false
                    },
                    {
                        visible: false,
                        title: {
                            align: 'high',
                            offset: 5,
                            text: 'øre',
                            rotation: 0,
                            y: 15
                        },
                        labels: {
                            x: 5
                        },
                        min: 0,
                        opposite: true,
                        allowDecimals: false
                    }
                ],
                title: {
                    text: null
                },
                time: {
                    getTimezoneOffset: timestamp => -moment.tz(timestamp, 'Europe/Oslo').utcOffset()
                },
                legend: {
                    enabled: true,
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom',
                },
                plotOptions: {
                    column: {
                        dataGrouping: {
                            enabled: false,
                        }
                    },
                    series: {
                        events: {
                            legendItemClick: function (e) {
                                e.preventDefault();
                            }
                        }
                    }
                },
                exporting: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                loading: {
                    labelStyle: {
                        fontWeight: "normal"
                    }
                },
                series: [],
            }
        }
    },

    methods: {

        loadMeterValuesForMonth() {
            if (this.meterValuesForMonth.month !== this.month ||
                this.meterValuesForMonth.meterPointId !== this.meterPointId) {

                let from = moment.tz(this.day, "Europe/Oslo").startOf("month").utc();
                let to = moment.tz(this.day, "Europe/Oslo").endOf("month").utc();

                let url = `${this.apiUrl}customer/netowner/${this.netownerId}/metervalues/${this.meterPointId}`;
                let params = {
                    from: from.format(),
                    to: to.format(),
                    temperatureSensorId: this.meterPoint.temperatureSensor?.id
                };

                this.meterValuesForMonth = {
                    meterValues: this.$http.get(url, {params: params}),
                    month: this.month,
                    meterPointId: this.meterPointId
                }
            }

            return this.meterValuesForMonth.meterValues;
        },

        async loadMeterValues() {
            if (!this.day || !this.visible) {
                return;
            }

            this.chartOptions.xAxis.min = moment.tz(this.day, "Europe/Oslo").startOf("day").utc().valueOf();
            this.chartOptions.xAxis.max = moment.tz(this.day, "Europe/Oslo").startOf("day").add(1, "day").subtract(1, "hour").utc().valueOf();

            try {
                let response = await this.loadMeterValuesForMonth();

                let consumptionAbsolute = new Map();
                response.body.consumptionAbsolute.filter(this.isCurrentDay).forEach(each => {
                    consumptionAbsolute.set(each[0], this.formatNumber(each[1], 0));
                });

                let productionAbsolute = new Map();
                response.body.productionAbsolute.filter(this.isCurrentDay).forEach(each => {
                    productionAbsolute.set(each[0], this.formatNumber(each[1], 0));
                });

                let consumptionInterval = response.body.consumptionInterval.filter(this.isCurrentDay).map(each => ({
                    x: moment(each[0]).valueOf(),
                    y: each[1],
                    abs: consumptionAbsolute.get(each[0]) || '-'
                }));

                let productionInterval = response.body.productionInterval.filter(this.isCurrentDay).map(each => ({
                    x: moment(each[0]).valueOf(),
                    y: each[1],
                    abs: productionAbsolute.get(each[0]) || '-'
                }));

                let gridCost = response.body.cost.filter(this.isCurrentDay2).map(each => ({
                    x: moment(each.time).valueOf(),
                    y: each.gridCost
                }));

                let electricityCost = response.body.cost.filter(this.isCurrentDay2).map(each => ({
                    x: moment(each.time).valueOf(),
                    y: each.electricityCost
                }));

                let temperature = response.body.temperatures.filter(this.isCurrentDay).map(each => ({
                    x: moment(each[0]).valueOf(),
                    y: each[1]
                }));

                let prices = response.body.prices.filter(this.isCurrentDay).map(each => ({
                    x: moment(each[0]).valueOf(),
                    y: each[1] * 100
                }));

                this.sumConsumption = _.sumBy(consumptionInterval, 'y');
                this.averageConsumption = _.meanBy(consumptionInterval, 'y') || null;
                this.maxConsumption = _.maxBy(consumptionInterval, 'y')?.y;
                this.timeOfMaxConsumption = this.maxConsumption && moment.utc(consumptionInterval.find(each => each.y === this.maxConsumption)?.x).format();

                this.sumProduction = _.sumBy(productionInterval, 'y');
                this.averageProduction = _.meanBy(productionInterval, 'y') || null;
                this.maxProduction = _.maxBy(productionInterval, 'y')?.y;
                this.timeOfMaxProduction = this.maxProduction && moment.utc(productionInterval.find(each => each.y === this.maxProduction)?.x).format();

                this.sumGridCost = _.sumBy(gridCost, 'y');
                this.averageGridCost = _.meanBy(gridCost, 'y') || null;
                this.maxGridCost = _.maxBy(gridCost, 'y')?.y;
                this.timeOfMaxGridCost = this.maxGridCost && moment.utc(gridCost.find(each => each.y === this.maxGridCost)?.x).format();

                this.chartOptions.series = [
                    {
                        id: "0",
                        data: consumptionInterval,
                        visible: false,
                        showInLegend: false,
                        name: "Forbruk",
                        type: "column",
                        color: "#2196F3",
                        index: 0,
                        yAxis: 0,
                        zIndex: 0,
                        tooltip: {
                            pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br>● Målerstand: <b>{point.abs} kWt</b><br>',
                            valueSuffix: ' kWt',
                            valueDecimals: 1,
                            dateTimeLabelFormats: {
                                hour: '%A %e. %b  %H:%M'
                            }
                        },
                    },
                    {
                        id: "1",
                        data: productionInterval,
                        visible: false,
                        showInLegend: false,
                        name: "Produksjon",
                        type: "column",
                        color: "#66BB6A",
                        index: 0,
                        yAxis: 0,
                        zIndex: 0,
                        tooltip: {
                            pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br>● Målerstand: <b>{point.abs} kWt</b><br>',
                            valueSuffix: ' kWt',
                            valueDecimals: 1,
                            dateTimeLabelFormats: {
                                hour: '%A %e. %b  %H:%M'
                            }
                        },
                    },
                    {
                        id: "2",
                        data: gridCost,
                        visible: false,
                        showInLegend: false,
                        name: "Nettleie",
                        type: "column",
                        stack: 'cost',
                        stacking: 'normal',
                        color: "#2196F3",
                        index: 0,
                        yAxis: 1,
                        zIndex: 0,
                        tooltip: {
                            valueSuffix: ' kr',
                            valueDecimals: 2,
                        }
                    },
                    {
                        id: "3",
                        data: electricityCost,
                        visible: false,
                        showInLegend: false,
                        name: "Kraft (estimert)",
                        type: "column",
                        stack: 'cost',
                        stacking: 'normal',
                        color: "#66BB6A",
                        index: 0,
                        yAxis: 1,
                        zIndex: 0,
                        tooltip: {
                            valueSuffix: ' kr',
                            valueDecimals: 2,
                        },
                    },
                    {
                        id: "4",
                        data: temperature,
                        visible: false,
                        showInLegend: false,
                        name: "Temperatur",
                        type: "spline",
                        color: "#F44336",
                        lineWidth: 2,
                        dashStyle: "ShortDot",
                        index: 1,
                        yAxis: 2,
                        zIndex: 1,
                        tooltip: {
                            valueSuffix: ' °C',
                            valueDecimals: 1,
                            dateTimeLabelFormats: {
                                hour: '%A %e. %b  %H:%M'
                            }
                        },
                    },
                    {
                        id: "5",
                        data: prices,
                        visible: false,
                        showInLegend: false,
                        name: "Spotpris",
                        type: "spline",
                        color: "#F44336",
                        lineWidth: 2,
                        dashStyle: "ShortDot",
                        index: 1,
                        yAxis: 3,
                        zIndex: 1,
                        tooltip: {
                            valueSuffix: ' øre/kWt',
                            valueDecimals: 1,
                            dateTimeLabelFormats: {
                                hour: '%A %e. %b  %H:%M'
                            }
                        },
                    }
                ];

                this.updateChartVisibility();
                this.$refs.chart.chart.reflow();

            } catch (error) {
                this.setErrorResponse(error);
            }
        },

        updateChartVisibility() {
            if (this.chartOptions.series && this.chartOptions.series.length > 0) {
                this.chartOptions.series[0].visible = this.showEnergy && this.showConsumption;
                this.chartOptions.series[1].visible = this.showEnergy && this.showProduction;
                this.chartOptions.series[2].visible = this.showCost;
                this.chartOptions.series[3].visible = this.showCost && this.showElectricityCost;
                this.chartOptions.series[4].visible = this.showTemperature;
                this.chartOptions.series[5].visible = this.showPrices;

                this.chartOptions.series[0].showInLegend = this.chartOptions.series[0].visible;
                this.chartOptions.series[1].showInLegend = this.chartOptions.series[1].visible;
                this.chartOptions.series[2].showInLegend = this.chartOptions.series[2].visible;
                this.chartOptions.series[3].showInLegend = this.chartOptions.series[3].visible;
                this.chartOptions.series[4].showInLegend = this.chartOptions.series[4].visible;
                this.chartOptions.series[5].showInLegend = this.chartOptions.series[5].visible;
            }

            this.chartOptions.yAxis[0].visible = this.showEnergy;
            this.chartOptions.yAxis[1].visible = this.showCost;
            this.chartOptions.yAxis[2].visible = this.showTemperature;
            this.chartOptions.yAxis[3].visible = this.showPrices;
        },

        isCurrentDay(value) {
            return moment.utc(value[0]).tz('Europe/Oslo').format().startsWith(this.day);
        },

        isCurrentDay2(value) {
            return moment.utc(value.time).tz('Europe/Oslo').format().startsWith(this.day);
        },

        daysInMonth(month) {
            return month && this.meterValues ? this.meterValues.consumption.days.map(each => each.day).filter(each => each.startsWith(month)) : [];
        },

        visibilityChanged(visible) {
            this.visible = visible;
            if (visible) {
                // Necessary in case browser window was resized
                this.loadMeterValues();
            }
        },

        scrollToSelectedDay() {
            let buttonLeft = this.$refs[this.day]?.[0]?.$el?.offsetLeft;
            let buttonWidth = this.$refs[this.day]?.[0]?.$el?.offsetWidth;
            let scrollerWidth = this.$refs.scroller.$el?.offsetWidth;

            if (buttonLeft === undefined || buttonWidth === undefined || scrollerWidth === undefined) {
                return;
            }

            this.$refs.scroller.$el.scrollLeft = buttonLeft - (scrollerWidth - buttonWidth) / 2;
        }
    },

    computed: {
        days() {
            return this.meterValues && this.month ? this.meterValues.consumption.days
                                                      .map(each => each.day)
                                                      .filter(each => each.startsWith(this.month))
                                                  : [];
        },

        lastDayPreviousMonth() {
            if (!this.month) {
                return null;
            }
            let month = moment(this.month).subtract(1, 'month').format('YYYY-MM');
            let days = this.daysInMonth(month);
            return days.length > 0 ? days[days.length - 1] : null;
        },

        firstDayNextMonth() {
            if (!this.month) {
                return null;
            }
            let month = moment(this.month).add(1, 'month').format('YYYY-MM');
            let days = this.daysInMonth(month);
            return days.length > 0 ? days[0] : null;
        }
    },

    watch: {
        selectedButton() {
            if (this.selectedButton) {
                if (this.selectedButton.split('-').length === 3) {
                    this.$store.commit("setDay", this.selectedButton);
                } else {
                    this.$store.commit("setMonth", this.selectedButton);
                }
            }
        },

        day() {
            this.selectedButton = this.day;
            this.loadMeterValues();

            this.$nextTick(() => {
                this.scrollToSelectedDay();
            });
        },

        meterPoint() {
            this.meterValuesForMonth.meterPointId = null; // Clear cache to force refresh.
            this.loadMeterValues();
        },

        valueType() {
            this.updateChartVisibility();
        },

        chartType() {
            this.updateChartVisibility();
        },

        showElectricityCost() {
            this.updateChartVisibility();
        },

        showTemperature() {
            this.updateChartVisibility();
        },

        showPrices() {
            this.updateChartVisibility();
        }
    },

    mounted() {
        this.selectedButton = this.day;
        this.updateChartVisibility();
        this.scrollToSelectedDay();
        this.loadMeterValues();
    }
}
</script>
