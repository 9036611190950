<template>
    <v-card>
        <v-card-text>

            <div class="pt-1 pb-4 pl-4 text-button">Nettleie</div>

            <v-simple-table dense class="pb-2 px-4 dense-table">
                <template v-slot:default>
                    <tbody>
                    <tr>
                        <td>Hittil i år</td>
                        <td class="text-end">{{ formatNumber(gridCostThisYear, 0) }} kr</td>
                        <td class="text-end">
                            <v-btn text color="primary" @click="showCostForYear(thisYear)">Vis</v-btn>
                        </td>
                    </tr>
                    <tr>
                        <td>Hittil i {{ formatMonth(thisMonth) }}</td>
                        <td class="text-end">{{ formatNumber(gridCostThisMonth, 0) }} kr</td>
                        <td class="text-end">
                            <v-btn text color="primary" @click="showCostForMonth(thisMonth)">Vis</v-btn>
                        </td>
                    </tr>
                    <tr>
                        <td>{{ lastYear }}</td>
                        <td class="text-end">{{ formatNumber(gridCostLastYear, 0) }} kr</td>
                        <td class="text-end">
                            <v-btn text color="primary" @click="showCostForYear(lastYear)">Vis</v-btn>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <fixed-price-level-by-month v-if="fixedPriceBasis" class="pr-2"/>
            <power-price-level-by-month v-if="powerPriceBasis" class="pr-2"/>

            <v-switch v-model="showTariffPrices"
                      label="Vis priser"
                      hide-details
                      class="ma-0 pl-4 pb-2"/>

            <div v-if="showTariffPrices" class="pt-4">

                <div class="body-1 text-center pb-4 black--text">
                    Nettleiepriser gjeldende fra
                    {{ formatLongDate(meterPoint.tariff.validFrom) }}
                </div>

                <v-simple-table v-if="meterPoint.tariff.energyPrice" dense class="pb-4 px-4">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th>
                                Energiledd
                            </th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="level in meterPoint.tariff.energyPrice.levels">
                            <td>{{ level.name }}</td>
                            <td class="text-end">
                                <v-layout align-baseline justify-end>
                                    {{ formatNumber(100 * level.price, 2) }}
                                    <div class="caption price-unit">øre/kWt</div>
                                </v-layout>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <v-simple-table v-if="meterPoint.tariff.fixedPrice" dense class="pb-4 px-4">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th>
                                <span v-if="fixedPriceBasis">
                                    Kapasitetsledd<sup>*</sup>
                                </span>
                                <span v-else>
                                    Fastledd
                                </span>
                            </th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="level in meterPoint.tariff.fixedPrice.levels">
                            <td v-if="level.min && level.max">{{ level.min }} &rarr; {{ level.max }} kW</td>
                            <td v-if="!level.min && level.max">0 &rarr; {{ level.max }} kW</td>
                            <td v-if="level.min && !level.max">&gt; {{ level.min }} kW</td>
                            <td class="text-end">
                                <v-layout align-baseline justify-end>
                                    {{ formatNumber(level.price, 0) }}
                                    <span class="caption price-unit">kr/mnd</span>
                                </v-layout>
                            </td>
                        </tr>
                        <tr v-if="meterPoint.tariff.fixedPrice.flatPrice">
                            <td>Fastpris</td>
                            <td class="text-end">
                                <v-layout align-baseline justify-end>
                                    {{ formatNumber(meterPoint.tariff.fixedPrice.flatPrice, 0) }}
                                    <div class="caption price-unit">
                                        kr/{{ formatTimeUnit(meterPoint.tariff.fixedPrice.timeUnit) }}
                                    </div>
                                </v-layout>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <v-simple-table v-if="meterPoint.tariff.powerPrice" dense class="pb-4 px-4">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th>
                                Effektledd<span v-if="powerPriceBasis"><sup>*</sup></span>
                            </th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="level in meterPoint.tariff.powerPrice.levels">
                            <td v-if="level.min && level.max">{{ level.min }} &rarr; {{ level.max }} kW</td>
                            <td v-if="!level.min && level.max">0 &rarr; {{ level.max }} kW</td>
                            <td v-if="level.min && !level.max">&gt; {{ level.min }} kW</td>
                            <td v-if="!level.min && !level.max">Effekt</td>
                            <td class="text-end">
                                <v-layout align-baseline justify-end>
                                    {{ formatNumber(level.price, 0) }}
                                    <span class="caption price-unit">kr/kW</span>
                                </v-layout>
                            </td>
                        </tr>
                        <tr v-if="meterPoint.tariff.powerPrice.flatPrice">
                            <td>Fastpris</td>
                            <td class="text-end">
                                <v-layout align-baseline justify-end>
                                    {{ formatNumber(meterPoint.tariff.powerPrice.flatPrice, 0) }}
                                    <div class="caption price-unit">
                                        kr/{{ formatTimeUnit(meterPoint.tariff.powerPrice.timeUnit) }}
                                    </div>
                                </v-layout>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <v-simple-table v-if="meterPoint.tariff.tax" dense class="pb-4 px-4">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th>
                                Offentlige avgifter
                            </th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="elFee in meterPoint.tariff.tax.elFees">
                            <td :class="{'py-1': meterPoint.tariff.tax.elFees.length > 1}">
                                <div>
                                    Forbruksavgift
                                </div>
                                <div v-if="meterPoint.tariff.tax.elFees.length > 1" class="caption">
                                    {{ formatLongDate(elFee.from) }} &rarr;
                                    {{ formatLongDate(elFee.to) }}
                                </div>
                            </td>
                            <td class="text-end">
                                <v-layout align-baseline justify-end>
                                    {{ formatNumber(100.0 * elFee.value, 2) }}
                                    <div class="caption price-unit">øre/kWt</div>
                                </v-layout>
                            </td>
                        </tr>
                        <tr>
                            <td>Enovaavgift</td>
                            <td v-if="meterPoint.tariff.tax.fixedEnovaFee" class="text-end">
                                <v-layout align-baseline justify-end>
                                    {{ formatNumber(meterPoint.tariff.tax.enovaFee, 0) }}
                                    <span class="caption price-unit">kr/år</span>
                                </v-layout>
                            </td>
                            <td v-else class="text-end">
                                <v-layout align-baseline justify-end>
                                    {{ formatNumber(100.0 * meterPoint.tariff.tax.enovaFee, 2) }}
                                    <div class="caption price-unit">øre/kWt</div>
                                </v-layout>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <div class="caption px-4">
                    Tariff {{ meterPoint.tariff.key }} (<em>{{ meterPoint.tariff.name }}</em>).
                    Prisene er {{ meterPoint && meterPoint.vatIncluded ? 'inklusive' : 'eksklusive' }} merverdiavgift.
                </div>

                <div class="caption px-4 pt-2"
                     v-if="fixedPriceBasis === 'MONTHLY_MAX_3'">
                    <sup>*</sup>Kapasitetsleddet er basert på gjennomsnittet av de tre timene du brukte mest strøm
                    i løpet av måneden, fordelt på tre ulike dager.
                </div>

                <div class="caption px-4 pt-2"
                     v-if="fixedPriceBasis === 'MONTHLY_MAX'">
                    <sup>*</sup>Kapasitetsleddet er basert på det høyeste timeforbruket av strøm i løpet av måneden.
                </div>

                <div class="caption px-4 pt-2"
                     v-if="powerPriceBasis === 'MONTHLY_MAX_3'">
                    <sup>*</sup>Effektleddet er basert på gjennomsnittet av de tre timene du brukte mest strøm
                    i løpet av måneden, fordelt på tre ulike dager.
                </div>

                <div class="caption px-4 pt-2"
                     v-if="powerPriceBasis === 'MONTHLY_MAX'">
                    <sup>*</sup>Effektleddet er basert på det høyeste timeforbruket av strøm i løpet av måneden.
                </div>

            </div>

        </v-card-text>
    </v-card>

</template>

<script>

import Common from "../mixins/Common"
import FixedPriceLevelByMonth from './FixedPriceLevelByMonth'
import PowerPriceLevelByMonth from './PowerPriceLevelByMonth'
import {EventBus} from "@/eventbus";

export default {
    name: 'GridCost',
    mixins: [Common],
    components: {FixedPriceLevelByMonth, PowerPriceLevelByMonth},

    data() {
        return {
            showTariffPrices: false,
        }
    },

    computed: {

        thisMonth() {
            return this.meterValues?.consumption.months[this.meterValues?.consumption.months.length - 1]?.month;
        },

        gridCostThisYear() {
            if (this.meterValues && this.meterPoint?.meterPointId === this.meterValues.meterPointId) {
                return this.meterValues.cost.years.find(each => each.year === this.thisYear)?.sumGridCost;
            }
            return null;
        },

        gridCostLastYear() {
            if (this.meterValues && this.meterPoint?.meterPointId === this.meterValues.meterPointId) {
                return this.meterValues.cost.years.find(each => each.year === this.lastYear)?.sumGridCost;
            }
            return null;
        },

        gridCostThisMonth() {
            if (this.meterValues && this.meterPoint?.meterPointId === this.meterValues.meterPointId) {
                return this.meterValues.cost.months.find(each => each.month === this.thisMonth)?.sumGridCost;
            }
            return null;
        }
    },

    methods: {
        showCostForYear(year) {
            this.chartType = 'COST';
            this.$store.commit('setYear', year);
            EventBus.$emit('year-selected');
        },

        showCostForMonth(month) {
            this.chartType = 'COST';
            this.$store.commit('setMonth', month);
            EventBus.$emit('month-selected');
        },

        formatTimeUnit(timeUnit) {
            switch (timeUnit) {
                case 'DAY': return 'dag';
                case 'MONTH': return 'mnd';
                case 'YEAR': return 'år';
                default: return null;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.price-unit {
    width: 58px;
    padding-left: 10px;
    color: #565353;
    white-space: nowrap;
    text-align: left;
}
</style>
