<script>

    import moment from "moment-timezone";

    export default {

        computed: {

            properties() {
                return this.$store.state.properties;
            },

            isLoading() {
                return this.$store.state.pendingRequests > 0;
            },

            apiUrl() {
                return this.properties?.ApiUrl;
            },

            netownerId() {
                return this.properties?.NetOwnerId;
            },

            netownerCode() {
                return this.properties?.NetOwnerCode;
            },

            netownerName() {
                return this.properties?.NetOwnerName;
            },

            outageMapEnabled() {
                return this.properties?.OutageMapEnabled;
            },

            earthfaultEnabled() {
                return this.properties?.EarthfaultEnabled;
            },

            earthfaultEmail() {
                return this.properties?.EarthfaultEmail;
            },

            earthfaultPhone() {
                return this.properties?.EarthfaultPhone;
            },

            earthfaultUrl() {
                return this.properties?.EarthfaultUrl;
            },

            twitterId() {
                return this.properties?.TwitterId;
            },

            facebookId() {
                return this.properties?.FacebookId;
            },

            contactEmail() {
                return this.properties?.ContactEmail;
            },

            contactPhone() {
                return this.properties?.ContactPhone;
            },

            contactUrl() {
                return this.properties?.ContactUrl;
            },

            privacyUrl() {
                return this.properties?.PrivacyUrl;
            },

            hourlyConsumptionThresholds() {
                return this.properties?.HourlyConsumptionThresholds || [2, 5, 10, 15, 20, 25, 30, 50, 100];
            },

            hourlyProductionThresholds() {
                return this.properties?.HourlyProductionThresholds || [1, 2, 3, 5, 10, 15, 20, 25, 30, 50, 100];
            },

            meterPointsLoaded() {
                return this.$store.state.meterPointsLoaded;
            },

            meterPoints() {
                return this.$store.state.meterPoints;
            },

            meterPoint() {
                return this.$store.state.meterPoint;
            },

            meterPointId() {
                return this.meterPoint?.meterPointId;
            },

            meterValues() {
                return this.$store.state.meterValues;
            },

            thisYear() {
                return '' + moment().tz('Europe/Oslo').get('year');
            },

            lastYear() {
                return '' + (moment().tz('Europe/Oslo').get('year') - 1);
            },

            year() {
                return this.$store.state.year;
            },

            month() {
                return this.$store.state.month;
            },

            day() {
                return this.$store.state.day;
            },

            prefilledPhoneNumber() {
                return this.$route?.params.phoneNumber;
            },

            prefilledCodeId() {
                return this.$route?.params.codeId;
            },

            idToken() {
                return this.$store.state.cognito.idToken;
            },

            showPreviousYear: {
                get() {
                    return this.$store.state.showPreviousYear;
                },
                set(value) {
                    this.$store.commit("setShowPreviousYear", value);
                }
            },

            showElectricityCost: {
                get() {
                    return this.$store.state.showElectricityCost;
                },
                set(value) {
                    this.$store.commit("setShowElectricityCost", value);
                }
            },

            showTemperature: {
                get() {
                    return this.$store.state.showTemperature;
                },
                set(value) {
                    this.$store.commit("setShowTemperature", value);
                }
            },

            showPrices: {
                get() {
                    return this.$store.state.showPrices;
                },
                set(value) {
                    this.$store.commit("setShowPrices", value);
                }
            },

            showConsumption() {
                return this.valueType === 'CONSUMPTION';
            },

            showProduction() {
                return this.valueType === 'PRODUCTION';
            },

            showEnergy() {
                return this.chartType === 'ENERGY';
            },

            showCost() {
                return this.chartType === 'COST';
            },

            valueType:{
                get() {
                    return this.$store.state.valueType;
                },
                set(value) {
                    this.$store.commit("setValueType", value);
                },
            },

            chartType:{
                get() {
                    return this.$store.state.chartType;
                },
                set(value) {
                    this.$store.commit("setChartType", value);
                },
            },

            meterValuesTab:{
                get() {
                    return this.$store.state.meterValuesTab;
                },
                set(value) {
                    this.$store.commit("setMeterValuesTab", value);
                },
            },

            hasProduction() {
                return this.meterValues && this.meterValues.production.max > 0;
            },

            hasTariff() {
                return this.meterPoint?.tariff != null;
            },

            fixedPriceBasis() {
                return this.meterPoint?.tariff?.fixedPrice?.priceBasis;
            },

            powerPriceBasis() {
                return this.meterPoint?.tariff?.powerPrice?.priceBasis;
            },
        },

        watch: {
            hasProduction() {
                if (!this.hasProduction) {
                    this.valueType = 'CONSUMPTION';
                }
            },

            hasTariff() {
                if (!this.hasTariff) {
                    this.chartType = 'ENERGY';
                }
            }
        },

        methods: {
            signOut() {
                this.$store.commit("setCognitoRefreshToken", null);
                window.location.reload();
            },

            formatMonth(month) {
                return month && moment(month).format("MMMM");
            },

            formatShortMonth(month) {
                return this.formatMonth(month).substring(0, 3);
            },

            formatLongMonth(month) {
                return moment(month).format("MMMM YYYY");
            },

            formatDateTime(date) {
                return date ? moment(date).tz("Europe/Oslo").format("YYYY-MM-DD HH:mm") : null;
            },

            formatLongDate(date) {
                return date ? moment(date).tz("Europe/Oslo").format('D. MMM YYYY') : null;
            },

            formatDay(day) {
                return moment(day).date();
            },

            formatHour(date) {
                return date ? moment(date).tz("Europe/Oslo").format("HH") : null;
            },

            formatLongHourInterval(hour) {
                if (!hour) {
                    return null;
                }
                return moment(hour).format('dddd D. MMM [kl] HH') + '&ndash;' +
                       moment(hour).add(1, 'hour').format('HH');
            },

            formatShortHourInterval(hour) {
                if (!hour) {
                    return null;
                }
                return moment(hour).format('[kl] HH') + '&ndash;' +
                       moment(hour).add(1, 'hour').format('HH');
            },

            formatDuration(date) {
                return date ? moment.duration(moment(date).diff(moment())).humanize(true) : null;
            },

            formatNumber(number, fractionDigits) {
                if (number == null) {
                    return null;
                }
                if (fractionDigits !== null) {
                    try {
                        return number.toLocaleString('nb', {
                            minimumFractionDigits: fractionDigits,
                            maximumFractionDigits: fractionDigits
                        });
                    } catch (e) {
                        return number;
                    }
                }
                return number;
            },
        }
    }

</script>
