<template>

    <v-dialog :fullscreen="$vuetify.breakpoint.mobile" v-model="visible" max-width="420">
        <v-card tile>

            <v-card-title>
                <v-icon left large color="error">mdi-alert-circle-outline</v-icon>
                Jordfeil
                <v-spacer/>
                <v-btn icon @click="visible = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>

                <p>
                    Strømmåleren din har registrert en mulig jordfeil i anlegget ditt.
                </p>
                <p>
                    Jordfeil kan føre til livsfarlige støt eller brann.
                </p>
                <p v-if="intervals.length > 0">
                    Tabellen viser tidsrom der det er registrert jordfeil. Det kan hjelpe deg å finne ut hvilket
                    elektrisk utstyr som har feil.
                </p>
                <p>
                    Ta gjerne kontakt med oss hvis du har spørsmål.
                </p>

                <v-data-table v-if="intervals.length > 0"
                              :items="intervals"
                              :headers="[{text: 'Fra', value: 'from'}, {text: 'Til', value: 'to'}]"
                              :height="40 + Math.min(intervals.length * 30, 200)"
                              fixed-header
                              disable-sort
                              hide-default-footer
                              disable-pagination
                              :mobile-breakpoint="0"
                              dense>

                    <template v-slot:item.from="{ item }">
                        <span class="caption">{{ formatDateTime(item.from) }}</span>
                    </template>

                    <template v-slot:item.to="{ item }">
                        <span class="caption">{{ formatDateTime(item.to) }}</span>
                    </template>

                </v-data-table>

            </v-card-text>

            <v-card-actions>
                <v-btn v-if="earthfaultUrl"
                       text
                       color="primary"
                       :href="earthfaultUrl"
                       target="_blank"
                       class="mx-1">
                    Les mer
                </v-btn>

                <v-spacer/>

                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text
                               color="primary"
                               v-bind="attrs"
                               v-on="on">
                            Ta kontakt
                            <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <contact :phone="earthfaultPhone"
                             :email="earthfaultEmail"
                             :email-subject="'Jordfeil, målepunkt ' + meterPointId"/>
                </v-menu>

                <v-spacer/>

                <v-btn text
                       color="primary"
                       @click="visible = false">
                    Lukk
                </v-btn>
            </v-card-actions>
        </v-card>

    </v-dialog>

</template>

<script>

import Contact from "./Contact"
import Common from "@/mixins/Common"
import HttpErrorHandler from "@/mixins/HttpErrorHandler"

export default {
    name: 'Earthfault',
    mixins: [Common, HttpErrorHandler],
    components: {Contact},
    props: ['value'],

    data() {
        return {
            intervals: [],
        }
    },

    computed: {
        visible: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },

    methods: {
        loadEarthfaults() {
            if (!this.meterPointId) {
                this.intervals = [];
                return;
            }

            let url = `${this.apiUrl}customer/netowner/${this.netownerId}/earthfault/${this.meterPointId}/details`;
            this.$http.get(url).then(response => {
                this.intervals = response.body.intervals;
            }, response => {
                this.setErrorResponse(response);
            });
        },
    },

    watch: {
        visible() {
            if (this.visible) {
                this.loadEarthfaults();
            }
        },
    }
}
</script>
