<template>
    <div class="grey lighten-3" style="height: 100%">
        <v-container style="max-width: 600px">
            <H2>Meldinger</H2>
            <div v-for="item in messages">
                <v-card class="mr-1 mb-4">
                    <v-card-title class="text-body-2">
                        {{$t('messages.insightMessages.date')}}: {{item.created}}
                        <v-spacer></v-spacer>
                        <v-chip color="blue white--text" small>
                        {{ $t('messages.insightMessages.' + item.send_type) }}
                        </v-chip>
                    </v-card-title>
                    <v-card-text>
                        <v-textarea
                                :value="item.body"
                                auto-grow
                                rows="0"
                                no-resize
                                flat
                                solo
                                readonly
                                class="ml-n3 mb-n8"
                        ></v-textarea>
                    </v-card-text>
                </v-card>
            </div>
        </v-container>
    </div>
</template>


<script>


import Common from "../mixins/Common";
import HttpErrorHandler from "../mixins/HttpErrorHandler";
import moment from "moment-timezone";

export default {
    name: 'InsightMessages',
    mixins: [Common, HttpErrorHandler],
    components: {},
    data() {
        return {
            ADDRESS: ["ADDRESS", "ADRESSE", "ADRESS"],
            METER_POINT_ID: ["METERPOINTID", "MÅLEPUNKTNUMMER", "MÄTPUNKTNUMMER"],
            METER_SERIAL_NUMBER: ["METERNUMBER", "MÅLERNUMMER", "MÄTARNUMMER"]
        }
    },
    methods: {
        formatDate(date) {
            if (!date) {
                return null;
            }

            return moment(date).format("YYYY-MM-DD HH:mm:ss");
        },
        expandMessage(body) {

            let expandedBody = this.expandType(this.METER_POINT_ID, body  ,this.meterPointId);
            expandedBody = this.expandType(this.METER_SERIAL_NUMBER, expandedBody, this.meterPoint?.meterSerialNumber);
            expandedBody = this.expandType(this.ADDRESS, expandedBody, (this.meterPoint?.address1 || '') + ' ' + (this.meterPoint?.address2 || '' ));

            return expandedBody;
        },
        expandType(templateList, message, textToInject) {
            if(!message || !textToInject) {
                return message;
            }

            for(let key in templateList) {
                message = message.replace(("{" + templateList[key] + "}"), textToInject);
            }

            return message;
        }
    },
    computed: {
        messages() {

            return this.$store.state.messages.map(item => ({
                "id": item.id,
                "created": this.formatDate(item.created),
                "send_type": item.send_type,
                "body": this.expandMessage(item.body)}));

        }
    }
}
</script>