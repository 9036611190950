<template>
    <v-list>

        <v-list-item v-if="url" :href="url" target="_blank">
            <v-list-item-icon class="mr-3">
                <v-icon color="primary">mdi-web</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ urlHostname }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="phone" :href="phoneHref" target="_blank">
            <v-list-item-icon class="mr-3">
                <v-icon color="primary">mdi-phone</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ phone }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="email" :href="emailHref" target="_blank">
            <v-list-item-icon class="mr-3">
                <v-icon color="primary">mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ email }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

    </v-list>
</template>

<script>

import Common from "@/mixins/Common"

export default {
    name: 'Contact',
    mixins: [Common],
    components: {},
    props: ['url', 'phone', 'email', 'emailSubject'],

    computed: {

        urlHostname() {
            return this.url ? new URL(this.url)?.hostname : null;
        },

        emailHref() {
            return this.emailSubject
                   ? 'mailto:' + this.email + '?subject=' + this.emailSubject
                   : 'mailto:' + this.email;
        },

        phoneHref() {
            return 'tel:' + this.phone;
        },
    }
}
</script>
