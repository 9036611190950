<template>
    <div style="padding-bottom:60px">

        <v-layout align-center class="py-1">
            <v-spacer/>
            <div class="text-button pl-12" v-text="year"/>
            <v-spacer/>
            <v-btn icon class="mr-1" color="primary" @click="showSettings = true">
                <v-icon>mdi-cog</v-icon>
            </v-btn>
            <meter-values-settings v-model="showSettings"/>
        </v-layout>

        <value-type-selector/>

        <highcharts ref="chart" constructor-type="stockChart" :options="chartOptions"
                    :style="{height: $vuetify.breakpoint.xs ? '260px' : '500px'}"
                    style="width:100%"
                    v-observe-visibility="visibilityChanged"/>

        <div v-if="showEnergy && showConsumption">
            <v-layout class="pt-3">
                <div class="pb-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Forbruk {{ year }}</div>
                    <div class="text-center">
                        <span class="title mr-1" v-text="formatNumber(sumConsumption, 0)"/>
                        <span class="caption">kWt</span></div>
                </div>

                <v-divider vertical/>

                <div class="py-1"  style="width:50%">
                    <div class="text-center text-uppercase caption">{{ sumConsumption >= sumConsumptionPreviousYear ? 'Økning' : 'Reduksjon'}} fra {{ year - 1}}</div>
                    <div class="text-center">
                        <span v-if="sumConsumptionPreviousYear" class="title">
                            <v-icon v-show="sumConsumption >= sumConsumptionPreviousYear" size="28" color="red darken-2" style="margin-top: -6px">mdi-arrow-up-bold</v-icon>
                            <v-icon v-show="sumConsumption < sumConsumptionPreviousYear" size="28" color="green darken-2" style="margin-top: -6px">mdi-arrow-down-bold</v-icon>
                            {{ formatNumber(Math.abs(sumConsumption - sumConsumptionPreviousYear), 0) }}
                        </span>
                        <span v-else class="title mr-1">&mdash;</span>
                        <span class="caption">kWt</span></div>
                </div>
            </v-layout>

            <v-divider/>

            <v-layout>
                <div class="py-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Høyeste forbruk</div>
                    <div class="text-center">
                        <span class="title mr-1" v-html="formatNumber(maxConsumption, 1) || '&mdash;'"/>
                        <span class="caption">kWt</span>
                    </div>
                    <div style="line-height: 1" class="text-center caption" v-html="formatLongHourInterval(timeOfMaxConsumption)"/>
                </div>

                <v-divider vertical/>

                <div class="py-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Snitt forbruk</div>
                    <div class="text-center">
                        <span class="title mr-1" v-html="formatNumber(averageConsumption, 1) || '&mdash;'"/>
                        <span class="caption">kWt</span></div>
                </div>
            </v-layout>
        </div>

        <div v-if="showEnergy && showProduction">
            <v-layout class="pt-3">
                <div class="pb-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Produksjon {{ year }}</div>
                    <div class="text-center">
                        <span class="title mr-1" v-text="formatNumber(sumProduction, 0)"/>
                        <span class="caption">kWt</span></div>
                </div>

                <v-divider vertical/>

                <div class="py-1"  style="width:50%">
                    <div class="text-center text-uppercase caption">{{ sumProduction >= sumProductionPreviousYear ? 'Økning' : 'Reduksjon'}} fra {{ year - 1}}</div>
                    <div class="text-center">
                        <span v-if="sumProductionPreviousYear" class="title">
                            <v-icon v-show="sumProduction >= sumProductionPreviousYear" size="28" color="green darken-2" style="margin-top: -6px">mdi-arrow-up-bold</v-icon>
                            <v-icon v-show="sumProduction < sumProductionPreviousYear" size="28" color="red darken-2" style="margin-top: -6px">mdi-arrow-down-bold</v-icon>
                            {{ formatNumber(Math.abs(sumProduction - sumProductionPreviousYear), 0) }}
                        </span>
                        <span v-else class="title mr-1">&mdash;</span>
                        <span class="caption">kWt</span></div>
                </div>
            </v-layout>

            <v-divider/>

            <v-layout>
                <div class="py-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Høyeste produksjon</div>
                    <div class="text-center">
                        <span class="title mr-1" v-html="formatNumber(maxProduction, 1) || '&mdash;'"/>
                        <span class="caption">kWt</span>
                    </div>
                    <div style="line-height: 1" class="text-center caption" v-html="formatLongHourInterval(timeOfMaxProduction)"/>
                </div>

                <v-divider vertical/>

                <div class="py-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Snitt produksjon</div>
                    <div class="text-center">
                        <span class="title mr-1" v-html="formatNumber(averageProduction, 1) || '&mdash;'"/>
                        <span class="caption">kWt</span></div>
                </div>
            </v-layout>
        </div>

        <div v-if="showCost">
            <v-layout class="pt-3">
                <div class="pb-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Nettleie {{ year }}</div>
                    <div class="text-center">
                        <span class="title mr-1" v-text="formatNumber(sumGridCost, 0)"/>
                        <span class="caption">kr</span></div>
                </div>

                <v-divider vertical/>

                <div class="py-1"  style="width:50%">
                    <div class="text-center text-uppercase caption">{{ sumGridCost >= sumGridCostPreviousYear ? 'Økning' : 'Reduksjon'}} fra {{ year - 1}}</div>
                    <div class="text-center">
                        <span v-if="sumGridCostPreviousYear" class="title">
                            <v-icon v-show="sumGridCost >= sumGridCostPreviousYear" size="28" color="red darken-2" style="margin-top: -6px">mdi-arrow-up-bold</v-icon>
                            <v-icon v-show="sumGridCost < sumGridCostPreviousYear" size="28" color="green darken-2" style="margin-top: -6px">mdi-arrow-down-bold</v-icon>
                            {{ formatNumber(Math.abs(sumGridCost - sumGridCostPreviousYear), 0) }}
                        </span>
                        <span v-else class="title mr-1">&mdash;</span>
                        <span class="caption">kr</span></div>
                </div>
            </v-layout>

            <v-divider/>

            <v-layout>
                <div class="py-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Dyreste nettleie</div>
                    <div class="text-center">
                        <span class="title mr-1" v-html="formatNumber(maxGridCost, 2) || '&mdash;'"/>
                        <span class="caption">kr</span>
                    </div>
                    <div style="line-height: 1" class="text-center caption" v-html="formatLongHourInterval(timeOfMaxGridCost)"/>
                </div>

                <v-divider vertical/>

                <div class="py-1" style="width:50%">
                    <div class="text-center text-uppercase caption">Snitt nettleie</div>
                    <div class="text-center">
                        <span class="title mr-1" v-html="formatNumber(averageGridCost, 2) || '&mdash;'"/>
                        <span class="caption">kr/t</span></div>
                </div>
            </v-layout>

            <itemized-grid-cost :values="itemizedGridCost" class="pt-6"/>

            <div v-if="meterPoint && !meterPoint.vatIncluded"
                 class="caption text-center pt-6">
                (Beløpene er uten mva)
            </div>

        </div>

        <div class="d-flex justify-center mt-4" style="position:fixed; bottom:65px; left:12px; right:12px">
            <v-btn-toggle mandatory
                          rounded
                          dense
                          color="primary"
                          v-model="selectedButton"
                          class="overflow-x-auto overflow-y-hidden">
                <v-btn v-for="year in years" :key="year" :value="year"> {{year}}</v-btn>
            </v-btn-toggle>
        </div>

    </div>
</template>

<script>

    import MeterValuesSettings from "./MeterValuesSettings"
    import ValueTypeSelector from "./ValueTypeSelector"
    import ItemizedGridCost from "./ItemizedGridCost"
    import Common from "../mixins/Common"
    import HttpErrorHandler from "../mixins/HttpErrorHandler"
    import {EventBus} from '@/eventbus'
    import moment from "moment-timezone";
    import _ from "lodash";

    export default {
        name: 'MeterValuesByYear',
        mixins: [Common, HttpErrorHandler],
        components: {MeterValuesSettings, ValueTypeSelector, ItemizedGridCost},

        data() {
            return {
                selectedButton: null,
                showSettings: false,

                chartOptions: {
                    chart: {
                        backgroundColor: "transparent",
                        panning: false,
                        spacingBottom: 0,
                        style: {
                            fontFamily: "Roboto"
                        }
                    },
                    navigator: {
                        enabled: false
                    },
                    rangeSelector: {
                        enabled: false
                    },
                    scrollbar: {
                        enabled: false
                    },
                    tooltip: {
                        split: false,
                        shared: true,
                    },
                    xAxis: {
                        min: 0,
                        max: 11,
                        type: 'category',
                        categories: ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des']
                    },
                    yAxis: [
                        {
                            title: {
                                align: 'high',
                                offset: 5,
                                text: 'kWt',
                                rotation: 0,
                                y: 15
                            },
                            labels: {
                                x: -5
                            },
                            opposite: false
                        },
                        {
                            title: {
                                align: 'high',
                                offset: 5,
                                text: 'kr',
                                rotation: 0,
                                y: 15
                            },
                            labels: {
                                x: -5
                            },
                            opposite: false,
                            reversedStacks: false
                        },
                        {
                            visible: false,
                            title: {
                                align: 'high',
                                offset: 5,
                                text: '°C',
                                rotation: 0,
                                y: 15
                            },
                            labels: {
                                x: 5
                            },
                            opposite: true,
                            allowDecimals: false
                        },
                        {
                            visible: false,
                            title: {
                                align: 'high',
                                offset: 5,
                                text: 'øre',
                                rotation: 0,
                                y: 15
                            },
                            labels: {
                                x: 5
                            },
                            min: 0,
                            opposite: true,
                            allowDecimals: false
                        }
                    ],
                    title: {
                        text: null
                    },
                    legend: {
                        enabled: true,
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom',
                    },
                    plotOptions: {
                        column: {
                            dataGrouping: {
                                enabled: false,
                            },
                            pointPadding: 0.1,
                            groupPadding: 0.1,
                        },
                        series: {
                            events: {
                                legendItemClick: function(e) {
                                    e.preventDefault();
                                }
                            }
                        }
                    },
                    exporting: {
                        enabled: false
                    },
                    credits: {
                        enabled: false
                    },
                    loading: {
                        labelStyle: {
                            fontWeight: "normal"
                        }
                    },
                    series: [],
                }
            }
        },

        methods: {

            updateChart() {
                if (!this.meterValues) {
                    return;
                }

                let consumptionAbsolute = new Map();
                this.meterValues.consumption.months.forEach(each => {
                    consumptionAbsolute.set(each.month, this.formatNumber(each.absolute, 0));
                });

                let productionAbsolute = new Map();
                this.meterValues.production.months.forEach(each => {
                    productionAbsolute.set(each.month, this.formatNumber(each.absolute, 0));
                });

                let consumption = this.meterValues.consumption.months.filter(each => each.month.startsWith(this.year))
                    .map(each => ({
                        x: moment(each.month).month(),
                        y: each.sum,
                        abs: consumptionAbsolute.get(each.month) || '-'
                    }));

                let production = this.meterValues.production.months.filter(each => each.month.startsWith(this.year))
                    .map(each => ({
                        x: moment(each.month).month(),
                        y: each.sum,
                        abs: productionAbsolute.get(each.month) || '-'
                    }));

                let gridCost = this.meterValues.cost.months.filter(each => each.month.startsWith(this.year))
                    .map(each => ({
                        x: moment(each.month).month(),
                        y: each.sumGridCost
                    }));

                let electricityCost = this.meterValues.cost.months.filter(each => each.month.startsWith(this.year))
                    .map(each => ({
                        x: moment(each.month).month(),
                        y: each.sumElectricityCost
                    }));

                let consumptionPreviousYear = this.meterValues.consumption.months.filter(each => each.month.startsWith(this.year - 1))
                        .map(each => ({
                            x: moment(each.month).month(),
                            y: each.sum
                        }));

                let productionPreviousYear = this.meterValues.production.months.filter(each => each.month.startsWith(this.year - 1))
                        .map(each => ({
                            x: moment(each.month).month(),
                            y: each.sum
                        }));

                let gridCostPreviousYear = this.meterValues.cost.months.filter(each => each.month.startsWith(this.year - 1))
                    .map(each => ({
                        x: moment(each.month).month(),
                        y: each.sumGridCost
                    }));

                let electricityCostPreviousYear = this.meterValues.cost.months.filter(each => each.month.startsWith(this.year - 1))
                    .map(each => ({
                        x: moment(each.month).month(),
                        y: each.sumElectricityCost
                    }));

                let temperature = this.meterValues.temperature.months.filter(each => each.month.startsWith(this.year))
                        .map(each => ({
                            x: moment(each.month).month(),
                            y: each.average
                        }));

                let prices = this.meterValues.price.months.filter(each => each.month.startsWith(this.year))
                    .map(each => ({
                        x: moment(each.month).month(),
                        y: each.average * 100
                    }));

                this.chartOptions.series = [
                    {
                        id: "0",
                        data: consumption,
                        visible: false,
                        showInLegend: false,
                        name: "Forbruk",
                        type: "column",
                        color: "#2196F3",
                        index: 1,
                        yAxis: 0,
                        zIndex: 0,
                        tooltip: {
                            pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br>● Målerstand: <b>{point.abs} kWt</b><br>',
                            valueSuffix: ' kWt',
                            valueDecimals: 1,
                        },
                        events: {
                            click: (e) => {
                                let month = moment(this.year).month(e.point.x).format("YYYY-MM")
                                this.$store.commit('setMonth', month);
                                EventBus.$emit('month-selected');
                            }
                        }
                    },
                    {
                        id: "1",
                        data: consumptionPreviousYear,
                        visible: false,
                        showInLegend: false,
                        name: (this.year - 1),
                        type: "column",
                        color: "#b8b8b8",
                        index: 0,
                        yAxis: 0,
                        zIndex: 0,
                        tooltip: {
                            valueSuffix: ' kWt',
                            valueDecimals: 1,
                        },
                    },
                    {
                        id: "2",
                        data: production,
                        visible: false,
                        showInLegend: false,
                        name: "Produksjon",
                        type: "column",
                        color: "#66BB6A",
                        index: 1,
                        yAxis: 0,
                        zIndex: 0,
                        tooltip: {
                            pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br>● Målerstand: <b>{point.abs} kWt</b><br>',
                            valueSuffix: ' kWt',
                            valueDecimals: 1,
                        },
                        events: {
                            click: (e) => {
                                let month = moment(this.year).month(e.point.x).format("YYYY-MM")
                                this.$store.commit('setMonth', month);
                                EventBus.$emit('month-selected');
                            }
                        }
                    },
                    {
                        id: "3",
                        data: productionPreviousYear,
                        visible: false,
                        showInLegend: false,
                        name: (this.year - 1),
                        type: "column",
                        color: "#b8b8b8",
                        index: 0,
                        yAxis: 0,
                        zIndex: 0,
                        tooltip: {
                            valueSuffix: ' kWt',
                            valueDecimals: 1,
                        },
                    },
                    {
                        id: "4",
                        data: gridCost,
                        visible: false,
                        showInLegend: false,
                        name: "Nettleie",
                        type: "column",
                        stack: 'cost',
                        stacking: 'normal',
                        color: "#2196F3",
                        index: 1,
                        yAxis: 1,
                        zIndex: 0,
                        tooltip: {
                            valueSuffix: ' kr',
                            valueDecimals: 0,
                        },
                        events: {
                            click: (e) => {
                                let month = moment(this.year).month(e.point.x).format("YYYY-MM")
                                this.$store.commit('setMonth', month);
                                EventBus.$emit('month-selected');
                            }
                        }
                    },
                    {
                        id: "5",
                        data: electricityCost,
                        visible: false,
                        showInLegend: false,
                        name: "Kraft (estimert)",
                        type: "column",
                        stack: 'cost',
                        stacking: 'normal',
                        color: "#66BB6A",
                        index: 1,
                        yAxis: 1,
                        zIndex: 0,
                        tooltip: {
                            valueSuffix: ' kr',
                            valueDecimals: 0,
                        },
                        events: {
                            click: (e) => {
                                let month = moment(this.year).month(e.point.x).format("YYYY-MM")
                                this.$store.commit('setMonth', month);
                                EventBus.$emit('month-selected');
                            }
                        }
                    },
                    {
                        id: "6",
                        data: gridCostPreviousYear,
                        visible: false,
                        showInLegend: false,
                        name: 'Nettleie ' + (this.year - 1),
                        type: "column",
                        stack: 'costPreviousYear',
                        stacking: 'normal',
                        color: "#969696",
                        index: 0,
                        yAxis: 1,
                        zIndex: 0,
                        tooltip: {
                            valueSuffix: ' kr',
                            valueDecimals: 0,
                        },
                    },
                    {
                        id: "7",
                        data: electricityCostPreviousYear,
                        visible: false,
                        showInLegend: false,
                        name: 'Strøm ' + (this.year - 1),
                        type: "column",
                        stack: 'costPreviousYear',
                        stacking: 'normal',
                        color: "#b8b8b8",
                        index: 0,
                        yAxis: 1,
                        zIndex: 0,
                        tooltip: {
                            valueSuffix: ' kr',
                            valueDecimals: 0,
                        },
                    },

                    {
                        id: "8",
                        data: temperature,
                        visible: false,
                        showInLegend: false,
                        name: "Temperatur",
                        type: "spline",
                        color: "#F44336",
                        lineWidth: 2,
                        dashStyle: "ShortDot",
                        index: 2,
                        yAxis: 2,
                        zIndex: 1,
                        tooltip: {
                            valueSuffix: ' °C',
                            valueDecimals: 1,
                        },
                    },
                    {
                        id: "9",
                        data: prices,
                        visible: false,
                        showInLegend: false,
                        name: "Spotpris",
                        type: "spline",
                        color: "#F44336",
                        lineWidth: 2,
                        dashStyle: "ShortDot",
                        index: 2,
                        yAxis: 3,
                        zIndex: 1,
                        tooltip: {
                            valueSuffix: ' øre/kWt',
                            valueDecimals: 1,
                        },
                    }
                ];
                this.updateChartVisibility();
                this.$refs.chart.chart.reflow();
            },

            updateChartVisibility() {
                if (this.chartOptions.series && this.chartOptions.series.length > 0) {
                    this.chartOptions.series[0].visible = this.showEnergy && this.showConsumption;
                    this.chartOptions.series[1].visible = this.showEnergy && this.showConsumption && this.showPreviousYear;
                    this.chartOptions.series[2].visible = this.showEnergy && this.showProduction;
                    this.chartOptions.series[3].visible = this.showEnergy && this.showProduction && this.showPreviousYear;
                    this.chartOptions.series[4].visible = this.showCost;
                    this.chartOptions.series[5].visible = this.showCost && this.showElectricityCost;
                    this.chartOptions.series[6].visible = this.showCost && this.showPreviousYear;
                    this.chartOptions.series[7].visible = this.showCost && this.showElectricityCost && this.showPreviousYear;
                    this.chartOptions.series[8].visible = this.showTemperature;
                    this.chartOptions.series[9].visible = this.showPrices;

                    this.chartOptions.series[0].showInLegend = this.chartOptions.series[0].visible;
                    this.chartOptions.series[2].showInLegend = this.chartOptions.series[2].visible;
                    this.chartOptions.series[4].showInLegend = this.chartOptions.series[4].visible;
                    this.chartOptions.series[5].showInLegend = this.chartOptions.series[5].visible;
                    this.chartOptions.series[8].showInLegend = this.chartOptions.series[8].visible;
                    this.chartOptions.series[9].showInLegend = this.chartOptions.series[9].visible;
                }

                this.chartOptions.yAxis[0].visible = this.showEnergy;
                this.chartOptions.yAxis[1].visible = this.showCost;
                this.chartOptions.yAxis[2].visible = this.showTemperature;
                this.chartOptions.yAxis[3].visible = this.showPrices;
            },

            visibilityChanged(visible) {
                if (visible) {
                    // Necessary in case browser window was resized
                    this.$refs.chart.chart.reflow();
                }
            },
        },

        computed: {
            years() {
                return this.meterValues ? this.meterValues.consumption.years.map(each => each.year) : [];
            },

            yearConsumption() {
                return this.meterValues?.consumption.years.find(each => each.year === this.year);
            },

            yearProduction() {
                return this.meterValues?.production.years.find(each => each.year === this.year);
            },

            yearCost() {
                return this.meterValues?.cost.years.find(each => each.year === this.year);
            },

            sumConsumption() {
                return this.yearConsumption?.sum;
            },

            sumProduction() {
                return this.yearProduction?.sum;
            },

            sumGridCost() {
                return this.yearCost?.sumGridCost;
            },

            itemizedGridCost() {
                return this.yearCost?.itemizedGridCost;
            },

            sumConsumptionPreviousYear() {
                let todayLastYear = moment().subtract(1, 'year').format('YYYY-MM-DD');
                let previousYear = '' + (this.year - 1);
                let consumption = this.meterValues?.consumption.days.filter(each => each.day.startsWith(previousYear) &&
                                                                                    each.day.localeCompare(todayLastYear) < 0)

                let completeness = this.meterValues?.consumption.years.find(each => each.year === previousYear)?.completeness;

                return consumption && completeness && completeness > 90 ? _.sumBy(consumption, 'sum') : null;
            },

            sumProductionPreviousYear() {
                let todayLastYear = moment().subtract(1, 'year').format('YYYY-MM-DD');
                let previousYear = '' + (this.year - 1);
                let production = this.meterValues?.production.days.filter(each => each.day.startsWith(previousYear) &&
                                                                                  each.day.localeCompare(todayLastYear) < 0)

                let completeness = this.meterValues?.production.years.find(each => each.year === previousYear)?.completeness;

                return production && completeness && completeness > 90 ? _.sumBy(production, 'sum') : null;
            },

            sumGridCostPreviousYear() {
                let todayLastYear = moment().subtract(1, 'year').format('YYYY-MM-DD');
                let previousYear = '' + (this.year - 1);
                let cost = this.meterValues?.cost.days.filter(each => each.day.startsWith(previousYear) &&
                                                                      each.day.localeCompare(todayLastYear) < 0)

                // Note: Use completeness for consumption
                let completeness = this.meterValues?.consumption.years.find(each => each.year === previousYear)?.completeness;

                return cost && completeness && completeness > 90 ? _.sumBy(cost, 'sumGridCost') : null;
            },

            maxConsumption() {
                return this.yearConsumption?.max;
            },

            maxProduction() {
                return this.yearProduction?.max;
            },

            maxGridCost() {
                return this.yearCost?.maxGridCost;
            },

            averageConsumption() {
                return this.yearConsumption?.average;
            },

            averageProduction() {
                return this.yearProduction?.average;
            },

            averageGridCost() {
                return this.yearCost?.averageGridCost;
            },

            timeOfMaxConsumption() {
                return this.yearConsumption?.timeOfMax;
            },

            timeOfMaxProduction() {
                return this.yearProduction?.timeOfMax;
            },

            timeOfMaxGridCost() {
                return this.yearCost?.timeOfMaxGridCost;
            }
        },

        watch: {
            meterValues() {
                this.updateChart();
            },

            selectedButton() {
                if (this.selectedButton) {
                    this.$store.commit("setYear", this.selectedButton);
                }
            },

            year() {
                this.selectedButton = this.year;
                this.updateChart();
            },

            valueType() {
                this.updateChartVisibility();
            },

            chartType() {
                this.updateChartVisibility();
            },

            showPreviousYear() {
                this.updateChartVisibility();
            },

            showElectricityCost() {
                this.updateChartVisibility();
            },

            showTemperature() {
                this.updateChartVisibility();
            },

            showPrices() {
                this.updateChartVisibility();
            }
        },

        mounted() {
            this.selectedButton = this.year;
            this.updateChartVisibility();
            this.updateChart();
        }
    }
</script>
