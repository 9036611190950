<template>

    <v-container>

        <v-alert
            dismissible
            icon="mdi-information"
            dark
            app
            color="primary"
            style="color: white"
        >
          {{ $t('messages.social.toShowTweets') }} <v-btn text color="white" @click="openDialog"> {{ $t('messages.social.readMore') }} </v-btn>
        </v-alert>

        <v-dialog v-model="dialogVisible" max-width="500">
          <TwitterHelpDialog @closeDialog="closeDialog"/>
        </v-dialog>

      <div style="max-width: 500px" class="mx-auto pt-5">
            <Timeline v-if="visible" :id="twitterId" sourceType="profile" :options="{ chrome: 'noheader'}"/>
        </div>
    </v-container>

</template>

<script>

    import {Timeline} from 'vue-tweet-embed';
    import Common from "../mixins/Common"
    import TwitterHelpDialog from "./TwitterHelpDialog.vue";

    export default {
        name: 'Twitter',
        mixins: [Common],
        components: {TwitterHelpDialog, Timeline},

        data() {
            return {
                visible: true,
                dialogVisible: false,
            }
        },
        methods: {
            openDialog() {
              this.dialogVisible = true;
            },

            closeDialog() {
              this.dialogVisible = false;
            },
        },
        watch: {
            // Work-around to avoid twitter timeline to become invisible
            $route(to) {
                this.visible = "/twitter" === to.path;
            }
        },
    }

</script>
