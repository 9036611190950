<template>

    <v-container>
        <v-row dense>
            <v-col cols="12">
                <consumption-by-hour-of-day/>
            </v-col>
            <v-col cols="12" v-if="hasProduction">
                <production-by-hour-of-day/>
            </v-col>
            <v-col cols="12">
                <consumption-by-day-of-week/>
            </v-col>
            <v-col cols="12" v-if="hasProduction">
                <production-by-day-of-week/>
            </v-col>
            <v-col cols="12" v-if="hasProduction">
                <production-by-month/>
            </v-col>
            <v-col cols="12">
                <consumption-by-temperature/>
            </v-col>
            <v-col cols="12" v-if="this.meterPoint && this.meterPoint.fuseSize && this.meterPoint.voltage && this.meterPoint.phases">
                <load/>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>

    import Common from "../mixins/Common"
    import ConsumptionByHourOfDay from './insights/ConsumptionByHourOfDay'
    import ConsumptionByDayOfWeek from './insights/ConsumptionByDayOfWeek'
    import ConsumptionByTemperature from './insights/ConsumptionByTemperature'
    import ProductionByMonth from './insights/ProductionByMonth'
    import ProductionByHourOfDay from './insights/ProductionByHourOfDay'
    import ProductionByDayOfWeek from './insights/ProductionByDayOfWeek'
    import Load from './insights/Load'

    export default {
        name: 'Insights',
        mixins: [Common],
        components: {
            ConsumptionByHourOfDay,
            ConsumptionByDayOfWeek,
            ConsumptionByTemperature,
            ProductionByMonth,
            ProductionByHourOfDay,
            ProductionByDayOfWeek,
            Load
        },

        data() {
            return {
            }
        },
    }
</script>
