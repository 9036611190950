<template>

    <v-container style="max-width: 600px">

        <v-tabs show-arrows centered center-active height="40" v-model="meterValuesTab">
            <v-tab>År</v-tab>
            <v-tab>Måned</v-tab>
            <v-tab>Dag</v-tab>
            <v-tab><v-icon left color="green">mdi-lightbulb-on-outline</v-icon>Innsikt</v-tab>
        </v-tabs>

        <v-tabs-items v-model="meterValuesTab" touchless>
            <v-tab-item :transition="false" :reverse-transition="false">
                <meter-values-by-year/>
            </v-tab-item>
            <v-tab-item :transition="false" :reverse-transition="false">
                <meter-values-by-month/>
            </v-tab-item>
            <v-tab-item :transition="false" :reverse-transition="false">
                <meter-values-by-day/>
            </v-tab-item>
            <v-tab-item :transition="false" :reverse-transition="false">
                <insights/>
            </v-tab-item>
        </v-tabs-items>

    </v-container>

</template>

<script>

    import Common from "../mixins/Common"
    import HttpErrorHandler from "../mixins/HttpErrorHandler"
    import MeterValuesByYear from './MeterValuesByYear.vue'
    import MeterValuesByMonth from './MeterValuesByMonth.vue'
    import MeterValuesByDay from './MeterValuesByDay.vue'
    import Insights from './Insights.vue'
    import {EventBus} from '@/eventbus'

    export default {
        name: 'MeterValues',
        mixins: [Common, HttpErrorHandler],
        components: {
            MeterValuesByYear,
            MeterValuesByMonth,
            MeterValuesByDay,
            Insights
        }
    }
</script>
