<template>

    <v-dialog v-model="visible" max-width="350">
        <v-card>

            <v-card-title>
            </v-card-title>

            <v-card-text>
                <v-switch v-model="showPreviousYear"
                          dense
                          hide-details
                          label="Sammenlign med forrige år"/>

                <v-switch v-model="showElectricityCost"
                          dense
                          hint="Basert på Nord Pool spotpris uten påslag"
                          persistent-hint
                          label="Vis estimert kraftkostnad"/>

                <v-switch v-model="showTemperature"
                          dense
                          :hint="temperatureSensorName"
                          persistent-hint
                          label="Vis temperatur"/>

                <v-switch v-model="showPrices"
                          dense
                          hint="Nord Pool spotpris uten påslag"
                          persistent-hint
                          label="Vis spotpris"/>
            </v-card-text>

            <v-card-actions>
                <v-spacer/>
                <v-btn text color="primary" @click="visible = false">Lukk</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>

import Common from "@/mixins/Common"

export default {
    name: 'MeterValuesSettings',
    mixins: [Common],
    components: {},
    props: ['value'],

    data() {
        return {
        }
    },

    methods: {
    },

    computed: {
        visible: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },

        temperatureSensorName() {
            let sensor = this.meterPoint?.temperatureSensor;
            if (!sensor) {
                return null;
            }
            return `${sensor.name}, ${sensor.region} (${this.formatNumber(sensor.distance / 1000, 0)} km)`;
        }
    },

    watch: {
    },

    created() {
    },
}
</script>
