var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.priceBasis)?_c('div',{staticClass:"pb-4 caption text-center"},[_c('span',{staticClass:"amber--text text--darken-1"},[_vm._v(" ★ ")]),(_vm.fixedPriceBasis)?_c('span',[_vm._v(" = Tellende for valg av kapasitetstrinn. Snitt: ")]):(_vm.powerPriceBasis )?_c('span',[_vm._v(" = Tellende for valg av effekttrinn. Maks: ")]):_vm._e(),_c('b',[_vm._v(" "+_vm._s(this.formatNumber(_vm.priceBasisValue, 3))+" kWt ")])]):_vm._e(),_c('v-data-table',{attrs:{"items":_vm.topTenHours,"headers":_vm.headers,"fixed-header":"","disable-sort":"","hide-default-footer":"","disable-pagination":"","mobile-breakpoint":"0","dense":""},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',[(_vm.priceBasis === 'MONTHLY_MAX_3' && _vm.topThreeDailyMaxes.find(function (each) { return each[0] === item.time; }) || _vm.priceBasis === 'MONTHLY_MAX' && _vm.monthlyMax && _vm.monthlyMax[0] === item.time)?_c('span',{staticClass:"pr-2 caption amber--text text--darken-1"},[_vm._v(" ★ ")]):_vm._e(),_c('span',{staticClass:"caption",domProps:{"textContent":_vm._s(index + 1)}})])]}},{key:"item.day",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption",domProps:{"textContent":_vm._s(_vm._formatDay(item.time))}})]}},{key:"item.hour",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption",domProps:{"innerHTML":_vm._s(_vm.formatShortHourInterval(item.time))}})]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption",domProps:{"textContent":_vm._s(_vm.formatNumber(item.value, 3))}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }