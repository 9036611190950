<template>

    <v-dialog v-model="visible" max-width="350">
        <v-card>

            <v-card-title>
                <v-icon left>mdi-ethernet</v-icon>
                HAN-port
            </v-card-title>

            <v-card-text>

                <div class="pb-5">
                    Ønsker du detaljert informasjon om strøm&shy;forbruket ditt? Vil du styre strøm&shy;forbruket ditt bedre?
                </div>
                <div>
                    Da kan det være en idé å bestille åpning av HAN-porten på strøm&shy;måleren din.
                </div>
            </v-card-text>

            <v-card-actions>
                <v-spacer/>
                <v-btn text color="primary" href="https://www.nek.no/info-ams-han-brukere/" target="_blank" class="mr-2">Les mer</v-btn>
                <v-btn text color="primary" @click="visible = false">Lukk</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>

import Common from "@/mixins/Common"

export default {
    name: 'HanInfo',
    mixins: [Common],
    components: {},
    props: ['value'],

    computed: {
        visible: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
}
</script>
