<template>

    <highcharts ref="chart" :options="chartOptions"
                :style="{height: $vuetify.breakpoint.xs ? '470px' : '600px'}"
                style="width:100%"
                v-observe-visibility="visibilityChanged"/>

</template>

<script>

import Common from "../mixins/Common"
import moment from "moment-timezone";
import _ from "lodash";

export default {
    name: 'HourValueForMonth',
    mixins: [Common],
    components: {},
    props: ['hourValues'],

    data() {
        return {
            visible: true,

            chartOptions: {
                chart: {
                    type: 'heatmap',
                    inverted: true,
                    backgroundColor: 'transparent',
                    style: {
                        fontFamily: 'Roboto'
                    }
                },
                title: {
                    text: null
                },

                subtitle: {
                    text: null,
                },

                xAxis: {
                    type: 'datetime',
                    labels: {
                        format: '{value:%e}.', // Day of month
                    },
                    tickInterval: 2 * 24 * 3600 * 1000,
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                    labels: {
                        format: '{value}:00'
                    },
                    opposite: true,
                    visible: true,
                    gridLineWidth: 0,
                    lineWidth: 1,
                    minPadding: 0,
                    maxPadding: 0,
                    startOnTick: false,
                    endOnTick: false,
                    tickInterval: 3,
                    tickWidth: 1,
                    min: 0,
                    max: 23,
                    reversed: false
                },
                time: {
                    getTimezoneOffset: timestamp => -moment.tz(timestamp, 'Europe/Oslo').utcOffset()
                },
                legend: {
                    enabled: true,
                    useHTML: true
                },
                colorAxis: {
                    dataClasses: []
                },
                exporting: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                loading: {
                    labelStyle: {
                        fontWeight: 'normal',
                    },
                    style: {
                        opacity: 1.0,
                        backgroundColor: '#424242'
                    }
                },
                series: [
                    {
                        type: 'heatmap',
                        borderWidth: 0,
                        borderColor: 'lightgray',
                        nullColor: '#888888',
                        colsize: 24 * 60 * 60 * 1000, // one day in milliseconds
                    }
                ]
            }
        }
    },

    computed: {

        yearValues() {
            return this.showConsumption
                   ? this.meterValues?.consumption.years.find(each => each.year === this.year)
                   : this.meterValues?.production.years.find(each => each.year === this.year)
        },

        minHourlyValueInYear() {
            return this.yearValues?.min;
        },

        maxHourlyValueInYear() {
            return this.yearValues?.max;
        },

        paddedHourlyThresholds() {
            let thresholds = this.showConsumption ? this.hourlyConsumptionThresholds : this.hourlyProductionThresholds;
            return [0].concat(thresholds).concat([1000000000]);
        }
    },

    methods: {

        updateChart() {
            if (!this.hourValues) {
                return;
            }

            this.updateLegend();

            this.chartOptions.xAxis.min = moment.tz(this.month, "Europe/Oslo").startOf("month").utc().valueOf();
            this.chartOptions.xAxis.max = moment.tz(this.month, "Europe/Oslo").startOf("month").add(1, "month").subtract(1, 'day').utc().valueOf();

            let intervalValues = this.showConsumption ? this.hourValues.consumptionInterval : this.hourValues.productionInterval;
            let dailyMaxesByDay = _.mapValues(_.groupBy(intervalValues, each => this.formatDay(each[0])), v => _.maxBy(v, v => v[1]));
            let topThreeDailyMaxes = _.take(_.orderBy(_.toArray(dailyMaxesByDay), each => each[1], 'desc'), 3);
            let monthlyMax = _.maxBy(topThreeDailyMaxes, each => each[1])
            let priceBasis = this.fixedPriceBasis || this.powerPriceBasis;

            let values = intervalValues
                .map(each => ({
                    x: moment.tz(each[0], "Europe/Oslo").startOf("day").valueOf(),
                    y: moment.tz(each[0], "Europe/Oslo").hour(),
                    value: each[1],
                    time: moment.tz(each[0], "Europe/Oslo").format('HH:mm'),
                    dataLabels: {
                        enabled: this.showConsumption &&
                                 (priceBasis === 'MONTHLY_MAX_3' && topThreeDailyMaxes.find(e => e[0] === each[0]) ||
                                  priceBasis === 'MONTHLY_MAX' && monthlyMax && monthlyMax[0] === each[0]),
                        allowOverlap: true,
                        color: '#FFD54F',
                        format: '&#9733;',
                        useHTML: true,
                        style: {
                            fontSize: '10px',
                        }
                    }
                }));

            this.chartOptions.colorAxis.minColor = this.showConsumption ? '#e6ebf5' : '#e6f5eb';
            this.chartOptions.colorAxis.maxColor = this.showConsumption ? '#003399' : '#009933';

            this.chartOptions.series = [{
                data: values,
                borderWidth: 1,
                nullColor: '#EFEFEF',
                tooltip: {
                    headerFormat: null,
                    pointFormat: "{point.x:%e. %b} {point.time}: <b>{point.value} kWt</b>",
                    valueDecimals: 3,
                },
            }];
        },

        updateLegend() {
            let min = Math.max(this.minHourlyValueInYear, 0);
            let max = Math.max(this.maxHourlyValueInYear, 0);

            let firstIndex = _.findLastIndex(this.paddedHourlyThresholds, threshold => threshold <= min);
            let lastIndex = this.paddedHourlyThresholds.findIndex(threshold => threshold > max);

            let dataClasses = [];

            for (let index = firstIndex; index < lastIndex; index++) {
                let from = this.paddedHourlyThresholds[index];
                let to = (index === this.paddedHourlyThresholds.length - 2) ? null : this.paddedHourlyThresholds[index + 1];
                let name = from + (to ? ('&ndash;' + to) : '+');
                if (index === lastIndex - 1) {
                    name += ' kWt'
                }

                let dataClass = {from, name};
                if (to) {
                    dataClass.to = to;
                }
                dataClasses.push(dataClass);
            }

            this.chartOptions.colorAxis.dataClasses = dataClasses;
        },

        visibilityChanged(visible) {
            this.visible = visible;
            if (visible) {
                // Necessary in case browser window was resized
                this.$refs.chart.chart.reflow();
            }
        },
    },

    watch: {
        hourValues() {
            this.updateChart();
        },

        valueType() {
            this.updateChart();
        }
    }
}
</script>
