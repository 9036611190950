<template>
    <v-app id="app">

        <v-dialog v-model="error" max-width="400">
            <v-card>
                <v-card-title class="headline">Noe gikk galt</v-card-title>

                <v-card-text>
                    <div v-for="line in errorMessage">{{line}}</div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="clearError()">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-navigation-drawer app temporary touchless v-model="drawerVisible" width="270">

            <v-list three-line>

                <v-list-item v-show="!needSetup" @click="signOut">
                    <v-list-item-content >
                        <v-list-item-title><v-icon left color="primary">mdi-logout</v-icon>Logg ut</v-list-item-title>
                        <v-list-item-subtitle class="caption">Hvis du logger ut må du få tilsendt ny SMS-kode for å logge på igjen.</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-show="beforeInstallPrompt" @click="install">
                    <v-list-item-content >
                        <v-list-item-title><v-icon left color="primary">mdi-cellphone-arrow-down</v-icon>Legg til snarvei</v-list-item-title>
                        <v-list-item-subtitle class="caption">Legg til <strong>Min strøm</strong> som en snarvei på Hjem-skjermen din.</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item v-bind="attrs"
                                     v-on="on"
                                     v-show="contactUrl">
                            <v-list-item-content >
                                <v-list-item-title><v-icon left color="primary">mdi-chat</v-icon>Ta kontakt</v-list-item-title>
                                <v-list-item-subtitle class="caption">Ta kontakt med oss hvis du har spørsmål eller vil melde feil.</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <contact :url="contactUrl"
                             :phone="contactPhone"
                             :email="contactEmail"
                             :email-subject="'Målepunkt ' + meterPointId"/>
                </v-menu>

                <v-list-item v-show="privacyUrl" :href="privacyUrl" target="_blank">
                    <v-list-item-content >
                        <v-list-item-title><v-icon left color="primary">mdi-account-outline</v-icon>Personvern</v-list-item-title>
                        <v-list-item-subtitle class="caption">Les personvern-erklæring og brukervilkår.</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-show="showActivateNotificationDialogue" target="_blank">
                    <v-list-item-content >
                        <v-list-item-title><v-icon left color="primary">mdi-account-outline</v-icon>Push varsel</v-list-item-title>
                        <push-notification></push-notification>
                        <v-list-item-subtitle class="caption">{{ $t('messages.pushNotification.description') }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

            </v-list>

            <div class="caption pl-5" style="position: absolute; bottom: 10px">Versjon {{version}}</div>
        </v-navigation-drawer>

        <v-app-bar app dense dark color="black">

            <v-app-bar-nav-icon @click="drawerVisible = !drawerVisible"/>

            <v-spacer/>

            <div style="padding-left:15px">
                <v-menu v-if="meterPoints.length > 1"
                        open-on-hover
                        dark
                        max-height="calc(100vh - 45px)"
                        bottom
                        offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text
                               v-bind="attrs"
                               v-on="on">
                            {{ meterPointTitle(meterPoint) }}
                            <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>

                    <v-list two-line>
                        <v-list-item v-for="mp in meterPoints"
                                     :key="mp.meterPointId"
                                     :disabled="mp === meterPoint"
                                     @click="selectMeterPoint(mp)">
                            <v-list-item-content>
                                <v-list-item-title v-text="meterPointTitle(mp)"/>
                                <v-list-item-subtitle>
                                    Målernummer {{ mp.meterSerialNumber }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <div v-else v-text="meterPointTitle(meterPoint)" class="text-button"/>
            </div>
            <v-spacer/>

            <v-progress-circular v-show="isLoading" indeterminate :size="25" color="primary" style="padding-left: 40px"/>
            <img v-show="!isLoading" :src="`/img/icons/${netownerCode}/logo.png`" style="height: 40px">
        </v-app-bar>

        <v-main style="background-color: white">

            <keep-alive>
                <router-view></router-view>
            </keep-alive>

            <v-bottom-navigation v-if="!needSetup" app grow color="primary">
                <v-btn to="/status">
                    <span>Oversikt</span>
                    <v-icon>mdi-home</v-icon>
                </v-btn>

                <v-btn to="/metervalues" :disabled="!meterPoint">
                    <span>Forbruk</span>
                    <v-icon>mdi-poll</v-icon>
                </v-btn>

                <v-btn v-if="outageMapEnabled" to="/outage">
                    <span>Strømstans</span>
                    <v-icon>mdi-power-plug-off</v-icon>
                </v-btn>

                <v-btn v-if="twitterId" to="/twitter">
                    <span>Twitter</span>
                    <v-icon>mdi-twitter</v-icon>
                </v-btn>

                <v-btn v-if="facebookId" to="/facebook">
                    <span>Facebook</span>
                    <v-icon>mdi-facebook</v-icon>
                </v-btn>

                <v-btn to="/insightmessages" @click="messagesTabSelected">
                    <span>Meldinger</span>
                    <v-icon v-if="messagesAvailable">mark_unread_chat_alt</v-icon>
                    <v-icon v-else>sms</v-icon>
                </v-btn>

            </v-bottom-navigation>

        </v-main>

    </v-app>
</template>

<script>
    import Common from "./mixins/Common"
    import InstallWebapp from "./mixins/InstallWebapp"
    import HttpErrorHandler from "./mixins/HttpErrorHandler"
    import MeterValuesByYear from './components/MeterValuesByYear'
    import MeterValuesByMonth from './components/MeterValuesByMonth'
    import MeterValuesByDay from './components/MeterValuesByDay'
    import PushNotification from "./components/PushNotification.vue"
    import Status from './components/Status'
    import Contact from './components/Contact'
    import {EventBus} from '@/eventbus'
    import {version} from './version'
    import moment from "moment-timezone";
    import _ from "lodash";

    export default {
        name: 'app',
        mixins: [Common, InstallWebapp, HttpErrorHandler],
        components: {
            MeterValuesByYear,
            MeterValuesByMonth,
            MeterValuesByDay,
            Status,
            Contact,
            PushNotification
        },
        data() {
            return {
                bottomNav: null,
                drawerVisible: false,
                showActivateNotificationDialogue: true,
                messagesAvailable: false
            }
        },
        methods: {
            updateApp() {
                window.location.reload();
            },

            loadMeterPoints() {
                if (this.netownerId == null || this.idToken == null) {
                    this.$store.commit("setMeterPoints", []);
                    this.$store.commit("setMeterPointsLoaded", false);
                    return;
                }

                let url = `${this.apiUrl}customer/netowner/${this.netownerId}/meterpoint`;

                this.$http.get(url).then(response => {
                    this.$store.commit("setMeterPoints", response.body);
                    this.$store.commit("setMeterPointsLoaded", true);
                }, response => {
                    this.setErrorResponse(response);
                });
            },

            loadMeterValues() {
                if (!this.meterPoint) {
                    return;
                }

                let url = `${this.apiUrl}customer/netowner/${this.netownerId}/metervalues/${this.meterPointId}/aggregated`;
                let params = {
                    temperatureSensorId: this.meterPoint.temperatureSensor?.id
                };

                this.$http.get(url, {params: params}).then(response => {
                    this.$store.commit("setMeterValues", response.body);
                    let days = response.body.consumption.days;
                    if (days.length > 0 && !this.day) {
                        this.$store.commit("setDay", days[days.length - 1].day);
                    }
                });
            },

            selectMeterPoint(meterPoint) {
                this.$store.commit("setMeterPoint", meterPoint);
            },

            meterPointTitle(meterPoint) {
                return meterPoint?.address1 || meterPoint?.meterSerialNumber;
            },

            showMeterValues() {
                this.$router.push('/metervalues').catch(() => {})
            },
            async messagesTabSelected() {
                await this.getLatestMessages();
                this.setLatestMessageReadDate(await this.checkIfNewMessages());
                this.messagesAvailable = false;

                if (navigator.setAppBadge) {
                    navigator.clearAppBadge().then(() => console.log("App badge cleared"));
                }

            },
            async getLatestMessages() {

                if (!this.idToken){
                    return;
                }

                let url = `${this.apiUrl}customer/netowner/${this.netownerId}/notifications`;
                await this.$http.get(url).then(response => {

                    this.$store.commit("setMessages", response.body ? response.body : []);

                }, response => {
                    this.$store.commit("setMessages", []);
                    this.setErrorResponse(response);
                });

                this.checkIfNewMessages();
            },
            checkIfNewMessages() {
                let latestMessageDateMillis = Number.parseInt(localStorage.getItem("latestMessageDate"));
                let maxCreatedList = this.$store.state.messages.map( m => m.created);


                if (maxCreatedList === null ||  maxCreatedList.length === 0) {
                    this.messagesAvailable = false;
                    return 0;
                }

                let millis = maxCreatedList.map(m => moment(m).valueOf());
                let maxCreated = _.max(millis);

                this.messagesAvailable = maxCreated !== latestMessageDateMillis;

                return maxCreated;
            },
            setLatestMessageReadDate(latestMessageDateMillis) {
                localStorage.setItem("latestMessageDate",latestMessageDateMillis);
            },
            clearPushNotificationToken() {
                localStorage.removeItem("pushNotificationToken");
                localStorage.removeItem("pushNotificationTokenTimestamp");
            }
        },

        computed: {
            version() {
                return version;
            },
            standalone() {
                return window.navigator.standalone;
            },
            updateAvailable() {
                return this.$store.state.updateAvailable;
            },
            needSetup() {
                return this.$store.state.cognito.refreshToken == null;
            },
        },

        watch: {

            netownerId() {
                this.loadMeterPoints();
            },

            idToken(newVal, prevVal) {
                this.loadMeterPoints();

                if(newVal && !prevVal) {
                    this.getLatestMessages();
                }

            },

            meterPoints() {
                // Retain selected meterpoint
                if (this.meterPoint) {
                    this.selectMeterPoint(this.meterPoints.find(each => each.meterPointId === this.meterPoint.meterPointId));
                } else {
                    this.selectMeterPoint(this.meterPoints?.[0]);
                }
            },

            meterPoint() {
                this.loadMeterValues();
            },

            updateAvailable() {
                if (this.updateAvailable) {
                    this.updateApp();
                }
            }
        },

        created() {
            this.loadMeterPoints();
            this.getLatestMessages();
            EventBus.$on('year-selected', () => {
                this.meterValuesTab = 0;
                this.showMeterValues()
            });
            EventBus.$on('month-selected', () => {
                this.meterValuesTab = 1;
                this.showMeterValues()
            });
            EventBus.$on('day-selected', () => {
                this.meterValuesTab = 2;
                this.showMeterValues()
            });
            EventBus.$on('push-notification', () => {
                this.messagesAvailable = true;
            });
            EventBus.$on('get-latest-messages', async () => {
                await this.messagesTabSelected();
                this.$router.push('/insightmessages').catch(() => {
                });
            });

            if (!this.prefilledPhoneNumber) {
                if (this.needSetup) {
                    //Clear eventual existing push tokens
                    this.clearPushNotificationToken();
                    this.$router.push('/welcome').catch(() => {});
                } else {

                    if(!window.location.href.includes('insightmessages')) {
                        this.$router.push('/status').catch(() => {
                        });
                    }
                }
            }

        }
    }
</script>
