<template>
    <div>
        <v-bottom-sheet v-model="showHelp" >
            <v-card tile color="blue lighten-5">
                <v-card-text class="pt-12">
                    {{ $t('messages.extensiveOutage') }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn text color="primary" @click="showHelp = false">Lukk</v-btn>
                    <v-spacer/>
                </v-card-actions>
            </v-card>
        </v-bottom-sheet>

        <div style="height:calc(100vh - 106px); position:relative;">

            <div v-if="outages.length === 0"
                 class="chip"
                 style="background-color:transparent">

                <v-chip dark
                        class="elevation-1"
                        color="green darken-3"
                        @click="zoomToGridArea">
                    <v-icon left>mdi-check-circle-outline</v-icon>
                    <span class="font-weight-light text-uppercase body-2">
                    {{ $t('messages.noOutage') }}
                    </span>
                </v-chip>
            </div>

            <div class="toolbar" v-if="selectedOutage">

                <v-layout align-center class="my-2">
                    <v-btn dark icon @click="selectedOutage = null" class="ml-2 mr-3">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                    <div>
                        <div class="font-weight-light text-uppercase body-2">{{selectedOutage.substation.description || selectedOutage.substation.name}}</div>

                        <div v-if="!selectedOutage.type  || selectedOutage.type === 'ongoingError'" class="caption">
                            {{ $t('messages.outage') }} {{formatDateTime(selectedOutage.from)}}
                        </div>

                        <div v-else class="caption">
                            <div class="d-inline-block" v-if="selectedOutage.type === 'correction' || selectedOutage.type === 'troubleshooting'">{{ $t('messages.plannedOutageError') }} {{ $t('messages.types.' + selectedOutage.type).toLowerCase() }}</div>
                            <div class="d-inline-block" v-else>{{ $t('messages.plannedOutage') }} {{ $t('messages.types.' + selectedOutage.type).toLowerCase() }}</div>
                            <br>
                            {{ formatDateTime(selectedOutage.from) }} &rarr;
                            {{ formatDateTime(selectedOutage.to) }}
                            <v-chip v-show="isActive" small class="ml-1 error"> {{$t('messages.active')}}</v-chip>
                        </div>
                    </div>

                    <v-spacer/>

                    <v-btn v-if="selectedOutage.actual" dark icon @click="showHelp = true" class="mx-2">
                        <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                </v-layout>

                <div v-if="!selectedOutage.type" class="caption mx-3 mb-2">
                    {{ $t('messages.troubleshootMessage') }}
                    <span v-if="twitterId">
                        {{ $t('messages.see') }}
                        <router-link to="/twitter" v-slot="{ href, navigate}">
                            <a :href="href" @click="navigate">{{ $t('messages.messages') }}</a>
                            </router-link>
                            {{ $t('messages.updates') }}
                        </span>
                    <span v-if="facebookId">
                        {{ $t('messages.see') }}
                        <router-link to="/facebook" v-slot="{ href, navigate}">
                            <a :href="href" @click="navigate">{{ $t('messages.messages') }}</a>
                        </router-link>
                    {{ $t('messages.messages') }}
                    </span>
                </div>

                <div v-else class="caption mx-3 mb-2">
                    {{ selectedOutage.description }}
                </div>
            </div>

            <v-btn small dark fab color="rgba(0,0,0,0.62)" @click="locate" class="locate">
                <v-icon>mdi-crosshairs-gps</v-icon>
            </v-btn>

            <v-btn icon large v-show="!selectedOutage" @click="showCard= !showCard" class="overlay">
                <v-icon color="'primary'">mdi-information</v-icon>
            </v-btn>


            <div class="help-div">
                <v-layout>
                    <v-card v-show="showCard && !selectedOutage" class="help-card">
                        <v-card-title>{{ $t('messages.help.meaning') }}</v-card-title>
                        <v-card-text>

                            <v-list three-line style="margin-left:-15px;">
                                <v-list-item>
                                    <v-list-item-action style="margin-right: 10px">
                                        <div class="icon-total icon-help">
                                        </div>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{
                                                $t('messages.help.outage')
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{
                                                $t('messages.help.outageExplained')
                                            }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>


                                <v-list-item>
                                    <v-list-item-action style="margin-right: 10px;">
                                        <div class="icon-planned-active icon-help">
                                        </div>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{
                                                $t('messages.help.activePlannedOutage')
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{
                                                $t('messages.help.activePlannedOutageExplained')
                                            }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-action style="margin-right: 10px">
                                        <div class="icon-planned icon-help">
                                        </div>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{
                                                $t('messages.help.plannedOutage')
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{
                                                $t('messages.help.plannedOutageExplained')
                                            }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-layout>
            </div>

            <div class="layer-controls hidden-sm-and-down">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-img v-on="on" class="layer-controls-image" src="layer0.png" @click="layerSelection = 0" :height="46" :width="46"/>
                    </template>
                    <span>{{$t('messages.map.layer_map_dark')}}</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-img v-on="on" class="layer-controls-image" src="layer1.png" @click="layerSelection = 1" :height="46" :width="46"/>
                    </template>
                    <span>{{$t('messages.map.layer_map')}}</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-img v-on="on" class="layer-controls-image" src="layer2.png" @click="layerSelection = 2" :height="46" :width="46"/>
                    </template>
                    <span>{{$t('messages.map.layer_satellite_dark')}}</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-img v-on="on" class="layer-controls-image" src="layer3.png" @click="layerSelection = 3" :height="46" :width="46"/>
                    </template>
                    <span>{{$t('messages.map.layer_satellite')}}</span>
                </v-tooltip>
            </div>

            <l-map ref="map" :bounds.sync="bounds" style="background-color: #000; z-index:0" :minZoom="4" :maxZoom="18" :zoom.sync="zoom"
                   :options="{zoomControl: false, attributionControl: $vuetify.breakpoint.mdAndUp}">
                <l-control-zoom  v-if="$vuetify.breakpoint.mdAndUp" position="bottomleft"/>
                <l-control-scale v-if="$vuetify.breakpoint.mdAndUp" position="bottomleft" :imperial="false"/>

                <l-geo-json v-if="gridArea" :geojson="gridArea" :options="gridAreaOptions"/>

                <l-marker v-if="userLocation"
                          :lat-lng="userLocation"
                          :zIndexOffset="11000"
                          :icon="userLocationIcon"
                          @click="flyTo(userLocation)"/>

                <l-marker v-if="homeLocation"
                          :lat-lng="homeLocation"
                          :zIndexOffset="10000"
                          :icon="homeLocationIcon"
                          @click="flyTo(homeLocation)"/>

                <l-marker v-if="zoom <= 11"
                          v-for="outage in outages"
                          :icon="getIconForOutage(outage)"
                          :options="{zIndexOffset: getZIndexOffsetForOutage(outage)}"
                          :key="'m-' + outage.substation.id"
                          :lat-lng="getCenterForOutage(outage)"
                          @click="selectedOutage = outage"/>

                <l-polygon v-if="zoom > 11"
                           v-for="outage in outages"
                           :color="getPolygonColor(outage)"
                           :fillColor="getFillColor(outage)"
                           :weight="1.0"
                           :key="outage.substation.id"
                           :lat-lngs="outage.polygon"
                           @click="selectedOutage = outage">
                    <l-tooltip :options="{permanent: true, className: 'tooltip'}">{{outage.substation.description || outage.substation.name}}</l-tooltip>
                </l-polygon>
            </l-map>
        </div>
    </div>
</template>

<script>

    import Common from "../mixins/Common"
    import HttpErrorHandler from "../mixins/HttpErrorHandler"
    import L from 'leaflet';
    import {LCircleMarker, LMarker, LControlLayers, LControlScale, LControlZoom, LGeoJson, LMap, LPolygon, LTooltip} from 'vue2-leaflet';
    import moment from "moment-timezone";

    export default {
        name: 'OutageMap',
        mixins: [Common, HttpErrorHandler],
        components: {
            LTooltip,
            LMap,
            LCircleMarker,
            LMarker,
            LControlLayers,
            LControlScale,
            LControlZoom,
            LPolygon,
            LGeoJson,
        },
        props: {},
        methods: {

            loadNetowner() {
                if (!this.netownerId || !this.idToken) {
                    return;
                }
                this.selectedOutage = null;
                this.loadOutages();

                let url = this.properties.ApiUrl + "customer/netowner/" + this.netownerId + "/netowner";

                this.$http.get(url).then(response => {
                                             if (response.body.netownerBounds) {
                                                 this.netownerBounds = L.latLngBounds(L.latLng(response.body.netownerBounds.south, response.body.netownerBounds.west),
                                                                                      L.latLng(response.body.netownerBounds.north, response.body.netownerBounds.east));
                                                 this.bounds = this.netownerBounds;
                                                 if (response.body.netownerPolygonUrl) {
                                                     this.$http.get(response.body.netownerPolygonUrl).then(response => {
                                                         this.gridArea = response.body;
                                                     });
                                                 }
                                             }
                                         },
                                         response => {
                                             this.setErrorResponse(response);
                                         });
            },

            loadOutages() {
                if (!this.netownerId || !this.idToken) {
                    return;
                }

                let url = this.properties.ApiUrl + "customer/netowner/" + this.netownerId + "/outage";

                this.$http.get(url).then(response => {
                    this.outages = this.merge(response.body.outages, response.body.plannedOutages);

                }, response => {
                    this.outages = [];
                    console.log(response);
                });
            },

            merge(outages, plannedOutages) {
                outages.forEach(outage => {
                    let plannedOutage = plannedOutages.find(each => each.substation.id === outage.substation.id);
                    outage.actual = true;
                    if (plannedOutage) {
                        outage.type = plannedOutage.type;
                        outage.description = plannedOutage.description;
                        outage.from = plannedOutage.from;
                        outage.to = plannedOutage.to;
                    }
                });

                let now = moment();
                return outages.concat(plannedOutages
                                              .filter(plannedOutage => moment(plannedOutage.to).isAfter(now))
                                              .filter(plannedOutage => !outages.find(each => each.substation.id === plannedOutage.substation.id)));
            },

            locate() {
                if (!this.locationEnabled) {
                    this.locationEnabled = true;
                    let map = this.$refs.map.mapObject;
                    map.locate({watch: true, enableHighAccuracy: true});
                    map.on('locationfound', this.onLocationFound);
                }
                this.flyTo(this.userLocation);
            },

            getIconForOutage(outage) {
                if (outage.actual || outage.type === 'ongoingError') {
                    return this.totalIcon;
                }
                return outage.from < moment().utc().format() ? this.plannedActiveIcon : this.plannedIcon
            },

            getFillColor(outage){
                return outage.actual || outage.type === 'ongoingError' ? '#ba000d' : '#e0e0e0';
            },

            getPolygonColor(outage){
                if (outage.actual || outage.type === 'ongoingError'){
                    return '#f44336'

                }
                return  outage.from < moment().utc().format() ? '#f44336' : '#e0e0e0';
            },

            getZIndexOffsetForOutage(outage) {
                return outage.actual ? 30000 : 20000;
            },

            getCenterForOutage(outage) {
                return L.latLngBounds(outage.polygon).getCenter();
            },

            onLocationFound(event) {
                let shouldFly = !this.userLocation;
                this.userLocation = event.latlng;

                if (shouldFly) {
                    this.flyTo(this.userLocation);
                }
            },

            flyTo(location) {
                if (location) {
                    let map = this.$refs.map.mapObject;
                    map.flyTo(location, 16, {animate: true});
                }
            },

            zoomToGridArea() {
                if (this.netownerBounds) {
                    this.bounds = this.netownerBounds;
                }
            },

            zoomToOutage(outage) {
                this.bounds = L.latLngBounds(outage.polygon);
            }
        },

        computed: {
            homeLocation() {
                return this.meterPoint
                       && this.meterPoint.latitude
                       && this.meterPoint.longitude
                       && [this.meterPoint.latitude, this.meterPoint.longitude];
            },

            isActive(){
                return moment(this.selectedOutage.from).isBefore(moment().utc().format());
            },
        },

        watch: {
            netownerId() {
                this.loadNetowner();
            },

            idToken(current, previous) {
                if (!previous && current) {
                    this.loadNetowner();
                }
                this.loadOutages();
            },

            selectedOutage() {
                if (this.selectedOutage) {
                    this.zoomToOutage(this.selectedOutage);
                } else {
                    this.zoomToGridArea();
                }
            },
            layerSelection(newLayerSelection, oldLayerSelection) {
                let map = this.$refs.map.mapObject;
                this.tileLayers[oldLayerSelection].remove();
                this.tileLayers[newLayerSelection].addTo(map);
            },

            $route() {
                if (this.$route && this.$route.path === '/outage') {
                    // Necessary in case browser window was resized
                    this.$refs.map.mapObject.invalidateSize(false);
                }
            },
        },

        data() {
            return {
                outages: [],
                zoom: 8,
                locationEnabled: false,
                showHelp: false,
                bounds: L.latLngBounds(L.latLng(61.93, 4.958), L.latLng(67.917, 24.398)),
                userLocation: null,
                netownerBounds: null,
                clickedOutage: null,
                selectedOutage: null,
                userLocationIcon: L.icon({iconUrl: 'pegman.png', iconSize: [19, 40]}),
                homeLocationIcon: L.icon({iconUrl: 'home.png', iconSize: [26, 26]}),
                totalIcon: L.divIcon({className: 'icon-total', iconSize: [22, 22]}),
                plannedIcon: L.divIcon({className: 'icon-planned', iconSize: [22, 22]}),
                plannedActiveIcon: L.divIcon({className: 'icon-planned-active', iconSize: [22, 22]}),
                layerSelection: 0,
                showCard: false,
                tileLayers: [
                    L.tileLayer('https://d3awtdd4drqiy5.cloudfront.net/ArcGIS/rest/services/Geocache_WMAS_WGS84/GeocacheBasis/MapServer/tile/{z}/{y}/{x}',
                                {
                                    name: 'Kart (mørk)',
                                    visible: true,
                                    opacity: 0.55,
                                    attribution: '&copy; Kartverket, Geovekst og Kommuner - Geodata AS',
                                }),
                    L.tileLayer('https://d3awtdd4drqiy5.cloudfront.net/ArcGIS/rest/services/Geocache_WMAS_WGS84/GeocacheBasis/MapServer/tile/{z}/{y}/{x}',
                                {
                                    name: 'Kart',
                                    visible: false,
                                    opacity: 1.0,
                                    attribution: '&copy; Kartverket, Geovekst og Kommuner - Geodata AS',
                                }),
                    L.tileLayer('https://d3awtdd4drqiy5.cloudfront.net/ArcGIS/rest/services/Geocache_WMAS_WGS84/GeocacheBilder/MapServer/tile/{z}/{y}/{x}',
                                {
                                    name: 'Satellitt (mørk)',
                                    visible: false,
                                    opacity: 0.5,
                                    attribution: '&copy; Kartverket, Geovekst og Kommuner - Geodata AS',
                                }),
                    L.tileLayer('https://d3awtdd4drqiy5.cloudfront.net/ArcGIS/rest/services/Geocache_WMAS_WGS84/GeocacheBilder/MapServer/tile/{z}/{y}/{x}',
                                {
                                    name: 'Satellitt',
                                    visible: false,
                                    opacity: 1.0,
                                    url: 'https://d3awtdd4drqiy5.cloudfront.net/ArcGIS/rest/services/Geocache_WMAS_WGS84/GeocacheBilder/MapServer/tile/{z}/{y}/{x}',
                                    attribution: '&copy; Kartverket, Geovekst og Kommuner - Geodata AS',
                                })
                ],
                gridArea: null,
                gridAreaOptions: {
                    color: "rgb(255, 255, 255)",
                    weight: 0,
                    fillOpacity: 0.1,
                    attribution: '<a href="https://nve.no/" target="_blank">NVE</a>'
                },
            }
        },

        mounted() {

            this.$nextTick(() => {
                let map = this.$refs.map.mapObject;
                map.addLayer(this.tileLayers[0]);
                map.on('locationfound', this.onLocationFound);
            });

            setInterval(() => {
                if (!this.isLoading) {
                    this.loadOutages();
                }
            }, 60 * 1000);

            this.loadNetowner();
            this.loadOutages();
        },
    }
</script>

<style lang="scss">
    @import "~leaflet/dist/leaflet.css";

    @mixin box-on-map {
        background-color: rgba(#000, .62);
        color: white;
        position: absolute;
        z-index: 1;
        display: block;
    }

    .overlay {
        @include box-on-map;
        width: fit-content;
        height: fit-content;
        right: 5px;
        top: 5px;
        background: transparent;
    }

    .chip {
        @include box-on-map;
        top: 15px;
        left: calc(50vw - 95px);
    }

    .toolbar {
        @include box-on-map;
        left: 0;
        width: 100%;
    }

    .locate {
        @include box-on-map;
        right: 25px;
        bottom: 25px;
    }

    .layer-controls {
        @include box-on-map;
        padding-left: 3px;
        padding-right: 3px;
        bottom: 8px;
        left: 50px;
    }

    .layer-controls-image {
        float: left;
        margin-left: 3px;
        margin-right: 3px;
        margin-top: 5px;
        margin-bottom: 5px;
        border: 1px solid lightgray;
        cursor: pointer;
    }

    .icon-location {
        border-radius: 50%;
        width: 12px;
        height: 12px;
        min-width: 12px;
        min-height: 12px;
        max-width: 12px;
        max-height: 10px;
        border: 1px solid black;
        background: #ffd600;
    }

    .icon-total, .icon-planned, .icon-planned-active {
        border-radius: 50%;
        box-shadow: 3px 3px 10px -1px rgba(0, 0, 0, 0.75);
        text-align: center;
    }

    .icon-total {
        border: 3px solid #F44336;
        background: #F44336A0;
    }

    .icon-planned {
        border: 3px solid #bababa;
        background: #BABABAA0;
    }

    .tooltip {
        background-color: #d4d4d4;
        padding-top: 2px;
        padding-bottom: 2px;
        border: none;
    }


    .icon-planned-active {
        border: 3px solid #F44336;
        background: #BABABAA0;
    }

    .icon-help {
        width: 22px;
        height: 22px;
        box-shadow: none;
        text-align: center;
    }

    .help-card {
        @include box-on-map;
        width: fit-content;
        height: fit-content;
        background-color:  rgba(#000, .62);

    }

    .help-div {
        @include box-on-map;
        width: 300px;
        height: fit-content;
        background-color: transparent;
        right: 0;
        margin-right: 30px;
        margin-top: 30px;
    }


</style>