<template>

    <v-container style="max-width: 600px">

        <div class="title text-center pb-6">Velkommen til Min strøm</div>

        <ul class="pb-10 mx-4">
            <li>Se oversikt og analyser av strøm&shy;forbruket ditt.</li>
            <li>Se feil i vårt nett.</li>
            <li>Meld fra til oss om farlige forhold.</li>
        </ul>

        <div v-if="showInstallBanner" class="pb-16">
            <v-alert prominent
                     border="left"
                     type="info">
                <div>
                    Legg til <strong>Min strøm</strong> som en snarvei på Hjem-skjermen din, så har du den alltid lett
                    tilgjengelig.
                </div>
            </v-alert>

            <div class="text-right">
                <v-btn text color="primary" class="mb-2" @click="install">Legg til snarvei</v-btn>
            </div>
            <div class="text-right">
                <v-btn to="/authenticate" text color="primary">Ikke nå</v-btn>
            </div>
        </div>

        <div v-if="showInstallBannerIos" class="pb-6">
            <v-alert prominent
                     border="left"
                     type="info">
                <div class="mb-3">
                    Legg til <strong>Min strøm</strong> som en snarvei på Hjem-skjermen din, så har du den alltid lett
                    tilgjengelig.
                </div>

                <div class="mb-3">
                    Trykk
                    <v-icon class="px-1" style="margin-top: -10px">mdi-export-variant</v-icon>
                    og velg "Legg til på Hjem-skjermen".
                </div>

                <div>
                    Fortsett registreringen etter at du har åpnet snarveien.
                </div>

            </v-alert>

            <div class="text-right">
                <v-btn to="/authenticate" text color="primary">Ikke nå</v-btn>
            </div>
        </div>

        <div v-if="!showInstallBanner && !showInstallBannerIos" class="pb-16 text-center">
            <v-btn to="/authenticate" color="primary">Kom i gang</v-btn>
        </div>

        <div class="text-center">
            <img :src="`/img/icons/${netownerCode}/logo.png`" style="height: 90px">
        </div>

    </v-container>

</template>

<script>

import Common from "@/mixins/Common"
import InstallWebapp from "@/mixins/InstallWebapp"

export default {
    name: 'Welcome',
    mixins: [Common, InstallWebapp],
}
</script>
