<template>

    <v-container>
        <div style="width: 340px" class="mx-auto pt-2">
            <iframe :src="`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F${facebookId}&tabs=timeline&width=340&height=1000&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=false`"
                    width="340" height="1000" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
        </div>
    </v-container>

</template>

<script>

    import Common from "../mixins/Common"

    export default {
        name: 'Facebook',
        mixins: [Common],
    }

</script>
